import React from 'react';
import styles from './DesktopBannerContent.module.css';
import Typography from '../../atoms/Typography/Typography';
import { useTranslation } from 'react-i18next';
import PlayButton from '../PlayButton/PlayButton';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../AthContext';

const DesktopBannerContent = ({ title, subTitle, onButtonClick }) => {
  const { loggedIn } = useAuth();
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="headingXL" fontWeight="font900" color="colorBlack" additionalClass={`${styles.header}`}>
        {title}
      </Typography>
      <div className={styles.captionButtonsContainer}>
        <Typography variant="heading3" fontWeight="font400" color="colorBlack" additionalClass={styles.headerCaption}>
          {subTitle}
        </Typography>
        <div className={styles["buttons-container"]}>
          <PlayButton
            id="free_play"
            additionalClass="play-free"
            primaryText={t("generic.playForFree")}
            onClick={onButtonClick}
            extraClass={styles.playFree}
          />
          <Link to={loggedIn ? '/quiz' : '/forms'}>
            <PlayButton
              additionalClass="play-prize"
              primaryText={t("generic.playForPrize")}
              imageNameRight="goldenTrophy"
              extraClass={styles.playPrize}
            />
          </Link>
        </div>
      </div>
    </>
  )
}

export default DesktopBannerContent;
