import React from "react";
import styles from "./CashTournamentsSection.module.css";
import CashQuizTournamentsPanel from "../../molecules/CashQuizTournamentsPanel/CashQuizTournamentsPanel";

const CashTournamentsSection = () => {
  const dateTo = new Date("02/17/24");
  const dateTo2 = new Date("02/2s5/24");
  return (
    <div className={styles.cashTournamentsSection}>
      <CashQuizTournamentsPanel
        title={"DAILY QUIZ"}
        startTime={dateTo}
        panelColor={"darkBlue"}
      />
      <CashQuizTournamentsPanel
        isTournament
        title={"EURO 24 TOURNAMENT"}
        startTime={dateTo}
        panelColor={"darkGreen"}
      />
      <CashQuizTournamentsPanel
        isTournament
        title={"FORMULA 1 TOURNAMENT"}
        startTime={dateTo2}
        panelColor={"darkGrey"}
      />
      <CashQuizTournamentsPanel
        isTournament
        title={"EURO 24 TOURNAMENT"}
        startTime={dateTo}
        panelColor={"darkGreen"}
      />
      <CashQuizTournamentsPanel
        title={"DAILY QUIZ"}
        startTime={dateTo}
        panelColor={"darkBlue"}
      />
      <CashQuizTournamentsPanel
        isTournament
        title={"FORMULA 1 TOURNAMENT"}
        startTime={dateTo2}
        panelColor={"darkGrey"}
      />
    </div>
  );
};

export default CashTournamentsSection;
