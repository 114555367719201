import React, { useState } from "react";
import styles from "./WalletContainer.module.css";
import WalletNotification from "../../molecules/WalletNotification/WalletNotification";
import WalletButton from "../../molecules/WalletButton/WalletButton";
import Typography from "../../atoms/Typography/Typography";
import { useTranslation } from "react-i18next";
import ComingSoon from "../../molecules/ComingSoon/ComingSoon";
import ModalNotification from "../../molecules/ModalNotification/ModalNotification";
import ModalWith1Button from "../../molecules/ModalWith1Button/ModalWith1Button";

const WalletContainer = ({ walletData, coins }) => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  const onButtonClick = () => {
    //console.log("Button clicked");
    if (walletData?.available > 0 || coins > 0) {
      setShowModal(true);
    }
  };

  const availableCashRounded = walletData?.available.toFixed(2);

  return (
    <>
      {showModal && (
        <ModalWith1Button
          mainTitle={t("mainNav.walletTab.popUp.title")}
          closeButtonTitle={t("generic.yes")}
          onClick={() => setShowModal(false)}
          secondaryTitle={t("mainNav.walletTab.popUp.body")}
        />
      )}
      <div className={styles.walletContainer}>
        <div className={styles.notificationsContainer}>
          <div className={styles.availableCashContainer}>
            <WalletNotification
              headText={`${availableCashRounded} €`}
              description={t("mainNav.walletTab.availableToWithdraw")}
            />

            {/* <WalletButton leftIcon="coin" text={t("mainNav.walletTab.deposit")} /> */}
            <div className={`${styles.walletButton} ${styles.blur}`}>
              {/* <WalletButton
              leftIcon="card"
              text={t("mainNav.walletTab.paymentMethods")}
              onClick={() => {
                //console.log("Payment methods clicked");
              }}
            /> */}

              <div
                className={`${styles.walletButton} ${
                  walletData?.available <= 0 ? styles.blur : ""
                }`}
              >
                <WalletButton
                  leftIcon="withdraw"
                  text={t("mainNav.walletTab.withdraw")}
                  onClick={onButtonClick}
                />
              </div>
              <div className={`${styles.walletButton} ${styles.blur}`}>
                {/* <WalletButton
              leftIcon="folder"
              text={t("mainNav.walletTab.transactionHistory")}
              onClick={() => {
                //console.log("Transaction history clicked");
              }}
            /> */}
              </div>
            </div>
          </div>
          <div className={styles.availableCoinsContainer}>
            <WalletNotification
              headText={`${coins} Coins`}
              description={t("mainNav.walletTab.availableToRedeem")}
            />

            {/* <WalletButton leftIcon="coin" text={t("mainNav.walletTab.deposit")} /> */}
            <div className={`${styles.walletButton} ${styles.blur}`}>
              {/* <WalletButton
              leftIcon="card"
              text={t("mainNav.walletTab.paymentMethods")}
              onClick={() => {
                //console.log("Payment methods clicked");
              }}
            /> */}

              <div
                className={`${styles.walletButton} ${
                  coins <= 0 ? styles.blur : ""
                }`}
              >
                <WalletButton
                  leftIcon="redeem"
                  text={t("mainNav.walletTab.redeem")}
                  onClick={onButtonClick}
                />
              </div>
              <div className={`${styles.walletButton} ${styles.blur}`}>
                {/* <WalletButton
              leftIcon="folder"
              text={t("mainNav.walletTab.transactionHistory")}
              onClick={() => {
                //console.log("Transaction history clicked");
              }}
            /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WalletContainer;