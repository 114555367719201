import React from 'react';
import styles from './FancySpinWheelBase.module.css';
import Icon from '../../atoms/Icon/Icon';
import CircleShape from '../../atoms/CircleShape/CircleShape';

const FancySpinWheelBase = ({
  baseDiameter = 455,
  baseColor = 'red',
  numOfLeds = 16,
  ledDiameter = 16,
  ledColor = 'gold',
}) => {
  const leds = [];
  const translateDistance = baseDiameter / 2.09; // for the leds to be a little inside the circle

  // Calculate the offset for the first LED to not start at 0 degrees
  const offsetAngle = 360 / (numOfLeds * 2);

  for (let i = 0; i < numOfLeds; i++) {
    leds.push(
      <CircleShape
        key={i}
        className={styles.leds}
        style={{
          transform: `rotate(${offsetAngle + (360 / numOfLeds) * i}deg) translate(${translateDistance}px)`,
        }}
        diameter={ledDiameter}
        color={ledColor}
      />,
    );
  }

  return (
    <CircleShape className={styles['wheel-base']} diameter={baseDiameter} color={baseColor}>
      {leds}
    </CircleShape>
  );
};

export default FancySpinWheelBase;
