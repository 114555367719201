import React from "react";
import styles from "./FriendsLayoutV2.module.css";
import Icon from "../../atoms/Icon/Icon";
import Typography from "../../atoms/Typography/Typography";
import Button from "../../atoms/Button/Button";
import {
  declineFriendRequest,
  acceptFriendRequest,
  getChatId,
  deleteMessages,
} from "../../../apiHelpers";
import { useAuth } from "../../../AthContext";
import { useTranslation } from "react-i18next";
import { useLoginExpired } from "../../../customHooks";

const FriendsLayoutV2 = ({
  friendsList,
  friendRequestsList,
  setFriendRequestsList,
  setRefreshSocialTriggered,
  setSelectedGameMode,
  setActiveDiscussionId,
  setAboutToStartChatsList,
}) => {
  const { t } = useTranslation();
  const { translateText } = useAuth();
  const loginExpired = useLoginExpired();
  // make the header text dynamic depending if there is a request
  const headerForFriendsRequestsSection =
    friendRequestsList.length > 0
      ? translateText("generic.newFriendRequest")
      : null;

  const headerForFriendsSection =
    friendsList.length > 0 ? translateText("generic.yourFriends") : null;

  const handleAccept = async (userId) => {
    try {
      const result = await acceptFriendRequest(userId);
      console.log(result); // Handle the result accordingly
      setRefreshSocialTriggered((prev) => !prev); // Toggle the refresh trigger
      // Update the friendRequestsList to exclude the declined request
    } catch (error) {
      console.error("Failed to decline friend request", error);
      // Handle error (e.g., show an error message)
    }
  };

  const handleReject = async (userId) => {
    try {
      const result = await declineFriendRequest(userId);
      console.log(result); // Handle the result accordingly

      // Update the friendRequestsList to exclude the declined request
      const updatedFriendRequestsList = friendRequestsList.filter(
        (request) => request.from.internalId !== userId
      );
      setFriendRequestsList(updatedFriendRequestsList);
    } catch (error) {
      console.error("Failed to decline friend request", error);
      // Handle error (e.g., show an error message)
    }
  };

  const handleChatButton = async (id) => {
    try {
      // Find friend details from the friend list using the provided ID
      const friendDetails = friendsList.find(
        (friend) => friend.internalId === id
      );

      // Fetch the chat ID for this particular friend
      const chatIdResponse = await getChatId(id);
      const chatId = chatIdResponse.chatId;
      // Construct a new discussion object
      const newDiscussion = {
        chatId: chatId,
        lastMessage: {
          createdAt: new Date().toISOString(),
        },
        user1: {
          internalId: friendDetails.internalId,
          username: friendDetails.username,
          avatarId: friendDetails.avatarId,
        },
        user2: {
          internalId: friendDetails.internalId,
          username: friendDetails.username,
          avatarId: friendDetails.avatarId,
        },
      };

      setAboutToStartChatsList(newDiscussion);
      setActiveDiscussionId(chatId);
      setSelectedGameMode("Chat");
    } catch (error) {
      console.error("Failed to fetch chat ID:", error);
      if (error.response?.status === 401) {
        loginExpired()
      }
    }
  };

  const handlePlayNow = async (id) => {
    const chatIdResponse = await getChatId(id);
    const chatId = chatIdResponse.chatId;
    console.log(chatId);
    try {
      const deleteResponse = await deleteMessages(chatId);
      console.log(deleteResponse);
    } catch (error) {
      console.error("Failed to delete the conversation", error);
    }
  };

  return (
    <>
      {friendRequestsList.length <= 0 && friendsList.length <= 0 && (
        <div className={styles.noFriendsContainer}>
          <Typography
            fontWeight="font500"
            variant="heading1"
            color="colorBlack"
          >
            {t("mainNav.friendsTab.friendsScreen.noFriendsTitle")}
          </Typography>
        </div>
      )}
      <div className={styles.friendsV2TemplateContainer}>
        <div className={styles.friendsRequestsContainer}>
          {friendRequestsList.length > 0 && (
            <Typography
              variant="heading2"
              fontWeight="font900"
              color="colorBlack"
              additionalClass={styles.header}
            >
              {headerForFriendsRequestsSection}
            </Typography>
          )}
          {friendRequestsList.map((friendRequest) => (
            <div key={friendRequest.from._id} className={styles.skewedBorder}>
              <div className={styles.avatarAndNameSection}>
                <Icon
                  name={`avatar-${friendRequest.from.avatarId}`}
                  folder="avatars"
                  size={48}
                />
                <Typography
                  variant="body1"
                  fontWeight="font700"
                  color="colorBlack"
                >
                  {friendRequest.from.username}
                </Typography>
              </div>
              <div className={styles.buttonsSection}>
                <Button
                  additionalClass={styles.rejectButton}
                  onClick={() => handleReject(friendRequest.from.internalId)}
                >
                  <Typography
                    variant="body2"
                    fontWeight="font700"
                    additionalClass={styles.buttonText}
                  >
                    {translateText("generic.reject")}
                  </Typography>
                </Button>
                <Button
                  additionalClass={styles.acceptButton}
                  onClick={() => handleAccept(friendRequest.from.internalId)}
                >
                  <Typography
                    variant="body2"
                    fontWeight="font700"
                    additionalClass={styles.buttonText}
                  >
                    {translateText("generic.accept")}
                  </Typography>
                </Button>
              </div>
            </div>
          ))}
        </div>

        <div className={styles.friendsContainer}>
          {friendsList.length > 0 && (
            <Typography
              variant="heading2"
              fontWeight="font900"
              color="colorBlack"
              additionalClass={styles.header}
            >
              {headerForFriendsSection}
            </Typography>
          )}
          {friendsList.map((friend) => (
            <div key={friend._id} className={styles.skewedBorder}>
              <div className={styles.avatarAndNameSection}>
                <Icon
                  name={`avatar-${friend.avatarId}`}
                  folder="avatars"
                  size={48}
                />
                <Typography
                  variant="body1"
                  fontWeight="font700"
                  color="colorBlack"
                >
                  {friend.username}
                </Typography>
              </div>
              <div className={styles.buttonsSection}>
                <Button
                  additionalClass={styles.chatButton}
                  onClick={() => handleChatButton(friend.internalId)}
                >
                  <Typography
                    variant="body2"
                    fontWeight="font700"
                    additionalClass={styles.buttonText}
                  >
                    {translateText("generic.chat")}
                  </Typography>
                </Button>
                {/* <Button additionalClass="PlayNowButton" onClick={() => handlePlayNow(friend.internalId)}>
                <Typography
                  variant="body2"
                  fontWeight="font700"
                  additionalClass={styles.buttonText}
                >
                  {translateText("generic.Play Now")}
                </Typography>
              </Button> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default FriendsLayoutV2;
