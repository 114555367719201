import io from "socket.io-client";
// import Cookies from "js-cookie";

const socket = io.connect(`${process.env.REACT_APP_SOCKETS_URL}`, {
  transports: ["websocket"],
  rejectUnauthorized: false,
});

export { socket };

// Function to set flag in localStorage
const setNewChatMessageFlag = (value) => {
  console.log(`Setting newChatMessageFlag to ${value}`); // Debug log
  localStorage.setItem("newChatMessageFlag", value);
};

// Initialize flag in localStorage
setNewChatMessageFlag(localStorage.getItem("newChatMessageFlag") || 'false');


socket.onAny((event, ...args) => {
  console.log(event, args);
  console.log(args[0]?.gameId);
  
  if (event === "new chat message") {
    setNewChatMessageFlag(true);
    console.log("New chat message received"); // You can add any additional logic here
  }
});

export const connectSocket = () => {
  //console.log("I connect");
  socket.on("connect", () => {});
};
export const disconnectSocket = () => {
  //console.log("I disconnect");
  socket.disconnect();
};

export const emitInit = async () => {
  const access_token = localStorage?.getItem("token");
  const response = await new Promise((resolve) => {
    socket.emit("init", { access_token: access_token }, (response) => {
      resolve(response);
      //console.log(response);
    });
  });
  return await response;
};

export const listenForRefreshSocial = (callback) => {
  socket.on("refreshSocial", callback);
};

export const stopListenForRefreshSocial = (callback) => {
  socket.off("refreshSocial", callback);
};

export const listenForRefreshUserData = (callback) => {
  socket.on("refreshUserData", callback);
};

export const stopListenForRefreshUserData = (callback) => {
  socket.off("refreshUserData", callback);
};

export const emitUseBooster = (boosterId, gameId, questionNo, callback) => {
  socket.emit(
    "use booster",
    { boosterId: boosterId, gameId: gameId, questionNo: questionNo },
    callback
  );
};

export const listenToFogScreenBooster = (callback) => {
  socket.on("fog", callback);
};

export const stopListenToFogScreenBooster = (callback) => {
  socket.off("fog", callback);
};

export const listenToSplatterBooster = (callback) => {
  socket.on("splatter", callback);
};

export const stopListenToSplatterBooster = (callback) => {
  socket.off("splatter", callback);
};

export const listenToShuffleAnswersBooster = (callback) => {
  socket.on("shuffle answers", callback);
};

export const stopListenToShuffleAnswersBooster = (callback) => {
  socket.off("shuffle answers", callback);
};

export const listenToMirrorAnswersBooster = (callback) => {
  socket.on("mirror answers", callback);
};

export const stopListenToMirrorAnswersBooster = (callback) => {
  socket.off("mirror answers", callback);
};

export const emitNewGameVersus = (callback) => {
  //console.log("want to start new game");
  socket.emit("new game versus", callback);
};

export const listenGameStartVersus = (getGameInfo) => {
  //console.log('listening for game start at "game start versus"');
  socket.on("game start versus", (args) => {
    //console.log("game started", args);
    getGameInfo(args);
  });
};

export const stopListenGameStartVersus = (callback) => {
  socket.off("game start versus", callback);
};

export const emitStartBotVersus = (callback) => {
  //console.log("want to start new bot game");
  socket.emit("start bot versus", callback);
};

export const emitGetQuestionVersus = async (
  { gameId, questionNo },
  getQuestionInfo
) => {
  let lang = await localStorage.getItem("language");
  // Remove extra quotes from lang string
  lang = lang.replace(/"/g, "");
  //console.log(lang);
  await socket.emit(
    "get question versus",
    { gameId, questionNo, lang },
    (args) => {
      getQuestionInfo(args);
    }
  );
};

export const emitUserAnswerVersus = async (
  { gameId, questionNo, userAnswer, timeToAnswer },
  getCorrectAnswer
) => {
  await socket.emit(
    "user answer versus",
    { gameId, questionNo, userAnswer, timeToAnswer },
    (response) => {
      getCorrectAnswer(response);
    }
  );
};

export const listenOpponentAnsweredVersus = (callback) => {
  socket.on("opponent answered versus", callback);
};

export const stopListenOpponentAnsweredVersus = (callback) => {
  socket.off("opponent answered versus", callback);
};

export const listenFinalizeVersus = (callback) => {
  socket.on("finalize versus", callback);
};

export const stopListenFinalizeVersus = (callback) => {
  socket.off("finalize versus", callback);
};

export const emitLeaveLobbyVersus = (callback) => {
  //console.log("leave lobby versus");
  socket.emit("leave lobby versus", callback);
};

export const emitLeaveGameVersus = (callback) => {
  //console.log("leave game versus");
  socket.emit("leave game versus", callback);
};

export const emitLogout = async () => {
  try {
    const acknowledgement = await new Promise((resolve, reject) => {
      //console.log("try to disconnect");
      // Assuming socket is defined somewhere
      socket.emit("logout", (response) => {
        if (response === "success") {
          resolve(response); // Resolve with "success" if server acknowledges
        } else {
          reject(new Error("Logout failed: " + response)); // Reject with error message if not successful
        }
      });
    });

    if (acknowledgement === "success") {
      console.log("Logout successful!");
      disconnectSocket();
    } // No need for "else" block here as any error would be caught in the try-catch block
  } catch (error) {
    // Handle any errors during the logout process
    console.error("Logout failed:", error);
  }
};

// export const emitLogout = () => {
//   console.log("I logout and disconnect");
//   socket.emit("logout", () => {});
//   if (socket) {
//     socket.disconnect();
//   }
// };

export const listenUserJoined = (callback) => {
  socket.on("user joined", callback);
};

export const stopListenUserJoined = (callback) => {
  socket.off("user joined", callback);
};

export const listenUserOccupied = (callback) => {
  socket.on("user occupied", callback);
};

export const stopListenUserOccupied = (callback) => {
  socket.off("user occupied", callback);
};

export const listenUserUnoccupied = (callback) => {
  socket.on("user unoccupied", callback);
};

export const stopListenUserUnoccupied = (callback) => {
  socket.off("user unoccupied", callback);
};

export const listenUserLeft = (callback) => {
  socket.on("user left", callback);
};

export const stopListenUserLeft = (callback) => {
  socket.off("user left", callback);
};

export const emitHeartbeat = () => {
  socket.emit("heartbeat");
};

export const listenNewChatMessage = (callback) => {
  socket.on("new chat message", callback);
};

export const stopListenNewChatMessage = (callback) => {
  socket.off("new chat message", callback);
};

export const emitInviteVersus = (opponentUsername, callback) => {
  socket.emit(
    "invite versus",
    { opponentUsername: opponentUsername },
    callback
  );
};

export const emitDeclineVersus = (opponentUsername, callback) => {
  socket.emit(
    "decline versus",
    { opponentUsername: opponentUsername },
    callback
  );
};

export const emitAcceptVersus = (opponentUsername, callback) => {
  socket.emit(
    "accept versus",
    { opponentUsername: opponentUsername },
    callback
  );
};

export const listenVersusInvitation = (callback) => {
  socket.on("versus invitation", callback);
};

export const stopListenVersusInvitation = (callback) => {
  socket.off("versus invitation", callback);
};

export const listenVersusCanceled = (callback) => {
  socket.on("versus canceled", callback);
};

export const stopListenVersusCanceled = (callback) => {
  socket.off("versus canceled", callback);
};

export const emitCancelInvitation = (opponentUsername, callback) => {
  socket.emit(
    "cancel versus",
    { opponentUsername: opponentUsername },
    callback
  );
};

export const listenVersusDeclined = (callback) => {
  socket.on("versus declined", callback);
};

export const stopListenVersusDeclined = (callback) => {
  socket.off("versus declined", callback);
};

//sockets for battle royal

// export const emitJoinBattle = (getInitialOpponents) => {
//   socket.emit("join battle", (args) => {
//     getInitialOpponents(args);
//   });
// };

// export const emitLeaveLobbyBattle = (callback) => {
//   console.log("i am gonna leave from here");
//   socket.emit("leave lobby battle", callback);
// };

// export const emitLeaveBattle = (callback) => {
//   console.log("i leave battle");
//   socket.emit("leave battle", callback);
// };

// export const emitGetQuestionBattle = async ({ battleId }, getQuestionInfo) => {
//   const lang = await Cookies.get("language");
//   await socket.emit("get question battle", { battleId, lang }, (args) => {
//     getQuestionInfo(args);
//   });
// };

// export const emitUserAnswerBattle = async (
//   { battleId, questionNo, userAnswer, timeToAnswer },
//   getIfUserContinues
// ) => {
//   await socket.emit(
//     "user answer battle",
//     { battleId, questionNo, userAnswer, timeToAnswer },
//     (response) => {
//       getIfUserContinues(response);
//     }
//   );
// };

// export const listenBattleStarted = (getBattleInfo) => {
//   socket.on("battle started", (args) => {
//     getBattleInfo(args);
//   });
// };

// export const stopListenBattleStarted = (callback) => {
//   socket.off("battle started", callback);
// };

// export const listenCorrectAnswerBattle = (getBattleUpdate) => {
//   socket.on("correct answer battle", (args) => {
//     getBattleUpdate(args);
//   });
// };

// export const stopListenCorrectAnswerBattle = (callback) => {
//   socket.off("correct answer battle", callback);
// };

// export const listenBattleFinished = (callback) => {
//   socket.on("battle finished", (args) => {
//     callback();
//   });
// };

// export const stopListenBattleFinished = (callback) => {
//   socket.off("battle finished", callback);
// };

// export const listenBattleInitializing = (callback) => {
//   socket.on("battle initializing", callback);
// };

// export const stopListenBattleInitializing = (callback) => {
//   socket.off("battle initializing", callback);
// };

// export const listenOpponentJoinedBattleLobby = (getOpponentJoined) => {
//   socket.on("player joined battle lobby", (args) => {
//     getOpponentJoined(args);
//   });
// };

// export const stopListenOpponentJoinedBattleLobby = (callback) => {
//   socket.off("player joined battle lobby", callback);
// };

// export const listenOpponentLeftBattleLobby = (getOpponentLeft) => {
//   socket.on("player left battle lobby", (args) => {
//     getOpponentLeft(args);
//   });
// };

// export const stopListenOpponentLeftBattleLobby = (callback) => {
//   socket.off("player left battle lobby", callback);
// };

// Sockets for Tournament

// Apparently not the way to join the tournament

// export const emitJoinTournament = (tournamentId, callback) => {
//   //console.log("join tournament");
//   socket.emit("join tournament", { tournamentId }, callback);
// };

export const listenTournamentStart = (getTournamentInfo) => {
  //console.log("open listener tournament start");
  socket.on("tournament start", (args) => {
    getTournamentInfo(args);
  });
};

export const listenTournamentQuestion = (getQuestionInfo) => {
  //console.log("open listener send question");
  socket.on("send question", (args) => {
    getQuestionInfo(args);
  });
};

export const emitUserAnswerTournament = ({ answer }, callback) => {
  // turn answer into string to avoid errors
  answer = answer.toString();
  socket.emit("user answer", { answer }, callback);
};

export const listenCorrectAnswerTournament = (getCorrectAnswer) => {
  //console.log("open listener send correct answer");
  socket.on("send answer", (args) => {
    getCorrectAnswer(args);
  });
};

export const listenTournamentFinish = (callback) => {
  //console.log("open listener tournament finish");
  socket.on("tournament finish", callback);
};

export const listenTournamentWinners = (getWinners) => {
  //console.log("open listener tournament winners");
  socket.on("show winners", (args) => {
    getWinners(args);
  });
};

export const stopListenTournamentStart = (callback) => {
  //console.log("close listener tournament start");
  socket.off("tournament start", callback);
};

export const stopListenTournamentQuestion = (callback) => {
  //console.log("close listener send question");
  socket.off("send question", callback);
};

export const stopListenCorrectAnswerTournament = (callback) => {
  //console.log("close listener send correct answer");
  socket.off("send answer", callback);
};

export const stopListenTournamentFinish = (callback) => {
  //console.log("close listener tournament finish");
  socket.off("tournament finish", callback);
};

export const stopListenTournamentWinners = (callback) => {
  //console.log("close listener tournament winners");
  socket.off("show winners", callback);
};
