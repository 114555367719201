import React from "react";
import styles from "./JackpotScreen.module.css";
import Typography from "../../atoms/Typography/Typography";
import Icon from "../../atoms/Icon/Icon";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../AthContext";
import PlayButton from "../../molecules/PlayButton/PlayButton";
import { useNavigate } from "react-router-dom";

const JackpotScreen = ({}) => {
  const { t } = useTranslation();
  const { isMobile } = useAuth();
  const navigate = useNavigate();

  return (
    <div className={styles.tournamentJackpot}>
      <div className={styles.tournamentJackpotIcon}>
        <Icon name="jackpot" folder="characters" size={isMobile ? 250 : 300} />
      </div>
      <div className={styles.tournamentJackpotTextBox}>
        <Typography
          variant="heading3"
          fontWeight={"font500"}
          additionalClass={isMobile ? styles.mobileText : styles.folded}
          color="colorBlack"
        >
          {t("jackpot.text1")}
        </Typography>
        <Typography fontWeight={"font700"} additionalClass={styles.blue}>
          {t("jackpot.text2")}
        </Typography>
        <div className={styles.grouped}>
          <Typography variant="body1" fontWeight={"font400"} color="colorBlack">
            {t("jackpot.text3")}
          </Typography>
          <Typography variant="body1" fontWeight={"font400"} color="colorBlack">
            {t("jackpot.text4")}
          </Typography>
        </div>
        <PlayButton
          primaryClass="skewed-play-button"
          extraClass={styles.playButton}
          primaryText={t("jackpot.buttonText")}
          onClick={() => {
            navigate("/");
          }}
        />
      </div>
    </div>
  );
};

export default JackpotScreen;
