import React, { useEffect, useRef } from "react";
import styles from "./QuestionAndAnswers.module.css";
import Typography from "../../atoms/Typography/Typography";
import AnswerButton from "../../molecules/AnswerButton/AnswerButton";
import gsap from "gsap";

const QuestionAndAnswers = ({
  currentQuestion,
  handleButtonClick,
  areResultsDisplayed,
  selectedAnswer,
}) => {
  const buttonsContainerRef = useRef(null);

  const answers = [
    currentQuestion.answer1, 
    currentQuestion.answer2, 
    currentQuestion.answer3, 
    currentQuestion.answer4
  ];

  const correctAnswer = answers[currentQuestion.correctAnswer - 1];

  useEffect(() => {
    const buttons = buttonsContainerRef.current.querySelectorAll("button");

    // Animate buttons on render
    gsap.fromTo(
      buttons,
      { opacity: 0, y: 50, scale: 0.8 },
      {
        opacity: 1,
        y: 0,
        scale: 1,
        duration: 0.5,
        stagger: 0.1, // Add delay between each button
        ease: "power3.out"
      }
    );
  }, [currentQuestion]); // Re-run animation when question changes

  return (
    <>
      <Typography variant="heading1" fontWeight="font700" color="colorBlack" additionalClass={styles.question}>
        {currentQuestion.question}
      </Typography>
      <div className={styles.buttonsContainer} ref={buttonsContainerRef}>
        {answers.map((answer, index) => (
          <AnswerButton
            key={index}
            buttonText={answer}
            onClick={() => handleButtonClick(answer, index)}
            additionalClass={
              areResultsDisplayed
                ? answer === correctAnswer
                  ? "correctAnswerClass"
                  : selectedAnswer === answer
                  ? "wrongAnswerClass"
                  : ""
                : selectedAnswer === answer
                ? "selectedAnswerClass"
                : ""
            }
          />
        ))}
      </div>
    </>
  );
};

export default QuestionAndAnswers;
