import React from "react";
import Typography from "../../atoms/Typography/Typography";
import Avatar from "../../molecules/Avatar/Avatar";
import ProfileUserStatistics from "../../organisms/ProfileUserStatistics/ProfileUserStatistics";
import styles from "./OtherUserProfileTemplate.module.css";
import ProfileOtherUserAssets from "../../organisms/ProfileOtherUserAssets/ProfileOtherUserAssets";

const OtherUsersProfileTemplate = ({ 
  playerData, 
  friendRequestsList, 
  setFriendRequestsList, 
  outgoingFriendRequestsList, 
  setRefreshSocialTriggered,
  friendsList,
  setSelectedUser,
  setFilteredUserList, 
  setFriendRequestSent
}) => {
  return (
    <div className={styles.profileTemplate}>
      <div className={styles.profileInfoSection}>
        <div className={styles.avatarArea}>
          <Avatar
            icon={`avatar-${playerData?.avatarId}`} // Dynamically constructs the icon name
            imageSize={"medium"}
            additionalClass={styles.avatar}
          />
          <Typography
            fontWeight={"font700"}
            color={"colorBlack"}
            additionalClass={styles.font24}
          >
            {playerData.username}
          </Typography>
        </div>
        <div>
          <ProfileOtherUserAssets 
            playerData={playerData} 
            friendRequestsList={friendRequestsList} 
            setFriendRequestsList={setFriendRequestsList} 
            outgoingFriendRequestsList={outgoingFriendRequestsList} 
            setRefreshSocialTriggered={setRefreshSocialTriggered} 
            friendsList={friendsList}
            setSelectedUser={setSelectedUser}
            setFilteredUserList={setFilteredUserList}
            setFriendRequestSent={setFriendRequestSent}
          />
        </div>
      </div>

      <ProfileUserStatistics playerData={playerData} />
    </div>
  );
};

export default OtherUsersProfileTemplate;
