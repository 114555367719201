import React from "react";
import Icon from "../../atoms/Icon/Icon.js";
import styles from "./StoreBonusAddOn.module.css";

const StoreBonusAddOn = ({ imageName }) => {
  return (
    <Icon
      name={imageName}
      folder={"store"}
      size={"50"}
      additionalClass={styles.bonusIcon}
    />
  );
};

export default StoreBonusAddOn;
