import React from "react";
import styles from "./WaitingScreen.module.css";
import WaitingScreenContent from "../../molecules/WaitingScreenContent/WaitingScreenContent";
import BackgroundAvatars from "../../molecules/BackgroundAvatars/BackgroundAvatars";
import LoadingSpinner from "../../molecules/LoadingSpinner/LoadingSpinner";

const WaitingScreen = () => {
  return (
    <div className={styles.container}>
      <BackgroundAvatars />
      <WaitingScreenContent />
      <LoadingSpinner />
    </div>
  );
};

export default WaitingScreen;
