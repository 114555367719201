import React from "react";
import styles from "./BackgroundGeneric.module.css";

import Icon from "../../atoms/Icon/Icon";

const BackgroundGeneric = () => {
  return (
    <Icon
      additionalClass={styles["background-image"]}
      name="genericBackground"
      folder="other_icons"
    />
  );
};

export default BackgroundGeneric;
