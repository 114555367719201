import React, { useRef } from "react";
import Icon from "../../atoms/Icon/Icon";
import Button from "../../atoms/Button/Button";
import styles from "./LoginIcons.module.css";
import { useGoogleLogin } from "@react-oauth/google";
import AppleLogin from "react-apple-login";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { useAuth } from "../../../AthContext";

const LoginIcons = ({
  setShowAvatarInput,
  setShowLoginPage,
  setSocialRegister,
  setEmailFromSocial,
  setPasswordFromSocial,
  setTokenFromGoogle,
  onLogin,
  onAppleLoginSuccess,
  setSocialLoginType,
  usernameText /* to be removed */,
}) => {
  const { handleInputChange, handleGoogleLogin, handleFacebookLogin } =
    useAuth(); // Using the useAuth hook
  const fbKey = process.env.REACT_APP_FACEBOOK_CLIENT_ID;
  const facebookToken = useRef("");

  const onFacebookLoginSuccess = async (response, token) => {
    try {
      const userData = await handleFacebookLogin(response, token);
      if (!userData) {
        console.error("Failed to get user data from Facebook.");
        return;
      }

      console.log(
        `User Data: Email: ${userData.email}, Token: ${userData.token}, id: ${userData.id}`
      );
      console.log(userData);
      // Check for specific error code and adjust UI accordingly
      if (userData.errorCode === 2) {
        setTokenFromGoogle(userData.token);
        setEmailFromSocial(userData.email);
        setPasswordFromSocial(userData.id);
        setShowLoginPage(false);
        setShowAvatarInput(true);
        setSocialRegister(true);
      } else if (!userData.errorCode) {
        // Fixed syntax here
        onLogin();
      }
    } catch (error) {
      console.error("Error during facebook login process:", error);
    }
  };

  const onGoogleLoginSuccess = async (response) => {
    try {
      const userData = await handleGoogleLogin(response);
      if (!userData) {
        console.error("Failed to get user data from Google.");
        return;
      }

      console.log(
        `User Data: Email: ${userData.email}, Token: ${userData.token},Sub: ${userData.sub}`
      );
      console.log(userData);
      // Check for specific error code and adjust UI accordingly
      if (userData.errorCode === 2) {
        setSocialLoginType("google");
        setTokenFromGoogle(userData.token);
        setEmailFromSocial(userData.email);
        setPasswordFromSocial(userData.sub);
        setShowLoginPage(false);
        setShowAvatarInput(true);
        setSocialRegister(true);
      } else if (!userData.errorCode) {
        // Fixed syntax here
        onLogin();
      }
    } catch (error) {
      console.error("Error during Google login process:", error);
    }
  };

  // Assuming useGoogleLogin and other parts of your component setup are correct
  const gLogin = useGoogleLogin({
    onSuccess: onGoogleLoginSuccess,
  });

  console.log("usernameText:", usernameText); /* to be removed */

  return (
    <div className={styles.loginIconsContainer}>
      <Button
        additionalClass={styles.googleButton}
        onClick={() => {
          gLogin();
          handleInputChange("type", "google");
        }}
        type="button"
      >
        <Icon folder="social_v2" name="google_login" size={32} />
      </Button>
      {usernameText === "helloadmin" /* to be removed */ && (
        <Button additionalClass={styles.appleButton}>
          <AppleLogin
            clientId="com.quizdom.quizdombattle"
            redirectURI="https://www.quizdombattle.com/forms"
            usePopup={true}
            onSuccess={onAppleLoginSuccess} // Pass the onSuccess handler
            onFailure={(error) => console.error("Apple login error", error)}
            render={(renderProps) => (
              <button
                onClick={() => {
                  renderProps.onClick();
                }}
                disabled={renderProps.disabled}
                className={styles.appleButton} // Ensures button styles
              >
                <Icon folder="social_v2" name="apple_login" size={45} />
              </button>
            )}
          />
        </Button>
      )}
    </div>
  );
};

export default LoginIcons;
