import React, { useEffect, useRef, useState } from "react";
import styles from "./LanguageMenu.module.css";
import Icon from "../../atoms/Icon/Icon";
import Typography from "../../atoms/Typography/Typography";
import Button from "../../atoms/Button/Button";
import { useAuth } from "../../../AthContext";
import { play } from "../../../services/helpers";

const LanguageMenu = ({ toggleLanguageModal }) => {
  const { setLanguage } = useAuth(); // Access setLanguage function from AuthContext
  const languageList = [
    { name: "english", code: "en" },
    { name: "deutsch", code: "de" },
    { name: "ελληνικά", code: "el" },
    // { name: "italiano", code: "it" },
    // { name: "español", code: "es" },
    // { name: "polski", code: "pl" },
    // { name: "română", code: "ro" },
  ];

  const menuRef = useRef(null);

  useEffect(() => {
    // Function to close the menu when clicking outside
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        // Click outside the menu
        toggleLanguageModal(false);
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [toggleLanguageModal]);

  const handleLanguageChange = (languageCode) => {
    const language = languageList.find((lang) => lang.name === languageCode);
    if (language) {
      play();

      setLanguage(language.code);
      toggleLanguageModal(false);
    }
  };

  return (
    <div className={styles.languageMenu} ref={menuRef}>
      {languageList.map((language, index) => (
        <Button
          key={index}
          additionalClass={styles.listItem}
          onClick={() => handleLanguageChange(language.name)}
        >
          <Icon
            name={language.name}
            folder={"flags"}
            size={16}
            additionalClass=""
          />
          <Typography
            variant={"body2"}
            fontWeight={"font500"}
            color="colorBlack"
          >
            {language.name}
          </Typography>
        </Button>
      ))}
    </div>
  );
};

export default LanguageMenu;
