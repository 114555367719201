import React, { useEffect, useRef, useState } from "react";
import Typography from "../../atoms/Typography/Typography";
import Icon from "../../atoms/Icon/Icon";
import Button from "../../atoms/Button/Button";
import TournamentCardButton from "../TournamentCardButton/TournamentCardButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../AthContext";
import { checkIfUserSubscribed } from "../../../apiHelpers";
import { gsap } from "gsap";
import styles from "./CashQuizTournamentsPanelV2.module.css";

const CashQuizTournamentsPanelV2 = ({
  weekDay,
  imageName1,
  textQuestionsPrize,
  topBackgroundColor,
  prize,
  remainingTime,
  requiredTickets,
  tournamentId,
  date,
}) => {
  const { isMobile, loggedIn } = useAuth();
  const navigate = useNavigate();
  const [isSubscribed, setIsSubscribed] = useState(false);
  const { t } = useTranslation();
  const cardRef = useRef(null);
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const observerRef = useRef(null);

  useEffect(() => {
    if (loggedIn) {
      const checkIfSubscribed = async () => {
        try {
          const response = await checkIfUserSubscribed(tournamentId);
          setIsSubscribed(response.isUserSubscribed);
        } catch (error) {
          console.error("Error in getting tournament info:", error);
        }
      };
      checkIfSubscribed();
    }
  }, [loggedIn]);

  // Animation function
  const startAnimation = () => {
    const timeline = gsap.timeline();

    // Slide in and immediately start squeezing
    timeline
      .fromTo(cardRef.current, 
        { x: 400, scaleX: 1, scaleY: 1 }, 
        { 
          x: 0, 
          duration: 0.4, 
          ease: "power4.out"
        })
      .to(cardRef.current, {
          scaleX: 0.7,
          duration: 0.2,
          transformOrigin: "left center",
          ease: "power2.out"
        }, "<0.1") // Start squeezing just before the slide ends
      .to(cardRef.current, {
          scaleX: 1,
          duration: 0.3,
          ease: "power2.out"
        });
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Only start animation when the area is just below the viewport
          setShouldAnimate(true);
          } else {
          // Reset the animation when the card goes out of the viewport
          setShouldAnimate(false);
        }
      });
    }, { 
      threshold: 0.2, // Trigger as soon as any part of the area is visible
      rootMargin: '50px 0px', // Trigger when the area is 100px below the viewport
    });

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    // Cleanup
    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, []);

  // Trigger animation when shouldAnimate changes
  useEffect(() => {
    if (shouldAnimate) {
      startAnimation();
    } else {
      // Reset the card to its initial state when it leaves the viewport
      gsap.set(cardRef.current, { x: 400, scaleX: 1, scaleY: 1 });
    }
  }, [shouldAnimate]);
  

  const cardClasses = [
    styles.cardContainer,
    weekDay === "SUNDAY" ? styles.enableGoldBoarder : null,
  ].join(" ");

  

  return (
    <div ref={observerRef} className={styles.renderArea}>
      <div
        className={cardClasses}
        ref={cardRef}
        style={{ opacity: shouldAnimate ? 1 : 0 }} // Control visibility
      >
        <span className={styles.getTicket}>
          <Typography variant={"caption"} fontWeight={"font900"}>
            {"GET YOUR TICKET"}
          </Typography>
        </span>

        <Icon
          className={styles.character}
          name={imageName1}
          folder={"tournaments"}
          size={180}
          color="black"
          additionalClass={styles.icon}
        />
        <Typography
          variant={isMobile ? "body2" : "heading2"}
          fontWeight={"font900"}
          additionalClass={styles.textQuestionsPrize}
        >
          {textQuestionsPrize}
        </Typography>
        <div className={styles.dateContainer}>
          <Typography
            additionalClass={styles.weekDay}
            variant={isMobile ? "heading3" : "heading2"}
            fontWeight={"font900"}
          >
            {weekDay.toUpperCase().split(" ")[0]}
          </Typography>
          <div className={styles.date}>
          <Typography
            variant={isMobile ? "body2" : "heading3"}
            fontWeight={"font900"}
            additionalClass={styles.dateHourText}
          >
            &nbsp;
            {loggedIn
              ? `${date.substring(8, 10)}/${date.substring(5, 7)} - ${t(
                  "mainNav.homeTab.prizeTournaments.remainingTime"
                )}`
              : date}
          </Typography>
          {loggedIn ?
          <Typography additionalClass={styles.timeZoneText}>
              {`${t('mainNav.homeTab.prizeTournaments.timeZone')}`}
          </Typography>
          : ""}
          </div>
        </div>
        <div className={`${styles.cardTop} ${styles[topBackgroundColor]}`}></div>
        <div className={styles.cardBottom}>
          <div className={styles.cardBottomInfo}>
            <span>
              <Typography
                color="colorBlack"
                variant={isMobile ? "small" : "body2"}
              >
                {"Prize Pool"}
              </Typography>
              <Button
                additionalClass={
                  prize > 50 ? styles.goldButton : styles.greenButton
                }
              >
                {prize} €
              </Button>
            </span>
            <span>
              <Typography
                color="colorBlack"
                variant={isMobile ? "body2" : "heading3"}
              >
                {loggedIn ? "STARTS IN" : "STARTS AT"}
              </Typography>
              {remainingTime && (
                <Typography
                  color="colorBlack"
                  variant={isMobile ? "small" : "body2"}
                  fontWeight={"font400"}
                >
                  {remainingTime}
                </Typography>
              )}
            </span>
            <span>
              <Typography
                color="colorBlack"
                variant={isMobile ? "small" : "body2"}
              >
                {"Entry fee"}
              </Typography>
              <Button additionalClass={styles.greyButton}>
                {requiredTickets} {requiredTickets > 1 ? "tickets" : "ticket"}
              </Button>
            </span>
          </div>
          <div className={styles.cardBottomButton}>
            {!loggedIn && (
              <Button
                additionalClass={styles.registerGoldButton}
                onClick={() => {
                  navigate("/forms");
                }}
              >
                <Typography
                  variant={isMobile ? "caption" : "body1"}
                  color="colorBlack"
                >
                  {"Register Now"}
                </Typography>
              </Button>
            )}
            {loggedIn && (
              <TournamentCardButton
                remainingTime={remainingTime}
                tournamentId={tournamentId}
                isSubscribed={isSubscribed}
                onSub={setIsSubscribed}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CashQuizTournamentsPanelV2;
