import React from 'react';
import styles from './BlockedPeson.module.css';
import Icon from '../../atoms/Icon/Icon';
import Typography from '../../atoms/Typography/Typography';
import Button from '../../atoms/Button/Button';
import { useTranslation } from 'react-i18next';

const BlockedPerson = ({ avatarImageId, avatarName, avatarLevel, onUnblock }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.blockedPersonContainer}>
      <div className={styles.blockedUserInfos}>
        <Icon name={`avatar-${avatarImageId}`} folder="avatars" size={48}></Icon>
        <div className={styles.textInfos}>
          <Typography variant="body1" fontWeight="font700" color="colorBlack">
            {avatarName}
          </Typography>
          <Typography variant="caption" fontWeight="font400" color="colorBlack" additionalClass={styles.levelText}>
            level:{avatarLevel}
          </Typography>
        </div>
      </div>

      <Button onClick={onUnblock}>
        <Typography variant="caption" fontWeight="font500" additionalClass={styles.textButton}>
          {t('generic.unblock')}     
        </Typography>
      </Button>
    </div>
  );
};

export default BlockedPerson;
