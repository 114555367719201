import React, { useState } from "react";
import PropTypes from "prop-types";
import Typography from "../../atoms/Typography/Typography";
import Icon from "../../atoms/Icon/Icon";
import styles from "./CategorySelectionPanel.module.css";
import { useAuth } from "../../../AthContext";

const TournamentPanel = () => {
  const { isMobile } = useAuth();

  return (
    <div
      className={`${styles.panel2}`}
      onClick={() => console.log("tournament pressed")}
    >
      <div className={styles.content2}>
        <Icon
          extraClass={styles.icon}
          name={"illustration_10"}
          folder={"characters"}
          size={isMobile ? 70 : 146}
        />
        <div className={styles.textContainer}>
          <Typography variant="heading2" fontWeight="font900">
            {"Cash Tournaments"}
          </Typography>
          <div className={styles.textContainerSubtitle}>
            <Typography variant="body2" fontWeight="font500" italian>
              {"COMING SOON"}
            </Typography>
          </div>
        </div>
      </div>
      <div className={`${styles.rectangle} ${styles.darkBlue}`}></div>
    </div>
  );
};

const TournamentBanner = () => {
  const { isMobile } = useAuth();
  return (
    <div
      className={`${styles.tournamentBanner}`}
      onClick={() => console.log("tournament banner pressed")}
    >
      <div className={styles.tournamentBannerContent}>
        <div className={styles.tournamentBannerTextContainer}>
          <Typography variant="heading3" fontWeight="font900">
            {"Cash Tournaments"}
          </Typography>
          <Typography variant="caption" fontWeight="font500" italian>
            {"COMING SOON"}
          </Typography>
        </div>
        <Icon
          name={"illustration_10"}
          folder={"characters"}
          size={isMobile ? 112 : 146}
        />
      </div>
      <div className={`${styles.bannerRectangle} ${styles.darkBlue}`}></div>
    </div>
  );
};

const CategorySelectionPanel = ({
  title,
  icon,
  color = "grey",
  selected: initialSelected,
  onClick,
  isTournament,
  tournamentBanner,
  canSelectMore
}) => {
  const [selected, setSelected] = useState(initialSelected);
  let folder = "categories";
  const { isMobile, language } = useAuth();


  const handleClick = () => {
    // Check if new selections are allowed before toggling `selected`
    if (!selected && !canSelectMore) {
      console.log("Maximum of 5 categories can be selected.");
      return; // Prevent selecting a new category
    }
    setSelected(!selected);
    if (onClick) {
      onClick(!selected);
    }
  };

  return (
    <>
      {isTournament ? (
        <TournamentPanel />
      ) : tournamentBanner ? (
        <TournamentBanner />
      ) : (
        <div
          className={`${styles.panel} ${selected ? styles.selected : ""}`}
          onClick={handleClick}
        >
          <div className={`${styles.content}`}>
            <Icon
              name={selected ? icon : `${icon}-off`}
              folder={folder}
            />
            <div className={styles.textContainer}>
              <Typography
                variant={isMobile ? "body2" : "heading2"}
                fontWeight="font900"
              >
                {title}
              </Typography>
            </div>
          </div>
          <div
            className={`${styles.rectangle} ${
              selected ? styles[color] : styles.grey
            }`}
          ></div>
        </div>
      )}
    </>
  );
};

CategorySelectionPanel.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.oneOf([
    "red",
    "blue",
    "green",
    "orange",
    "purple",
    "lightOrange",
    "darkOrange",
    "lightGreen",
    "magenta",
  ]), // Add more colors as needed
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  isTournament: PropTypes.bool,
};

export default CategorySelectionPanel;
