import React from "react"
import styles from "./SettingsMenu.module.css"
import Typography from "../../atoms/Typography/Typography"
import Button from "../../atoms/Button/Button"
import { useTranslation } from "react-i18next"

const SettingsMenu = () => {
  const { t } = useTranslation()

  const languageCode = localStorage.getItem("language")?.replace(/"/g, "");

  const faqUrlLanguageProp = languageCode === "en" ? "En" : languageCode === "de" ? "De" : "";
  const faqUrl = `https://api.quizdombattle.com/faq${faqUrlLanguageProp}`;

  const imprintUrlLanguagePro = languageCode === "de" ? "De" :"En"
  const imprintUrl = `https://api.quizdombattle.com/imprint${imprintUrlLanguagePro}`
  
  const termsUrlLanguagePro = languageCode === "de" ? "De" :"En"
  const termsUrl = `https://api.quizdombattle.com/terms${termsUrlLanguagePro}`

  const policyUrlLanguagePro = languageCode === "de" ? "De" :"En"
  const policyUrl = `https://api.quizdombattle.com/privacy${policyUrlLanguagePro}`
  
  console.log(imprintUrl)

  const settingsList = [
    { name: t("generic.faq"), url: faqUrl },
    { name: t("generic.contactUs"), url: "mailto:support@quizdombattle.com" },
    { name: t("mainNav.profileTab.buttons.settings.settingsModal.terms"), url: termsUrl },
    { name: t("mainNav.profileTab.buttons.settings.settingsModal.policy"), url: policyUrl },
    { name: t("generic.imprint"), url: imprintUrl}
  ];

  return (
    <div className={styles.settingsMenu}>
      {settingsList.map((item, index) => (
        <Button
          key={index}
          additionalClass={styles.listItem}
          onClick={() => {
            // Only try to open the URL if it exists
            if (item.url) {
              // window.open = item.url
              // e.preventDefault()
              window.open(item.url, "_blank")
            }
          }}
        >
          <Typography variant={"body2"} fontWeight={"font500"} color="colorBlack">
            {item.name}
          </Typography>
        </Button>
      ))}
    </div>
  )
}

export default SettingsMenu
