import React, { useState } from "react";
import styles from "./StripeCheckoutForm.module.css";
import { addPayment, completePayment } from "../../../apiHelpers";
import {
  useElements,
  useStripe,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useLocation, useNavigate } from "react-router-dom";
import PlayButton from "../../molecules/PlayButton/PlayButton";
import Icon from "../../atoms/Icon/Icon";
import { isMobile } from "react-device-detect";
import { useAuth } from "../../../AthContext";
import Typography from "../../atoms/Typography/Typography";
import { useTranslation } from "react-i18next";

const StripeCheckoutForm = () => {
  const elements = useElements();
  const stripe = useStripe();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isPaymentCompleted, setIsPaymentCompleted] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { clientSecret, androidProductId, priceToPay } = location.state || {};
  const { isMobile } = useAuth();
  const { t } = useTranslation();

  const saveTransactionResultToDB = async (status, transactionId) => {
    try {
      const result = await completePayment(
        androidProductId,
        clientSecret,
        status,
        transactionId
      );
      console.log("Transaction saved:", result);
      setIsPaymentCompleted(true);
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to save transaction:", error);
      setMessage("Failed to save transaction result.");
    }
  };

  const checkPaymentIntent = async (response) => {
    const { error, paymentIntent } = response;
    if (error) {
      setMessage(error.message || "An error occurred during payment.");
      console.error("Error:", error);
      setIsLoading(false);
    } else if (paymentIntent) {
      console.log(`PaymentIntent status: ${paymentIntent.status}`);
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded");
          saveTransactionResultToDB(paymentIntent.status, paymentIntent.id);
          break;
        case "processing":
          setMessage("Payment is processing");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          setIsLoading(false); // Set loading to false immediately for clear retry prompt
          break;
        default:
          setMessage("Something went wrong with your payment.");
          setIsLoading(false);
          break;
      }
    }
  };

  const handleSubmit = async (event) => {
    if (!stripe || !elements) {
      console.log("Stripe or elements not loaded.");
      setMessage("Stripe or elements not loaded.");
      return;
    }

    setIsLoading(true);

    try {
      // Call your backend to create the PaymentIntent
      const paymentResponse = await addPayment(androidProductId, clientSecret);
      console.log("Payment response:", paymentResponse);

      // Generalized payment method confirmation
      const confirmResponse = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/`,
        },
        redirect: "if_required", // This ensures redirect happens only if absolutely necessary
      });

      await checkPaymentIntent(confirmResponse);
    } catch (error) {
      console.error("Unhandled error during payment confirmation:", error);
      setMessage(error.message || "An error occurred during payment.");
      setIsLoading(false);
    }
  };

  const handleButtonClick = () => {
    if (isPaymentCompleted) {
      navigate("/", { replace: true });
    } else {
      handleSubmit();
    }
  };

  const handleCloseClick = () => {
    navigate("/store", { replace: true }); // This will navigate the user to /store when the icon is clicked
  };

  const certificationLogosInfo = [
    { name: "mastercard-logo", size: isMobile ? "35" : "55" },
    { name: "visa-logo", size: isMobile ? "80" : "120" },
    { name: "sectigo-logo", size: isMobile ? "90" : "120" },
    { name: 'ssl-logo', size: isMobile ? "100" : "150"},
    { name: 'stripe-logo', size: isMobile ? "100" : "130"}
  ];

  return (
    <div className={styles.modalWithPaymentWrapper}>
      <form className={styles.paymentForm} onSubmit={handleSubmit}>
        <Icon
          name="x_pay_module"
          folder="other_icons"
          size={25}
          onClick={handleCloseClick}
          additionalClass={`${styles.closePurchaseModalButton} ${
            isLoading ? styles.closePurchaseModalButtonDisabled : ""
          }`}
        />

        <PaymentElement className={styles.paymentElement} />
        <div className={styles.certificationLogosContainer}>
          {certificationLogosInfo.map(({ index, name, size }) => (
            <Icon
              key={index}
              name={name}
              folder="stripe"
              size={size}
              additionalClass={styles.logoIcon}
            />
          ))}
        </div>
        <Typography color="black" variant="body2" additionalClass={styles.guaranteeText1}>
          {t('generic.moneyGuarantee1')}
        </Typography>
        <Typography color="black" variant="body2" additionalClass={styles.guaranteeText2}>
          {t('generic.moneyGuarantee2')}
        </Typography>
        <PlayButton
          extraClass={`${styles.payButton} ${isLoading ? styles.disabled : ""}`}
          disabled={isLoading}
          primaryText={
            isPaymentCompleted
              ? "Go play"
              : isLoading
              ? "Processing…"
              : priceToPay
              ? `Pay ${priceToPay} €`
              : "Pay"
          }
          onClick={handleButtonClick}
        />
        {message && <p>{message}</p>}
      </form>
    </div>
  );
};

export default StripeCheckoutForm;
