import React from 'react';
import styles from './FancySpinWheelArrow.module.css';
import Icon from '../../atoms/Icon/Icon';

const FancySpinWheelArrow = ({ size = 100, top = '' }) => {
  return (
    <Icon
      name="wheel_arrow"
      folder="other_icons"
      size={size}
      style={{
        top: top,
      }}
      additionalClass={styles.arrow}
    />
  );
};

export default FancySpinWheelArrow;
