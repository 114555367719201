import axios from 'axios';

import { alreadyLogged, fetchUserDataFB, loginUserFB } from './facebookHelpers';
import { disconnectSocket, emitLogout } from './services/sockets';

const handleRequestError = (error) => {
  console.error('Request failed:', error);
  throw error;
};
//fetch
export const userLogin = async (formData) => {
  try {
    const language = localStorage.getItem('language').replaceAll('"', '');
    const defaultConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        lang: language,
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/user/login`,
      {
        type: formData.type,
        email: formData.email,
        password: formData.password,
      },
      defaultConfig
    );

    const { token, id } = response.data;
    localStorage.setItem('token', token);
    localStorage.setItem('id', id);

    return { ...response.data, token };
  } catch (error) {
    handleRequestError(error);
  }
};

export const forgotPassword = async (userEmail) => {
  try {
    const language = localStorage.getItem('language').replaceAll('"', '');
    const defaultConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        lang: language,
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/user/forgot`,
      {
        email: userEmail,
      },
      defaultConfig
    );
    return response;
  } catch (error) {
    throw error;
  }
};
// const logout = async () => {
//   try {
//     localStorage.clear();

//     const acknowledgement = await emitLogout();

//     if (acknowledgement === "success") {
//       disconnectSocket();
//     } else {
//       console.error("Logout failed:", acknowledgement);
//     }
//   } catch (error) {
//     console.error("Logout failed:", error);
//   }
// };
// apple login not used for now
// export const handleAppleLogin = async (response) => {
//   // start a login request flow

//   let tokenCode = response?.authorization?.id_token;

//   if (tokenCode) {
//     let authDecoded = jwtDecode(tokenCode);
//     try {
//       // Handle the decoded token without dispatching actions
//       console.log("Decoded token:", authDecoded);
//     } catch (e) {
//       console.log(e);
//     }
//   } else {
//     console.log("id_token not found");
//   }
// };

const handleFacebookLogin = async () => {
  try {
    const checkIfLogged = await alreadyLogged();
    let accessToken;
    if (checkIfLogged?.status === 'connected') {
      accessToken = checkIfLogged?.authResponse?.accessToken;
    } else if (checkIfLogged?.status === 'not_authorized') {
      console.log('well well well');
    } else {
      accessToken = await loginUserFB();
      if (!accessToken) {
        return;
      }
    }
    const userFbdata = await fetchUserDataFB(accessToken);

    // Handle the fetched user data without dispatching actions
    console.log('User Facebook data:', userFbdata);
  } catch (error) {
    console.log(error);
  }
};

export const getUserData = async () => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');
    const defaultConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/user/data`,
      defaultConfig
    );
    const userData = JSON.stringify(response?.data?.userData[0]);
    localStorage.setItem('userData', userData);
    return { ...response.data }; // Return both user data and token
  } catch (error) {
    throw error;
  }
};

export const getUserInfo = async (id) => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');
    const defaultConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
      params: { userId: id },
    };
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/user/info`,
      defaultConfig
    );
    const userInfo = JSON.stringify(response?.data?.userInfo);
    localStorage.setItem('userInfo', userInfo);
    return { ...response?.data?.userInfo };
  } catch (error) {
    throw error;
  }
};

export const getUserDataFromLocalStorage = () => {
  const storedUserData = localStorage.getItem('userData');

  let userData;

  // Check if storedUserData is not null and is parseable as JSON
  if (storedUserData) {
    try {
      userData = JSON.parse(storedUserData);
    } catch (error) {
      console.error('Error parsing stored user data:', error);
    }
  }

  return userData;
};

export const getQuotesAndInfosForTheDay = async () => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');
    const defaultConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/misc/sansimera`,
      defaultConfig
    );

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
// 1. Retrieve Store Data
export const getStoreData = async () => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');
    const defaultConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/store/data2`,
      defaultConfig
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAllTimeData = async (lastIndex) => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');
    const defaultConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/leaderboard/allTime?lastIndex=${lastIndex}`,
      defaultConfig
    );

    // Handle the successful response
    console.log('All time leaderboard data:', response.data);

    // You can return the data if needed
    return response.data;
  } catch (error) {
    // Handle errors
    console.error('Failed to fetch all time leaderboard data:', error);
    throw error; // Rethrow the error to let the caller handle it
  }
};

export const getFriendsLeaderboardData = async (lastIndex) => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');
    const defaultConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/leaderboard/friendsLeaderboard?lastIndex=${lastIndex}`,
      defaultConfig
    );

    // Handle the successful response
    console.log('Friends leaderboard data:', response.data);

    // You can return the data if needed
    return response.data;
  } catch (error) {
    // Handle errors
    // await userLogin({ ...localStorage });
    console.error('Failed to fetch friends data:', error);
    // throw error; // Rethrow the error to let the caller handle it
  }
};

export const deleteAccount = async () => {
  // Display confirmation dialog to the user
  const isConfirmed = window.confirm(
    'Are you sure you want to delete your account?'
  );

  // Proceed only if the user confirms
  if (isConfirmed) {
    try {
      const token = localStorage.getItem('token');
      const language = localStorage.getItem('language').replaceAll('"', '');
      const defaultConfig = {
        headers: {
          'Content-Type': 'application/json',
          lang: language,
          Authorization: token ? `Bearer ${token}` : '',
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/user/deleteAccount`,
        defaultConfig
      );

      if (response.status === 200) {
        alert('Account successfully deleted.');
      } else {
        alert('Failed to delete account. Please try again.');
      }
    } catch (error) {
      console.error('Error deleting account:', error);
      alert('An error occurred. Please try again.');
    }
  } else {
    // If user cancels, handle accordingly (optional)
    console.log('Account deletion cancelled by the user.');
  }
};

export const updateAvatar = async (newAvatar) => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');
    const defaultConfig = {
      headers: {
        'Content-Type': 'application/json',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/user/avatar`,
      {
        avatarId: newAvatar.id, // Send the selected avatar ID in the request body
      },
      defaultConfig
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const sendFriendRequest = async (id) => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');
    const defaultConfig = {
      headers: {
        'Content-Type': 'application/json',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/social/friends/add`,
      {
        userId: id,
      },
      defaultConfig
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getFriends = async () => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');
    const defaultConfig = {
      headers: {
        'Content-Type': 'application/json',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/social/friends`,
      defaultConfig
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getFriendRequest = async () => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');
    const defaultConfig = {
      headers: {
        'Content-Type': 'application/json',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/social/friends/pending`,
      defaultConfig
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const acceptFriendRequest = async (id) => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');
    const defaultConfig = {
      headers: {
        'Content-Type': 'application/json',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/social/friends/accept`,
      {
        userId: id,
      },
      defaultConfig
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const declineFriendRequest = async (id) => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');
    const defaultConfig = {
      headers: {
        'Content-Type': 'application/json',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/social/friends/decline`,
      {
        userId: id,
      },
      defaultConfig
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const cancelFriendRequest = async (id) => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');
    const defaultConfig = {
      headers: {
        'Content-Type': 'application/json',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/social/friends/request/remove`,
      {
        userId: id,
      },
      defaultConfig
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const removeFriend = async (id) => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');
    const defaultConfig = {
      headers: {
        'Content-Type': 'application/json',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/social/friends/remove`,
      {
        userId: id,
      },
      defaultConfig
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getBlockedUsers = async () => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');
    const defaultConfig = {
      headers: {
        'Content-Type': 'application/json',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/social/blocked`,
      defaultConfig
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const blockUser = async (id) => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');
    const defaultConfig = {
      headers: {
        'Content-Type': 'application/json',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/social/blocked/add`,
      {
        userId: id,
      },
      defaultConfig
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const unblockUser = async (id) => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');
    const defaultConfig = {
      headers: {
        'Content-Type': 'application/json',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/social/blocked/remove`,
      {
        userId: id,
      },
      defaultConfig
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

///\\  ================================================  CHAT  =======================================================   \\///

export const getChatId = async (id) => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');
    const defaultConfig = {
      headers: {
        'Content-Type': 'application/json',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
      params: {
        opponentId: id,
      },
    };
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/chat/id`,
      defaultConfig
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getChatList = async () => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');
    const defaultConfig = {
      headers: {
        'Content-Type': 'application/json',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/chat/list`,
      defaultConfig
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const sendMessage = async (id, message) => {
  // Default message to ' ' if not provided
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');
    const defaultConfig = {
      headers: {
        'Content-Type': 'application/json',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/chat/message`,
      {
        message,
        opponentId: id,
      },
      defaultConfig
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getNewestMessages = async (messageId, opponentId) => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');
    const defaultConfig = {
      headers: {
        'Content-Type': 'application/json',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
      params: {
        lastMessageId: ' ',
        opponentId: opponentId,
      },
    };
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/chat/messages/newest`,
      defaultConfig
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteMessages = async (id) => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');
    const defaultConfig = {
      headers: {
        'Content-Type': 'application/json',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/chat/delete`,
      {
        chatId: id,
      },
      defaultConfig
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

//search

export const handleSearchSubmit = async (searchQuery, setFilteredUserList) => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');
    const defaultConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/social/search?username=${searchQuery}`,
      defaultConfig
    );

    // Assuming the response data contains the filtered user list
    const filteredList = response.data.users;

    console.log(filteredList);

    // Update the filtered user list state
    setFilteredUserList(filteredList);
  } catch (error) {
    const errorResponse = (({ response }) => ({ response }))(error);
    // Handle errors
    console.error('Error fetching filtered user list:', errorResponse);
  }
};

// create & register

export const registerUser = async (userData) => {
  try {
    console.log('this is user data:', userData);
    const language = localStorage.getItem('language').replaceAll('"', '');
    const defaultConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        lang: language,
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/user/register`,
      userData,
      defaultConfig
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log('Server Error Message:', error?.response?.data?.error);

    throw error;
  }
};

export const checkEmailAvailability = async (email) => {
  try {
    const language = localStorage.getItem('language').replaceAll('"', '');
    const defaultConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        lang: language,
      },
    };

    const params = new URLSearchParams();
    params.append('email', email);

    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/user/check/email`,
      params,
      defaultConfig
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

/// ===========================================  QUIZ API CALLS  ================================================================ ///
export const startNewQuizGame = async (
  numberOfQuestions,
  selectedCategories
) => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');
    const defaultConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/games/newQuickGame`,
      {
        numOfQuestions: numberOfQuestions,
        categoriesEnabled: selectedCategories,
      },
      defaultConfig
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const startNewQuizGameForGuest = async (
  numberOfQuestions,
  selectedCategories
) => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');
    const defaultConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/games/newQuickGame/guest`,
      {
        numOfQuestions: numberOfQuestions,
        categoriesEnabled: selectedCategories,
      },
      defaultConfig
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchQuestions = async (gameId) => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');
    const defaultConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/games/quickGame/${gameId}`,
      defaultConfig // Corrected to use template literal
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchQuestionsForGuest = async (gameId) => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');
    const defaultConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/games/quickGame/${gameId}/guest`,
      defaultConfig // Corrected to use template literal
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchSingleQuestionData = async (gameId, questionNumber) => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');
    const defaultConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/games/quickGame/${gameId}/question/${questionNumber}`,
      defaultConfig // Corrected to use template literal
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const submitAnswerForSpecificQuestion = async (
  gameId,
  questionIndex,
  answerIndex
) => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');
    const defaultConfig = {
      headers: {
        // "Content-Type": "application/x-www-form-urlencoded",
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/games/quickGame/${gameId}/question/${questionIndex}/answer`,
      {
        answer: answerIndex,
      },
      defaultConfig // Corrected to use template literal
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const submitAnswerForSpecificQuestionForGuest = async (
  gameId,
  questionIndex,
  answerIndex
) => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');
    const defaultConfig = {
      headers: {
        // "Content-Type": "application/x-www-form-urlencoded",
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/games/quickGame/${gameId}/question/${questionIndex}/answer/guest`,
      {
        answer: answerIndex,
      },
      defaultConfig // Corrected to use template literal
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getResultsQuiz = async (gameId) => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');
    const defaultConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/games/quickGame/${gameId}/results`,
      defaultConfig // Corrected to use template literal
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getUnloggedResultsQuiz = async (gameId) => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');
    const defaultConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/games/quickGame/${gameId}/results/guest`,
      defaultConfig // Corrected to use template literal
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const initializePayment = async (productId) => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');

    // Create a URLSearchParams object
    const params = new URLSearchParams();
    params.append('productId', productId);

    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/store/create-payment-intent`,
      params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          lang: language,
          Authorization: token ? `Bearer ${token}` : '',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error in payment process:', error);
    throw error;
  }
};

export const addPayment = async (productId, secretKey) => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');

    // Using URLSearchParams to construct the body of the request
    const params = new URLSearchParams();
    params.append('productId', productId);
    params.append('secretKey', secretKey);

    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/store/addWebPurchase`,
      params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          lang: language,
          Authorization: token ? `Bearer ${token}` : '',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error in payment process:', error);
    throw error;
  }
};

export const completePayment = async (
  productId,
  secretKey,
  status,
  transactionId
) => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');

    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/store/completeWebPurchase`,
      {
        productId,
        secretKey,
        status,
        transactionId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          lang: language,
          Authorization: token ? `Bearer ${token}` : '',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error in payment process:', error);
    throw error;
  }
};

// Get Cash Tourmament IDs API call

export const getTournamentIds = async () => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');

    const defaultConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      }
    };
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/tournaments/getAvailableTournaments`,
      defaultConfig
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching tournament info:', error);
    throw error;
  }
};

// Get Tourmament Info API call

export const getTournamentInfo = async (tournamentId) => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');

    const defaultConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
      params: {
        tournamentId,
      },
    };
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/tournaments/info`,
      defaultConfig
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching tournament info:', error);
    throw error;
  }
};

// Pending testing
export const subscribeTournament = async (tournamentId) => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');

    const defaultConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };

    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/tournaments/subscribe`,
      { tournamentId },
      defaultConfig
    );

    return response.data;
  } catch (error) {
    console.error('Error subscribing to tournament:', error);
    throw error;
  }
};

// Seats Available API call
export const getRemainingSeats = async (tournamentId) => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');

    const defaultConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };

    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/tournaments/getRemainingSeats/${tournamentId}`,
      defaultConfig
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching remaining seats:', error);
    throw error;
  }
};

// API Call to check if already registered

export const checkIfUserSubscribed = async (tournamentId) => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');

    const defaultConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };

    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/tournaments/isUserSubscribed/${tournamentId}`,
      defaultConfig
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching subscription status:', error);
    throw error;
  }
};

// APIs for tournament Testing

export const setStartingTime = async (tournamentId, startingAt, key) => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');

    const defaultConfig = {
      headers: {
        'Content-Type': 'application/json',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };

    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/tournaments/setStartingAt`,
      { tournamentId, startingAt, key },
      defaultConfig
    );

    return response.data;
  } catch (error) {
    console.error('Error setting starting time:', error);
    throw error;
  }
};

// Delete tournament Winners API call

export const deleteTournamentWinners = async (tournamentId, key) => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');

    const defaultConfig = {
      headers: {
        'Content-Type': 'application/json',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };

    const response = await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/tournaments/deleteTournamentWinners/${tournamentId}/${key}`,
      defaultConfig
    );

    return response.data;
  } catch (error) {
    console.error('Error deleting tournament winners:', error);
    throw error;
  }
};

// Delete Tournament Answers API call

export const deleteTournamentAnswers = async (tournamentId, key) => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');

    const defaultConfig = {
      headers: {
        'Content-Type': 'application/json',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };

    const response = await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/tournaments/deleteTournamentAnswers/${tournamentId}/${key}`,
      defaultConfig
    );

    return response.data;
  } catch (error) {
    console.error('Error deleting tournament answers:', error);
    throw error;
  }
};

// Delete tournament subscribers API call

export const deleteTournamentSubscribers = async (tournamentId, key) => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');

    const defaultConfig = {
      headers: {
        'Content-Type': 'application/json',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };

    const response = await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/tournaments/deleteTournamentSubscribers/${tournamentId}/${key}`,
      defaultConfig
    );

    return response.data;
  } catch (error) {
    console.error('Error deleting tournament subscribers:', error);
    throw error;
  }
};

// Refill tournament subscribers API call
// route /tournaments/fillTournamentWithUsers/:tournamentId/:key
// Method POST

export const refillTournamentSubscribers = async (tournamentId, key) => {
  try {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language').replaceAll('"', '');

    const defaultConfig = {
      headers: {
        'Content-Type': 'application/json',
        lang: language,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };

    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/tournaments/fillTournamentWithUsers/${tournamentId}/${key}`,
      {},
      defaultConfig
    );

    return response.data;
  } catch (error) {
    console.error('Error refilling tournament subscribers:', error);
    throw error;
  }
};
