import React, { useState, useEffect } from "react";
import styles from "./FriendsTemplate.module.css";
import Typography from "../../atoms/Typography/Typography";
import GameModeSlider from "../../molecules/GameModeSlider/GameModeSlider";
import SearchBar from "../../molecules/SearchBar/SearchBar";
import OtherUsersProfileTemplate from "../OtherUserProfileTemplate/OtherUserProfileTemplate";
import ChatOrganism from "../../organisms/ChatOrganism/ChatOrganism";
import { useAuth } from "../../../AthContext";
import FriendsLayoutV2 from "../../organisms/FriendsLayoutV2/FriendsLayoutV2";
import {
  getFriendRequest,
  getFriends,
  getBlockedUsers,
} from "../../../apiHelpers";
import { useRefreshSocial, useLoginExpired } from "../../../customHooks";
import { useTranslation } from "react-i18next";
import ModalNotification from '../../molecules/ModalNotification/ModalNotification';

const FriendsTemplate = () => {
  const { t } = useTranslation();
  const [selectedGameMode, setSelectedGameMode] = useState("Friends");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredFriendsList, setFilteredFriendsList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null); // State to store the selected user
  const { isMobile } = useAuth();
  const [friendRequestsList, setFriendRequestsList] = useState([]);
  const [friendsList, setFriendsList] = useState([]); // State to store the list of friends
  const [outgoingFriendRequestsList, setOutgoingFriendRequestsList] = useState([]);
  const [refreshSocialTriggered, setRefreshSocialTriggered] = useState(false);
  const [activeDiscussionId, setActiveDiscussionId] = useState(0);
  const [discussions, setDiscussions] = useState([]);
  const [filteredUserList, setFilteredUserList] = useState([]);
  const [aboutToStartChatsList, setAboutToStartChatsList] = useState([]);
  const [friendRequestSent, setFriendRequestSent] = useState(false);
  const loginExpired = useLoginExpired();
  const socialActivity = useRefreshSocial();

  const handleGameModeChange = (index) => {
    const modes = ["Friends", "Chat", "Search"];
    setSelectedGameMode(modes[index]);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const friendRequestsResponse = await getFriendRequest();
        setFriendRequestsList(friendRequestsResponse.pending);
        setOutgoingFriendRequestsList(friendRequestsResponse.sentPending);
        const friendsResponse = await getFriends();
        setFriendsList(friendsResponse.friends); // Update this line based on the actual structure of your response
        getBlockedUsers();
      } catch (error) {
        console.error("Error fetching friends:", error);
        if (error.response?.status === 401) {
          loginExpired()
        }
      }
    };
    fetchData();
  }, [socialActivity, refreshSocialTriggered]);

  const handleSearch = (searchQuery) => {
    setSearchQuery(searchQuery);
    // Filter the friendsList based on the searchQuery
    const filteredUsers = friendsList.filter((user) =>
      user.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredFriendsList(filteredUsers);
  };

  const handleUserClick = (user) => {
    // Handle the click event when a user is clicked in the SearchBar
    setSelectedUser(user);
  };

  useEffect(() => {
    // If not in search mode, reset the filtered list
    if (selectedGameMode !== "search") {
      setFilteredFriendsList([]);
    }
  }, [selectedGameMode]);

  const handleFriendRequestModal = () => {
    setFriendRequestSent(false);
  };

  return (
    friendRequestSent ? (
      <ModalNotification
        imageName='add_friend'
        text={t("generic.friendRequestSent")}
        onClose={handleFriendRequestModal}
      />
    ) : (
    <div className={styles.friendsTemplate}>
        <Typography
        variant={isMobile ? "heading2" : "headingL"}
        fontWeight="font700"
        color="colorBlack"
      >
        {t("mainNav.friendsTab.friendsScreen.title")}
      </Typography>

      <GameModeSlider
        selectedGameMode={selectedGameMode}
        friend
        className={styles.slider}
        onChange={handleGameModeChange}
      />

      {selectedGameMode === "Search" && (
        <>
          <SearchBar
            onSearch={handleSearch}
            onUserClick={handleUserClick}
            filteredUserList={filteredUserList}
            setFilteredUserList={setFilteredUserList}
          />
        </>
      )}
      {selectedUser && selectedGameMode === "Search" && (
        <OtherUsersProfileTemplate
          playerData={selectedUser}
          friendRequestsList={friendRequestsList}
          setFriendRequestsList={setFriendRequestsList}
          outgoingFriendRequestsList={outgoingFriendRequestsList}
          setRefreshSocialTriggered={setRefreshSocialTriggered}
          friendsList={friendsList}
          setSelectedUser={setSelectedUser}
          setFilteredUserList={setFilteredUserList}
          setFriendRequestSent={setFriendRequestSent}
        />
      )}
      {selectedGameMode === "Friends" && (
        <FriendsLayoutV2
          friendsList={friendsList}
          friendRequestsList={friendRequestsList}
          setFriendRequestsList={setFriendRequestsList}
          setRefreshSocialTriggered={setRefreshSocialTriggered}
          setSelectedGameMode={setSelectedGameMode}
          setActiveDiscussionId={setActiveDiscussionId}
          setAboutToStartChatsList={setAboutToStartChatsList}
        />
      )}
      {selectedGameMode === "Chat" && (
        <ChatOrganism
          activeDiscussionId={activeDiscussionId}
          setActiveDiscussionId={setActiveDiscussionId}
          discussions={discussions}
          setDiscussions={setDiscussions}
          aboutToStartChatsList={aboutToStartChatsList}
        />
      )}
    </div>
  )
  )
}

export default FriendsTemplate;
