// Seperator.js
import React from 'react';
import styles from './LineShape.module.css';

const LineShape = ({ width = 175, height = 1, color = 'black', additionalClass }) => {
  return (
    <div
      style={{
        width: width,
        height: height,
        backgroundColor: color,
        additionalClass: additionalClass,
      }}
    ></div>
  );
};

export default LineShape;
