import React from "react";
import styles from './BlockedPersonsList.module.css'
import BlockedPerson from "../BlockedPerson/BlockedPerson";

const BlockedPersonsList = ({ blockedUsers, onUnblockUser }) => {
    return (
      <div className={styles.blockedPersonsList}>
        {blockedUsers.map((blockedUser) => (
          <BlockedPerson
            key={blockedUser.internalId}
            avatarImageId={blockedUser.avatarId}
            avatarName={blockedUser.username} // Assuming user has a name field
            avatarLevel={blockedUser.level} // Assuming user has a level field
            onUnblock={() => onUnblockUser(blockedUser.internalId)}
          />
        ))}
      </div>
    );
  };
  
  export default BlockedPersonsList;
