import React, { useState } from "react";
import styles from "./ChatTypingSection.module.css";
import Input from "../../atoms/Input/Input";
import Button from "../../atoms/Button/Button";
import Typography from "../../atoms/Typography/Typography";
import { useAuth } from "../../../AthContext";
import { sendMessage } from "../../../apiHelpers";
import { useLoginExpired } from "../../../customHooks";

const ChatTypingSection = ({
  opponentInternalId,
  setChatActivityTriggered,
}) => {
  const { isMobile, translateText } = useAuth();
  const [messageToSend, setMessageToSend] = useState("");
  const loginExpired = useLoginExpired();

  const handleInputChange = (event) => {
    setMessageToSend(event.target.value);
  };

  const handleSendClick = async () => {
    if (messageToSend.trim() !== "") {
      try {
        await sendMessage(opponentInternalId, messageToSend);
        setMessageToSend("");
        setChatActivityTriggered((prev) => !prev);
      } catch (error) {
        console.error("Error sending message:", error);
        if (error.response?.status === 401) {
          loginExpired()
        }
      }
    }
  };

  const handleEnterPress = (event) => {
    // Check if Enter key is pressed without Shift key
    if (event.key === "Enter") {
      handleSendClick();
    }
  };

  return (
    <div
      className={styles.typingSectionContainer}
      style={{
        width: isMobile ? "100%" : "clamp(350px, calc(100vw - 610px), 774px)",
      }}
    >
      <Input
        additionalClass={styles.inputArea}
        placeholder={translateText(
          "messagingScreen.giftedChatComponents.renderComposerPlaceholder"
        )}
        value={messageToSend}
        onChange={handleInputChange}
        onKeyDown={handleEnterPress}
      />
      <Button onClick={handleSendClick}>
        <Typography variant="heading3" fontWeight="font500">
          {translateText("messagingScreen.giftedChatComponents.send")}
        </Typography>
      </Button>
    </div>
  );
};

export default ChatTypingSection;
