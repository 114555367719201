import React, { useState, useEffect } from "react";
import styles from "./ChatOrganism.module.css";
import ChatPersonsList from "../../molecules/ChatPersonsList/ChatPersonsList";
import ChatWindowAndTypingSection from "../../molecules/ChatWindowAndTypingSection/ChatWindowAndTypingSection";
import ChatWindowTopSection from "../../molecules/ChatWindowTopSection/ChatWindowTopSection";
import BackgroundGeneric from "../../molecules/BackgroundGeneric/BackgroundGeneric";
import { useAuth } from "../../../AthContext";
import { getChatList, getNewestMessages } from "../../../apiHelpers";
import { useRefreshChat, useLoginExpired } from "../../../customHooks";

const ChatOrganism = ({
  activeDiscussionId,
  setActiveDiscussionId,
  discussions,
  setDiscussions,
  aboutToStartChatsList,
}) => {  
  const [showChatWindow, setShowChatWindow] = useState(false);
  const [messages, setMessages] = useState([]);
  const [chatActivityTriggered, setChatActivityTriggered] = useState(false);
  const { isMobile, playerInfo, setNewChatMessage, newMessageNotification, setNewMessageNotification} = useAuth();
  const chatActivity = useRefreshChat();
  const loginExpired = useLoginExpired();

  const toggleChatWindowVisibility = () => {
    setShowChatWindow(!showChatWindow);
    setMessages([]);
  };

  const activeDiscussion = discussions.find(discussion => discussion.chatId === activeDiscussionId) || discussions[0];

  // this is repeated in ChatPersonsList
  const giveToUsersRolesToChat = (discussion) => {
    if (!discussion || !playerInfo) return { currentUserRole: '', opponentUserRole: '' };
    const opponentUserRole = playerInfo.internalId === discussion.user1.internalId ? 'user2' : 'user1';
    return { opponentUserRole };
  };

  const { opponentUserRole } = giveToUsersRolesToChat(activeDiscussion);

  useEffect(() => {
    localStorage.setItem("newChatMessageFlag", "false");
    let temporaryChatList
    setNewMessageNotification(false);
    const fetchChatList = async () => {
      try {
        const responseChatlist = await getChatList();
        temporaryChatList = responseChatlist.chatList

        aboutToStartChatsList?.chatId && // if there is any item in aboutToStartChatsList
        !temporaryChatList.some(chat => chat.chatId === aboutToStartChatsList.chatId) && // and is not contained in chat list
        (temporaryChatList = [aboutToStartChatsList, ...temporaryChatList]); // add it to the top of the list

        // Sort the array by the createdAt date of the lastMessage
        temporaryChatList.sort((a, b) => new Date(b.lastMessage.createdAt) - new Date(a.lastMessage.createdAt));

        setDiscussions(temporaryChatList);
      } catch (error) {
        console.error("Error fetching chat list:", error);
        if (error.response?.status === 401) {
          loginExpired()
        }
      } 
    };

    fetchChatList();
  }, [chatActivity, chatActivityTriggered]);

  const lastMessageId = activeDiscussion?.lastMessage?._id;

  const opponentInternalId = activeDiscussion?.[opponentUserRole]?.internalId;

  useEffect(() => {
    // Ensure there is an opponentInternalId before attempting to fetch older messages
    if (opponentInternalId) {
      fetchNewestMessages(opponentInternalId);
    } else {
      console.log(
        "No opponentInternalId available, cannot fetch older messages."
      );
    }
  }, [opponentInternalId, chatActivity, chatActivityTriggered]);

  const fetchNewestMessages = async (opponentInternalId) => {
    try {
      const newestMessagesResponse = await getNewestMessages(lastMessageId, opponentInternalId);
      setMessages(newestMessagesResponse.chatMessages);
    } catch (error) {
      console.error('Failed to fetch older messages', error);
      if (error.response?.status === 401) {
        loginExpired()
      }
    }
  };

  return (
    <div className={styles.chatTemplateWrapper}>
      {isMobile ? (
        showChatWindow ? (
          // if you are in mobile and showChatWindow is true
          <div className={styles.chatWindowMobile}>
            <BackgroundGeneric />
            <ChatWindowTopSection
              discusserAvatarId={activeDiscussion[opponentUserRole].avatarId}
              discusserName={activeDiscussion[opponentUserRole].username}
              closeChatWindow={toggleChatWindowVisibility}
            />
            <ChatWindowAndTypingSection
              messages={messages}
              setMessages={setMessages}
              opponentInternalId={opponentInternalId}
              setChatActivityTriggered={setChatActivityTriggered}
            />
          </div>
        ) : (
          // if you are in mobile and showChatWindow is false
          <ChatPersonsList
            discussions={discussions}
            activeDiscussion={activeDiscussion}
            setActiveDiscussionId={setActiveDiscussionId}
            onPersonClick={toggleChatWindowVisibility}
            setChatActivityTriggered={setChatActivityTriggered}
          />
        )
      ) : (
        // if you are not in mobile show them both next to each other
        <>
          <ChatPersonsList
            discussions={discussions}
            activeDiscussion={activeDiscussion}
            setActiveDiscussionId={setActiveDiscussionId}
            onPersonClick={toggleChatWindowVisibility}
            setChatActivityTriggered={setChatActivityTriggered}
          />
          {discussions && discussions.length > 0 && (
            <ChatWindowAndTypingSection
              messages={messages}
              setMessages={setMessages}
              opponentInternalId={opponentInternalId}
              setChatActivityTriggered={setChatActivityTriggered}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ChatOrganism;
