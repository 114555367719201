import React,{useState} from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../atoms/Button/Button.js";
import Typography from "../../atoms/Typography/Typography.js";
import styles from "./StoreTransactionButton.module.css";
import { initializePayment } from "../../../apiHelpers";
import { useAuth } from "../../../AthContext";
import { useLoginExpired } from "../../../customHooks";

const StoreTransactionButton = ({ additionalClass, text, androidProductId }) => {
  const [priceToPay, setPriceToPay] = useState('')
  const navigate = useNavigate();
  const { isMobile, setUserWantsToBuyItem } = useAuth();
  const loginExpired = useLoginExpired();

  const handleClick = async() => {
    console.log(androidProductId);
    try {
      // Call the payment function to obtain the clientSecret
      const paymentResponse = await initializePayment(androidProductId);
      const { clientSecret, price } = paymentResponse;

      // Log the clientSecret to see the output
      console.log(paymentResponse);
      setPriceToPay(paymentResponse.price)
      setUserWantsToBuyItem(true);
      // If the clientSecret is successfully retrieved, redirect to /card
      navigate('/card', { state: { clientSecret, androidProductId, priceToPay:price } });
    } catch (error) {
      console.error('Error retrieving payment information:', error);
      if (error.response?.status === 401) {
        loginExpired()
      }
    }
  };


  return (
    <Button
      additionalClass={styles.storeTransactionButton + " " + additionalClass}
      onClick={handleClick}
    >
      <Typography
        color="colorWhite"
        fontWeight="font900"
        variant="heading3"
        additionalClass={styles.storeTransactionButtonText}
      >
        {text}
      </Typography>
    </Button>
  );
};

export default StoreTransactionButton;
