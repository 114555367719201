import React from "react";
import styles from "./WalletNotification.module.css";
import Typography from "../../atoms/Typography/Typography";

const WalletNotification = ({ headText, description }) => {
  return (
    <div className={styles.notificationContent}>
      <Typography
        variant={"heading1"}
        fontWeight="font500"
        additionalClass={styles.notificationHeadText}
      >
        {headText}
      </Typography>
      <Typography
        variant={"heading3"}
        fontWeight="font400"
        additionalClass={styles.notificationCaptionText}
      >
        {description}
      </Typography>
    </div>
  );
};

export default WalletNotification;
