import React, { useState, useRef } from "react";
import styles from "./Tooltip.module.css";

const Tooltip = ({ text, children, under }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const timeoutRef = useRef(null);
  const tooltipText =
    text.charAt(0).toUpperCase() + text.slice(1).replace(/_/g, " ");
  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    // Clear any existing timeout
    clearTimeout(timeoutRef.current);

    // Set a timeout to hide the tooltip after a delay
    timeoutRef.current = setTimeout(() => {
      setShowTooltip(false);
    }, 1000); // Adjust the delay (in milliseconds) as needed
  };

  return (
    <div className={styles.tooltipWrapper}>
      <div
        className={styles.tooltipTrigger}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </div>
      {showTooltip && (
        <div className={`${styles.tooltip} ${under && styles.under}`}>
          {tooltipText}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
