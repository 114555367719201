import React from "react";
import styles from "./TournamentIngameInfo.module.css";
import DisqualificationBox from "../../molecules/DisqualificationBox/DisqualificationBox";
import RemainingPlayers from "../../molecules/RemainingPlayers/RemainingPlayers";

const TournamentIngameInfo = ({
  disqualified,
  remainingPlayers,
  totalPlayers,
}) => {
  return (
    <div className={styles.container}>
      <DisqualificationBox disqualified={disqualified} />
      {/* <RemainingPlayers remainingPlayers={100} totalPlayers={100} /> */}
    </div>
  );
};

export default TournamentIngameInfo;
