import React from 'react';
import styles from './BlockedPersons.module.css';
import BlockedPersonsList from '../../molecules/BlockedPersonsList/BlockedPersonsList';
import BackgroundGeneric from '../../molecules/BackgroundGeneric/BackgroundGeneric';
import Typography from '../../atoms/Typography/Typography';
import Icon from '../../atoms/Icon/Icon';
import Button from '../../atoms/Button/Button';
import { useTranslation } from 'react-i18next';

const BlockedPersons = ({ blockedUsers, onUnblockUser, onClose }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.blockedPersonsTemplate}>
      <BackgroundGeneric />
      <div className={styles.titleWithIcon}>
        <Button additionalClass={styles.arrowButton} onClick={onClose}>
          <Icon name="arrowLeft2" folder="arrows" size={32} />
        </Button>
        <div className={styles.title}>
          <Typography fontWeight="font700" color="colorBlack">
          {t('mainNav.profileTab.buttons.blockedUsers.title')}
          </Typography>
        </div>
      </div>
      {blockedUsers.length === 0 && (
        <div className={styles.noBlockedPersonsTextContainer}>
          <Typography variant="heading1" fontWeight="font400" color="colorBlack">
            {t('mainNav.profileTab.buttons.blockedUsers.blockedUsersScreen.emptyListTitle')}
          </Typography>
        </div>
      )}
      <BlockedPersonsList blockedUsers={blockedUsers} onUnblockUser={onUnblockUser} />
    </div>
  );
};

export default BlockedPersons;
