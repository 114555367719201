import React, { useRef, useLayoutEffect, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './EinsteinQuizCard.module.css';
import Typography from '../../atoms/Typography/Typography';
import RemixImages from '../RemixImages/RemixImages';
import Button from '../../atoms/Button/Button';
import { startNewQuizGame, fetchQuestions } from '../../../apiHelpers';
import { useGame } from '../../../GameContext';
import * as ActionTypes from '../../../ActionTypes';
import { useAuth } from '../../../AthContext';
import { useTranslation } from 'react-i18next';
import { useLoginExpired } from '../../../customHooks';
import gsap from 'gsap';

const EinsteinQuizCard = ({
  title,
  body,
  extraInfo,
  dateInfo,
  color,
  buttonText,
  yAxisPositionImage,
  xAxisPositionImage,
  numberOfQuestions,
  selectedCategories,
  id,
}) => {
  const { dispatch } = useGame();
  const navigate = useNavigate();
  const { loggedIn, setQuizId } = useAuth();
  const { t } = useTranslation();
  const loginExpired = useLoginExpired();
  const cardRef = useRef(null); // Reference to the card container
  const [isVisible, setIsVisible] = useState(false); // State to track visibility

  const handleButtonClick = async () => {
    if (!loggedIn) {
      navigate('/forms');
      return; // Prevent further execution
    }
    try {
      const startGameResponse = await startNewQuizGame(
        numberOfQuestions,
        selectedCategories
      );
      console.log(startGameResponse.gameId);

      if (startGameResponse.gameId) {
        const questionsResponse = await fetchQuestions(
          startGameResponse.gameId
        );
        console.log(questionsResponse);
        setQuizId(startGameResponse.gameId);

        // Dispatch an action to update the global state with the fetched questions
        dispatch({
          type: ActionTypes.UPDATE_QUESTION_DATA,
          payload: {
            questionData: questionsResponse,
          },
        });
        navigate('/in-game');
      }
    } catch (error) {
      console.error('Error in starting new quiz game:', error);
      if (error.response?.status === 401) {
        loginExpired();
      }
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true); // Set to true when the card is in view
          observer.unobserve(entry.target); // Stop observing after it's in view
        }
      });
    });

    if (cardRef.current) {
      observer.observe(cardRef.current); // Start observing the card
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current); // Clean up observer on unmount
      }
    };
  }, []);

  useLayoutEffect(() => {
    if (isVisible) {
      // Generate a random delay between 0 and 0.2 seconds
      const randomDelay = Math.random() * 0.2;

      // Apply bounce animation on card render
      gsap.from(cardRef.current, {
        y: -350, // Higher starting position for a more obvious bounce
        ease: 'bounce.out', // Bounce easing
        duration: 0.8, // Faster duration
        delay: randomDelay, // Use random delay
      });
    }
  }, [isVisible]);

  return (
    <div
      ref={cardRef}
      className={styles.einsteinQuizCardContainer}
      style={{
        backgroundColor: color,
        visibility: isVisible ? 'visible' : 'hidden', // Hide until visible
      }}
    >
      <div className={styles.textAndButtonContent}>
        <div className={styles.textArea}>
          <Typography
            variant="heading2"
            fontWeight="font900"
            additionalClass={styles.titleText}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            fontWeight="font400"
            additionalClass={styles.bodyText}
          >
            {body}
          </Typography>
        </div>
        <Button id={id} additionalClass={styles.playButton} onClick={handleButtonClick}>
          <Typography id={id} variant="body1" fontWeight="font500" color="colorBlack" additionalClass={styles.buttonText}>
            {buttonText}
          </Typography>
        </Button>
      </div>
      {extraInfo && (
        <div className={styles.extraInfoContainer}>
          <Typography
            variant="heading2"
            fontWeight="font900"
            additionalClass={styles.extraInfoText}
          >
            {extraInfo}
          </Typography>
        </div>
      )}
      {/* {dateInfo && (
        <div className={styles.dateInfoContainer}>
          <Typography
            variant="caption"
            fontWeight="font500"
            additionalClass={styles.dateText}
          >
            {dateInfo}
          </Typography>
        </div>
      )} */}
      <div
        className={styles.imageContent}
        style={{
          top: `${yAxisPositionImage}%`,
          right: `${xAxisPositionImage}%`,
        }}
      >
        <RemixImages groupName={title} />
      </div>
    </div>
  );
};

export default EinsteinQuizCard;
