import React, { useState } from "react";
import styles from "./ProfileButtonSection.module.css";
import Typography from "../../atoms/Typography/Typography";
import Icon from "../../atoms/Icon/Icon";
import Button from "../../atoms/Button/Button";
import { getBlockedUsers } from "../../../apiHelpers";
import { useTranslation } from "react-i18next";
import { useLoginExpired } from "../../../customHooks";

const ProfileButtonSection = ({
  userWantsToLogout,
  setBlockedUsers,
  renderBlockedUsers,
  setRenderBlockedUsers,
  openSettings,
}) => {
  const { t } = useTranslation();
  const loginExpired = useLoginExpired();

  const handleBlockedUsersButton = async () => {
    try {
      const response = await getBlockedUsers();
      setBlockedUsers(response.blockedUsers);
      setRenderBlockedUsers(!renderBlockedUsers);
      console.log("Blocked Users:", response.blockedUsers);
    } catch (error) {
      console.error("Error fetching blocked users:", error);
      if (error.response?.status === 401) {
        loginExpired()
      }
    }
  };

  return (
    <div className={styles.profileButtonSection}>
      <Button
        additionalClass={styles.profileButton}
        onClick={handleBlockedUsersButton}
      >
        <Icon folder={"other_icons"} name={"blockUser"} />
        <Typography variant={"body1"}>
          {t("mainNav.profileTab.buttons.blockedUsers.title")}
        </Typography>
      </Button>
      <Button additionalClass={styles.profileButton} onClick={openSettings}>
        <Icon folder={"other_icons"} name={"setting"} />
        <Typography variant={"body1"}>
          {t("mainNav.profileTab.buttons.settings.title")}
        </Typography>
      </Button>
      <Button additionalClass={styles.profileButton}>
        <Icon folder={"other_icons"} name={"support"} />
        <Typography variant={"body1"}>
          {t("mainNav.profileTab.buttons.support.title")}
        </Typography>
      </Button>
      <Button additionalClass={styles.profileButton} onClick={userWantsToLogout}>
        <Icon folder={"other_icons"} name={"logOut"} />
        <Typography variant={"body1"}>
          {t("mainNav.profileTab.buttons.logout.title")}
        </Typography>
      </Button>
    </div>
  );
};

export default ProfileButtonSection;
