import React from "react";
import Typography from "../../atoms/Typography/Typography.js";
import styles from "./StoreValueAddon.module.css";

const StoreValueAddon = ({ background, textColor, text }) => {
  return (
    <div className={styles.circleQuarter} style={{ background: background }}>
      <div className={styles.circleText}>
        <Typography color={textColor} fontWeight="font900" variant="caption">
          {text}
        </Typography>
      </div>
    </div>
  );
};

export default StoreValueAddon;
