import React from 'react';
import styles from './CongratulationsStatistics.module.css';
import Typography from '../../atoms/Typography/Typography';

const CongratulationsStatistics = ({ number, text }) => {
  return (
    <div className={styles.statisticsInfoBox}>
      <Typography variant="heading1" fontWeight="font500" color="colorBlack" additionalClass={styles.numberOfResult}>
        {number}
      </Typography>
      <Typography variant="body2" fontWeight="font500" color="colorBlack" additionalClass={styles.textOfResult}>
        {text}
      </Typography>
    </div>
  );
};

export default CongratulationsStatistics;
