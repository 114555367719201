import React from "react";
import styles from "./WaitingScreenContent.module.css";
import Typography from "../../atoms/Typography/Typography";
import Icon from "../../atoms/Icon/Icon";
import CounterDownNumber from "../../molecules/CounterDownNumber/CounterDownNumber";
import { useAuth } from "../../../AthContext";
import { useTranslation } from "react-i18next";

const WaitingScreenContent = () => {
  const { t } = useTranslation();
  const { playerInfo } = useAuth();
  return (
    <div className={styles.waitingScreenContent}>
      <CounterDownNumber seconds={10} questionIndex={0} />
      <Icon
        name={"avatar-" + playerInfo.avatarId}
        folder="avatars"
        size={204}
      />
      <Typography variant="heading2" fontWeight="font700" color="colorBlack">
        {t("WaitingLobby1v1.lookingForOpponent")}
      </Typography>
    </div>
  );
};

export default WaitingScreenContent;
