import React from "react";
import Icon from "../../atoms/Icon/Icon";
import Typography from "../../atoms/Typography/Typography";
import StoreTransactionButton from "../StoreTransactionButton/StoreTransactionButton";
import StoreValueAddon from "../StoreValueAddon/StoreValueAddon.js";
import StoreBonusAddOn from "../StoreBonusAddOn/StoreBonusAddOn.js";
import styles from "./StoreAvatars.module.css";

const StoreAvatars = ({
  imageName,
  quantity,
  price,
  priceType,
  androidProductId,
  addOn,
  bonusNum,
}) => {
  console.log(androidProductId);
  const cardClasses = [
    styles.cardContainer,
    addOn ? styles.storeItemCardEnableRedBoarder : "", // Add styles only if addOn is true
  ].join(" ");
  return (
    <div className={cardClasses}>
      {addOn && (
        <div className={styles.storeItemCardAddOn}>
          <StoreValueAddon
            background={"linear-gradient(180deg, #FF5C00 0%, #FC2E12 100%)"}
            textColor={"colorWhite"}
            text={addOn.text}
          />
        </div>
      )}

      <div className={styles.backgroundAvatars}>
        <Icon
          name={imageName}
          folder="store"
          additionalClass={styles.avatarsIcon}
        />
      </div>
      <Typography
        color="colorBlack"
        variant="heading3"
        additionalClass={styles.avatarsAmount}
      >
        {quantity}
      </Typography>
      {bonusNum && <StoreBonusAddOn imageName={bonusNum.bonusImage} />}

      <StoreTransactionButton
        text={`${price} ${priceType}`}
        additionalClass={styles.avatarBuyButton}
        androidProductId={androidProductId}
      ></StoreTransactionButton>
    </div>
  );
};

export default StoreAvatars;
