import React from "react";
import styles from "./ToggleSwitch.module.css";

const ToggleSwitch = ({ name, action, isChecked }) => {
  return (
    <div className={styles.switch}>
      <input onChange={action} type="checkbox" id={name} checked={isChecked} />
      <label htmlFor={name}>Toggle</label>
    </div>
  );
};

export default ToggleSwitch;
