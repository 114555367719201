import React from "react";
import styles from "./BoostersToPlay.module.css";
import BoosterButton from "../../molecules/BoosterButton/BoosterButton";

const BoostersToPlay = ({ boosterData, onBoosterSelect }) => {
  return (
    <div className={styles.boostersContainer}>
      {boosterData.map((booster, index) => (
        <BoosterButton
          key={index}
          {...booster}
          onClick={() => onBoosterSelect(index)}
          available={booster.available} // Pass available prop
        />
      ))}
    </div>
  );
};

export default BoostersToPlay;
