import React from 'react';
import PropTypes from 'prop-types';

const ProgressBar = ({ percentage }) => {
  const progressWidth = (percentage / 100) * 115; // Adjust the width based on the percentage

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="123" height="16" viewBox="0 0 123 16" fill="none">
      <rect opacity="0.1" x="0.5" y="0.500183" width="122" height="15" rx="7.5" stroke="black" />
      <rect opacity="0.2" x="4" y="4.00018" width="115" height="8" rx="4" fill="#FFCD0D" />
      <rect x="4" y="4.00018" width={progressWidth} height="8" rx="4" fill="#FFCD0D" />
    </svg>
  );
};

ProgressBar.propTypes = {
  percentage: PropTypes.number.isRequired,
};

export default ProgressBar;
