import React from "react";
import styles from "./BackgroundAvatars.module.css";
import Icon from "../../atoms/Icon/Icon";

const BackgroundAvatars = () => {
  // Array of icon details
  const iconDetails = [
    {
      name: "avatar-1",
      size: "3%",
      position: { left: "12.5vw", top: "7.2vh" },
      rotation: "rotate(15.98deg)",
    },
    {
      name: "avatar-2",
      size: "11.8%",
      position: { left: "3.55vw", top: "44vh" },
      rotation: "rotate(-8.093deg)",
    },
    {
      name: "avatar-4",
      size: "4.63%",
      position: { left: "9.3vw", top: "91vh" },
      rotation: "rotate(-12.57deg)",
    },
    {
      name: "avatar-23",
      size: "6.54%",
      position: { left: "23.9vw", top: "17.08vh" },
      rotation: "rotate(-5.62deg)",
    },
    {
      name: "avatar-5",
      size: "5.65%",
      position: { left: "28vw", top: "47.43vh" },
      rotation: "rotate(9.82deg)",
    },
    {
      name: "avatar-9",
      size: "3.75%",
      position: { left: "25.5vw", top: "77.36vh" },
      rotation: "rotate(33.45deg)",
    },
    {
      name: "avatar-18",
      size: "6.47%",
      position: { left: "38.8vw", top: "83.57vh" },
      rotation: "rotate(24.73deg)",
    },
    {
      name: "avatar-8",
      size: "8.79%",
      position: { left: "62.8vw", top: "9.88vh" },
      rotation: "rotate(13.58deg)",
    },
    {
      name: "avatar-6",
      size: "3.75%",
      position: { left: "61.6vw", top: "86.68vh" },
      rotation: "rotate(-38.66deg)",
    },
    {
      name: "avatar-12",
      size: "3.2%",
      position: { left: "73.6vw", top: "66.21vh" },
      rotation: "rotate(14.47deg)",
    },
    {
      name: "avatar-14",
      size: "6.2%",
      position: { left: "84vw", top: "33.17vh" },
      rotation: "rotate(29.82deg)",
    },
    {
      name: "avatar-25",
      size: "7.56%",
      position: { left: "88.7vw", top: "0vh" },
      rotation: "rotate(-19.72deg)",
    },
    {
      name: "avatar-16",
      size: "5.11%",
      position: { left: "91.6vw", top: "78.91vh" },
      rotation: "rotate(-19.44deg)",
    },
  ];

  return (
    <div className={styles.avatarContainer}>
      {iconDetails.map((icon, index) => (
        <Icon
          key={index}
          name={icon.name}
          folder="avatars"
          size={icon.size}
          additionalClass={styles.avatar}
          style={{
            left: icon.position.left,
            top: icon.position.top,
            transform: icon.rotation,
            animationDelay: `${0.5 + Math.random() * 5.5}s`, // Random delay between 0.5 and 6 seconds
          }}
        />
      ))}
    </div>
  );
};

export default BackgroundAvatars;
