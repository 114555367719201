import React from "react";
import Typography from "../../atoms/Typography/Typography";
import StoreItemCard from "../../molecules/StoreItemCard/StoreItemCard";
import styles from "./StoreTicketSection.module.css";

const StoreTicketSection = ({ storeDataToRender }) => {
  const ticketsInfo = storeDataToRender[1]?.data;
  const ticketsTitle = storeDataToRender[1]?.title;

  return (
    <div className={styles.storeTicketSectionEntire}>
      <div className={styles.storeTicketSectionInfoBox}>
        <Typography
          color="colorBlack"
          variant="heading2"
          fontWeight="font900"
          additionalClass={styles.cardsTitles}
        >
          {ticketsTitle}
        </Typography>
      </div>
      <div className={styles.storeTicketSectionContainers}>
        {ticketsInfo?.map(
          ({ id, image, name, price, priceType, offerType, bonus, androidProductId}) => {
            return (
              <div key={id}>
                <StoreItemCard
                  imageName={image}
                  quantity={name}
                  price={price}
                  priceType={priceType}
                  addOn={offerType ? { text: offerType } : null}
                  additionalClass={styles.buttonBlackBackground}
                  cardBackground={styles.yellow}
                  androidProductId={androidProductId}
                  bonusNum={
                    bonus === 2
                      ? { bonusImage: "bonusWhite2" }
                      : bonus === 5
                      ? { bonusImage: "bonusWhite5" }
                      : null
                  }
                />
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};

export default StoreTicketSection;
