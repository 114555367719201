import React from "react";
import styles from "./WinnerEntries.module.css"; // Import your styles
import Icon from "../../atoms/Icon/Icon";
import Typography from "../../atoms/Typography/Typography";
import { useAuth } from "../../../AthContext";

const WinnerEntries = ({ avatarId, username, money }) => {
  const { isMobile } = useAuth();

  return (
    <div className={styles.leaderboardEntry}>
      <span className={styles.skewedBorder}></span>
      <div className={styles.entryContent}>
        {avatarId && (
          <Icon
            folder="avatars"
            name={`avatar-${avatarId}`}
            size={isMobile ? 32 : 48}
          />
        )}
        <div className={styles.userData}>
          <div className={styles.username}>
            <Typography
              variant={isMobile ? "caption" : "body1"}
              fontWeight={"font700"}
              color="colorBlack"
            >
              {username || ""}
            </Typography>
          </div>
        </div>
        <div className={styles.points}>
          <Typography
            variant={isMobile ? "caption" : "body1"}
            fontWeight={"font700"}
            color="colorBlack"
          >
            {money || ""}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default WinnerEntries;
