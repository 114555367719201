import React, { useEffect, useState } from 'react';
import styles from './CounterDownNumber.module.css';
import Typography from '../../atoms/Typography/Typography';

const CounterDownNumber = ({ seconds = 10, questionIndex }) => {
  const [count, setCount] = useState(seconds);

  useEffect(() => {
    setCount(seconds); // Reset countdown when the question index changes
  }, [questionIndex]);

  useEffect(() => {
    if (count <= 0) return;
    const timer = setTimeout(() => {
      setCount(count - 1);
    }, 1000);
    return () => clearTimeout(timer);
  }, [count]);

  return (
    <Typography variant="headingL" fontWeight="font700" color="colorBlack" additionalClass={styles.countdownNumber}>
      {count}
    </Typography>
  );
};

export default CounterDownNumber;
