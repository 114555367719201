import React, { createContext, useContext, useEffect, useReducer } from "react";
import { initialState } from "./initialState";
import * as ActionTypes from "./ActionTypes"; // Import your ActionTypes
import { socket } from "./services/sockets";

const GameContext = createContext();

const gameReducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.START_NEW_GAME_1_VS_1: {
      return {
        ...state,
        gameId: action.payload.gameId,
        players: action.payload.players,
        userScore: 0,
        opponentScore: 0,
      };
    }
    case ActionTypes.UPDATE_QUESTION_DATA: {
      return {
        ...state,
        questionData: action.payload.questionData,
        correctAnswer: null,
        userGivenAnswer: 0,
        opponentHasAnswered: false,
        gameInvitationInfo: {},
      };
    }
    // Handle other action types...
    default:
      return state;
  }
};

export const GameProvider = ({ children }) => {
  const [state, dispatch] = useReducer(gameReducer, initialState);

  useEffect(() => {
    // Example socket event listeners
    socket.on("gameStart", (gameData) => {
      dispatch({ type: ActionTypes.START_NEW_GAME_1_VS_1, payload: gameData });
    });

    socket.on("questionUpdate", (questionData) => {
      dispatch({
        type: ActionTypes.UPDATE_QUESTION_DATA,
        payload: questionData,
      });
    });

    // Clean up socket event listeners
    return () => {
      socket.off("gameStart");
      socket.off("questionUpdate");
    };
  }, []);

  // Actions to be used by components
  const actions = {
    startNewGame: () => {
      // Example of emitting a socket event
      socket.emit("startNewGame");
    },
    // Add more actions as needed
  };
  return (
    <GameContext.Provider value={{ state, dispatch, actions }}>
      {children}
    </GameContext.Provider>
  );
};

export const useGame = () => {
  const context = useContext(GameContext);
  if (!context) {
    throw new Error("useGame must be used within a GameProvider");
  }
  return context;
};
