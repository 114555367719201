import React from "react"
import PropTypes from "prop-types"
import styles from "./Typography.module.css"

const Typography = ({
  id = "",
  variant,
  italian,
  textShadow = false,
  fontWeight,
  color = "colorWhite",
  children,
  additionalClass = "",
}) => {
  const typographyClasses = `${styles.typography} ${styles[variant]} ${italian ? styles.italian : ""} ${
    textShadow ? styles.textShadow : ""
  } ${styles[fontWeight]} ${styles[color]} ${additionalClass}`

  return (
    <div id={id} className={typographyClasses}>
      {children}
    </div>
  )
}

Typography.propTypes = {
  variant: PropTypes.oneOf([
    "headingXL",
    "headingL",
    "heading1",
    "heading2",
    "heading3",
    "body1",
    "body2",
    "caption",
    "small",
  ]),
  italian: PropTypes.bool,
  fontWeight: PropTypes.oneOf(["font900", "font700", "font500", "font400"]),
  color: PropTypes.oneOf(["colorBlack", "colorWhite", "colorRed"]),
  children: PropTypes.node.isRequired,
  textShadow: PropTypes.bool,
}

export default Typography
