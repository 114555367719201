import React, { useEffect, useRef, useState } from "react";

import styles from "./ProfileSettingsModal.module.css";
import Typography from "../../atoms/Typography/Typography";
import ToggleSwitch from "../../molecules/ToggleSwitch/ToggleSwitch";
import ProfileModalCard from "../../molecules/ProfileModalCard/ProfileModalCard";
import ModalWith2Buttons from "../../molecules/ModalWith2Buttons/ModalWith2Buttons";
//import LanguageModal from "./LanguageModal/LanguageModal";

import { play, toggleBodyScroll } from "../../../services/helpers";
import { useAuth } from "../../../AthContext";
import Icon from "../../atoms/Icon/Icon";
import { useTranslation } from "react-i18next";
import Button from "../../atoms/Button/Button";
import LanguageMenu from "../../molecules/LanguageMenu/LanguageMenu";

const ProfileSettingsModal = ({
  setOpenSettings,
  notifications,
  setNotifications,

  deleteUserAccount,
}) => {
  const { t, i18n } = useTranslation();
  const { language, isMobile, sound, setSoundStatus, setLanguage } = useAuth();
  const [openLanguageModal, setOpenLanguageModal] = useState(false);
  const [openDeleteAccountModal, setOpenDeleteAccountModal] = useState(false);

  const sounds = useRef(true);

  useEffect(() => {
    toggleBodyScroll(true); // Run on mount to disable scrolling
    return () => {
      toggleBodyScroll(false); // Run on unmount to enable scrolling
    };
  }, []); // Empty dependency array ensures it only runs on mount and unmount

  let privacyUrl = "https://api.quizdombattle.com/privacy";
  if (language === "el" || null) {
    privacyUrl = privacyUrl + "Gr";
  } else if (language === "en") {
    privacyUrl = privacyUrl + "En";
  } else if (language === "de") {
    privacyUrl = privacyUrl + "De";
  }

  let termConditionsUrl = "https://api.quizdombattle.com/terms";
  if (language === "el" || null) {
    termConditionsUrl = termConditionsUrl + "Gr";
  } else if (language === "en") {
    termConditionsUrl = termConditionsUrl + "En";
  } else if (language === "de") {
    termConditionsUrl = termConditionsUrl + "De";
  }

  let imprintURL = "https://api.quizdombattle.com/imprint";
  if (language === "en" || language === "el") {
    imprintURL = imprintURL + "En";
  } else if (language === "de") {
    imprintURL = imprintURL + "De";
  }

  let faqURL = "https://api.quizdombattle.com/faq";
  if (language === "en") {
    faqURL = faqURL + "En";
  } else if (language === "de") {
    faqURL = faqURL + "De";
  }

  const allowSoundsSwitch = () => {
    sounds.current ? notAllowSounds() : allowSounds();
  };
  const notAllowSounds = () => {
    localStorage.setItem("sounds", "Not Permitted");
    setSoundStatus(false);
    sounds.current = false;
    console.log("switchedOff");
  };
  const allowSounds = () => {
    localStorage.setItem("sounds", "Allowed");
    play();
    setSoundStatus(true);
    sounds.current = true;
    console.log("switchedOn");
  };

  // FAQ , Imprint NOT on mobile
  const textButtonsMobile = [
    {
      id: 1,
      title: "FAQ",
      action: () => {
        play();
        window.location.href = faqURL;
      },
    },
    {
      id: 2,
      title: t("generic.imprint"),
      action: () => {
        play();
        window.location.href = imprintURL;
      },
    },
  ];

  const textButtons = [
    {
      id: 1,
      title: t("mainNav.profileTab.buttons.settings.settingsModal.terms"),
      action: () => {
        play();
        window.location.href = termConditionsUrl;
      },
    },
    {
      id: 2,
      title: t("mainNav.profileTab.buttons.settings.settingsModal.policy"),
      action: () => {
        play();
        window.location.href = privacyUrl;
      },
    },
  ];

  const onPressRightDeleteModalHandler = () => {
    setOpenSettings(false);
    setOpenDeleteAccountModal(false);
    deleteUserAccount();
  };
  return (
    <ProfileModalCard setOpenModal={setOpenSettings}>
      <div className={styles.settingsContentWrapper}>
        <Icon
          name={"icon1_3x"}
          folder="settings_modal"
          size={isMobile ? 40 : 100}
          additionalClass={styles.settingsicon1}
        />
        <div className={styles.settingstxtwrapper}>
          <Typography
            variant={isMobile ? "caption" : "heading3"}
            fontWeight="font500"
            color="colorBlack"
            additionalClass={styles.settingstitletxt}
          >
            {t("mainNav.profileTab.buttons.settings.settingsModal.sounds")}
          </Typography>
        </div>
        <div className={styles.settingsonoffbtn}>
          <ToggleSwitch
            isChecked={sounds.current}
            action={allowSoundsSwitch}
            name="sound"
          />
        </div>
      </div>
      <div className={styles.settingsContentWrapper}>
        <Icon
          name={"icon2_3x"}
          folder="settings_modal"
          size={isMobile ? 40 : 100}
          additionalClass={styles.settingsicon1}
        />

        <div className={styles.settingstxtwrapper}>
          <Typography
            variant={isMobile ? "caption" : "heading3"}
            fontWeight="font400"
            color="colorBlack"
            additionalClass={styles.settingstitletxt}
          >
            {t(
              "mainNav.profileTab.buttons.settings.settingsModal.notifications"
            )}
          </Typography>
        </div>
        <div className={styles.settingsonoffbtn}>
          <ToggleSwitch
            disabled
            action={() =>
              console.log("notifications need implementation first")
            }
            isChecked={false}
            name="notifications"
          />
        </div>
      </div>

      <div className={styles.settingsContentWrapper}>
        <Icon
          name={"language"}
          folder="settings_modal"
          size={isMobile ? 40 : 100}
          additionalClass={styles.settingsicon1}
        />
        <Button
          additionalClass={styles.emptyButton}
          onClick={() => {
            play();
            setOpenLanguageModal(true);
          }}
        >
          <div className={styles.settingstxtwrapper}>
            <Typography
              variant={isMobile ? "caption" : "heading3"}
              fontWeight="font500"
              color="colorBlack"
              additionalClass={styles.settingstitletxt}
            >
              {t(
                "mainNav.profileTab.buttons.settings.settingsModal.changeLanguage"
              )}
            </Typography>
          </div>
        </Button>
      </div>

      {openLanguageModal && (
        <div className={styles.languageMenu}>
          <LanguageMenu
            toggleLanguageModal={setOpenLanguageModal}
            // setOpenLanguageModal={setOpenLanguageModal}
            // language={language}
          />
        </div>
      )}

      <div className={styles.settingscontainer}>
        <div className={styles.settingstermagreementwrapper}>
          {isMobile && (
            <>
              {textButtonsMobile.map((item) => {
                return (
                  <Button
                    key={item.id}
                    additionalClass={styles.emptyButton}
                    // android_disableSound={true}
                    // style={({ pressed }) => [{ opacity: pressed ? 0.5 : 1.0 }, { marginTop: 10 }]}
                    onClick={item.action}
                  >
                    <Typography
                      additionalClass={styles.settingstermsagreementtxt}
                    >
                      {item.title}
                    </Typography>
                  </Button>
                );
              })}
            </>
          )}
          <>
            {textButtons.map((item) => {
              return (
                <Button
                  key={item.id}
                  additionalClass={styles.emptyButton}
                  // android_disableSound={true}
                  // style={({ pressed }) => [{ opacity: pressed ? 0.5 : 1.0 }, { marginTop: 10 }]}
                  onClick={item.action}
                >
                  <Typography
                    additionalClass={styles.settingstermsagreementtxt}
                  >
                    {item.title}
                  </Typography>
                </Button>
              );
            })}
          </>
        </div>

        <Button
          additionalClass={styles.deleteButton}
          // android_disableSound={true}
          // style={({ pressed }) => [{ opacity: pressed ? 0.5 : 1.0 }, { marginTop: 10 }]}
          onClick={() => {
            play();
            setOpenDeleteAccountModal(true);
          }}
        >
          <Icon name={"trash"} folder="settings_modal" size={24}></Icon>
          <Typography>
            {t(
              "mainNav.profileTab.buttons.settings.settingsModal.deleteAccount.title"
            )}
          </Typography>
        </Button>

        {openDeleteAccountModal && (
          <ModalWith2Buttons
            mainTitle={t(
              "mainNav.profileTab.buttons.settings.settingsModal.deleteAccount.modalContent"
            )}
            closeRightButtonTitle={t("generic.yes").toUpperCase()}
            closeLeftButtonTitle={t("generic.no").toUpperCase()}
            modalVisible={openDeleteAccountModal}
            onPressLeft={() => setOpenDeleteAccountModal(false)}
            onPressRight={onPressRightDeleteModalHandler}
            onModalHide={() => {}}
          />
        )}
      </div>
    </ProfileModalCard>
  );
};

export default ProfileSettingsModal;
