import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './DesktopBanner.module.css';
import DesktopBannerContent from '../../molecules/DesktopBannerContent/DesktopBannerContent';
import { useAuth } from '../../../AthContext';
import {
  startNewQuizGame,
  startNewQuizGameForGuest,
  fetchQuestions,
  fetchQuestionsForGuest,
} from '../../../apiHelpers';
import { useGame } from '../../../GameContext';
import * as ActionTypes from '../../../ActionTypes';
import { useLoginExpired } from '../../../customHooks';
import Icon from '../../atoms/Icon/Icon';

const DesktopBanner = ({ backgroundImage, einsteinImage, title, subTitle }) => {
  const { dispatch } = useGame();
  const { loggedIn, setQuizId } = useAuth();
  const navigate = useNavigate();
  const loginExpired = useLoginExpired();

  const categories = [
    {
      id: 1,
      categoriesId: [2, 6, 5, 15, 7, 8, 1, 9, 12],
      title: 'General Knowledge',
      icon: 'GeneralKnowledge',
      color: 'orange',
    },
    { id: 2, categoriesId: [2, 6], title: 'TV & Music', icon: 'TVMusic', color: 'green' },
    { id: 3, categoriesId: 5, title: 'Science', icon: 'Science', color: 'blue' },
    { id: 4, categoriesId: 15, title: 'Business', icon: 'Business', color: 'darkOrange' },
    { id: 5, categoriesId: 7, title: 'Sport', icon: 'Sport', color: 'purple' },
    { id: 6, categoriesId: 8, title: 'Food', icon: 'Food', color: 'lightOrange' },
    { id: 7, categoriesId: 1, title: 'Celebrities', icon: 'Celebrities', color: 'red' },
    { id: 8, categoriesId: 9, title: 'History', icon: 'History', color: 'lightGreen' },
    { id: 9, categoriesId: 12, title: 'Geography', icon: 'Geography', color: 'magenta' },
  ];

  // this function is repeated in 'EinsteinQuizCard'
  const handleButtonClick = async () => {
    try {
      const shuffledCategories = [...categories].sort(() => Math.random() - 0.5);

      // Generate a random number between 3 and 9 for the number of categories to select
      const numberOfCategoriesToSelect = Math.floor(Math.random() * (9 - 3 + 1)) + 3;

      // Select the first 3 categories from the shuffled array
      const selectedCategories = shuffledCategories.slice(0, numberOfCategoriesToSelect);

      // Extract categoriesId from the selected categories
      const randomizedCategories = selectedCategories.flatMap((cat) => cat.categoriesId);

      console.log(randomizedCategories);

      const startGameResponse = loggedIn
        ? await startNewQuizGame(5, randomizedCategories)
        : await startNewQuizGameForGuest(5, randomizedCategories);
      console.log(startGameResponse.gameId);

      if (startGameResponse.gameId) {
        const questionsResponse = loggedIn
          ? await fetchQuestions(startGameResponse.gameId)
          : await fetchQuestionsForGuest(startGameResponse.gameId);

        console.log(questionsResponse);
        setQuizId(startGameResponse.gameId);

        dispatch({
          type: ActionTypes.UPDATE_QUESTION_DATA,
          payload: {
            questionData: questionsResponse,
          },
        });
        navigate('/in-game');
      }
    } catch (error) {
      console.error('Error in starting new quiz:', error);
      if (error.response?.status === 401) {
        loginExpired();
      }
    }
  };

  return (
    <div className={styles['desktopBanner-container']}>
      <div className={`${styles.textAndButtonsContainer} ${loggedIn ? styles.loggedIn : styles.loggedOut}`}>
        <DesktopBannerContent title={title} subTitle={subTitle} onButtonClick={handleButtonClick} />
      </div>
      <div className={styles.einsteinImageContainer}>
        <Icon name={einsteinImage} folder="characters" size="30%" />
      </div>
      <div className={styles.backgroundImageContainer}>
        <Icon name={backgroundImage} folder="banners" size="100%" />
      </div>
    </div>
  );
};

export default DesktopBanner;
