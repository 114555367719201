import React from "react"
import styles from "./Button.module.css"

const Button = ({ type = "button", onClick, children, additionalClass = "", id = "", disabled = false }) => (
  <button id={id} type={type} className={`${styles.button} ${additionalClass}`} onClick={onClick} disabled={disabled} >
    {children}
  </button>
)

export default Button
