import React, { useEffect, useRef, useState } from 'react';
import styles from './ChatWindow.module.css';
import ChatMessageBubble from '../ChatMessageBubble/ChatMessageBubble';
import { useAuth } from '../../../AthContext';
import Typography from '../../atoms/Typography/Typography';

const ChatWindow = ({ messages }) => {
  const { isMobile, playerInfo } = useAuth();
  const chatWindowRef = useRef(null);
  const [currentDate, setCurrentDate] = useState('');

  // Scroll to bottom when messages change
  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [messages]);

  // Handle scroll event to update current date
  const handleScroll = () => {
    const chatWindow = chatWindowRef.current;
    if (!chatWindow) return;

    const dateElements = chatWindow.getElementsByClassName(styles.dateText);
    const chatWindowRect = chatWindow.getBoundingClientRect();
    let newCurrentDate = '';

    // Iterate through date elements to find the one just above the top of the chat window
    for (let i = 0; i < dateElements.length; i++) {
      const dateElement = dateElements[i];
      const rect = dateElement.getBoundingClientRect();

      if (rect.top >= chatWindowRect.top && rect.top <= chatWindowRect.top - 10) {
        newCurrentDate = dateElement.textContent;
        break;
      } else if (rect.top < chatWindowRect.top - 10) {
        newCurrentDate = dateElement.textContent;
      }
    }

    setCurrentDate(newCurrentDate);
  };

  // Attach scroll event listener
  useEffect(() => {
    const chatWindow = chatWindowRef.current;

    if (chatWindow) {
      chatWindow.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (chatWindow) {
        chatWindow.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  // Reverse and filter messages
  const filteredAndReversedMessages = messages
    .filter((message) => message.message.trim() !== '')
    .reverse();

  // Render messages with their respective dates
  const renderMessagesWithDates = () => {
    const messagesWithDates = [];
    let lastMessageDate = '';

    filteredAndReversedMessages.forEach((message) => {
      const messageDate = new Date(message.createdAt).toLocaleDateString();

      if (messageDate !== lastMessageDate) {
        messagesWithDates.push(
          <Typography
            key={message.createdAt}
            color="colorBlack"
            additionalClass={styles.dateText}
          >
            {messageDate}
          </Typography>
        );
        lastMessageDate = messageDate;
      }

      const senderIsCurrentUser = playerInfo.internalId === message.from;

      messagesWithDates.push(
        <ChatMessageBubble
          key={message._id}
          text={message.message}
          timestamp={message.createdAt}
          senderIsCurrentUser={senderIsCurrentUser}
        />
      );
    });

    return messagesWithDates;
  };

  return (
    <>
      {/* Fixed display for current date */}
      <div className={styles.fixedDateText}>
        <Typography color="colorBlack">{currentDate}</Typography>
      </div>

      {/* Chat window container */}
      <div
        className={styles.chatWindow}
        style={{ width: isMobile ? '98vw' : 'auto' }}
        ref={chatWindowRef}
      >
        {renderMessagesWithDates()}
      </div>
    </>
  );
};

export default ChatWindow;
