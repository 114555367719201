import React, { useState, useEffect } from "react";
import Typography from "../../atoms/Typography/Typography";
import styles from "./PvPmodeScoreSection.module.css";
import Avatar from "../../molecules/Avatar/Avatar";

const PvPmodeScoreSection = ({
  playerInfo = { username: "Player 1", avatarId: 1 },
  opponentInfo = { username: "Player 2", avatarId: 2 },
  scores = { playerScore: 0, opponentScore: 0 },
}) => {
  // State to store the imageSize based on screen size
  const [avatarSize, setAvatarSize] = useState("large");
  // State to store the typography variants based on screen size
  const [textVariant, setTextVariant] = useState({
    playerName: "heading2",
    scoreLabel: "heading3",
    scoreNumber: "heading3",
  });

  // Function to update avatarSize and textVariant based on screen size
  const updateSizes = () => {
    const screenWidth = window.innerWidth;
    let playerNameVariant = "heading2";
    let scoreLabelVariant = "heading3";
    let scoreNumberVariant = "heading3";
    if (screenWidth <= 768) {
      playerNameVariant = "heading3";
      scoreLabelVariant = "body1";
      scoreNumberVariant = "body1";
    }
    setAvatarSize(
      screenWidth <= 480 ? "xsmall" : screenWidth <= 768 ? "small" : "large"
    );
    setTextVariant({
      playerName: playerNameVariant,
      scoreLabel: scoreLabelVariant,
      scoreNumber: scoreNumberVariant,
    });
  };

  // useEffect hook to run the updateSizes function on component mount and window resize
  useEffect(() => {
    updateSizes();
    window.addEventListener("resize", updateSizes);
    return () => {
      window.removeEventListener("resize", updateSizes);
    };
  }, []);

  // Function to truncate player names for smaller screens
  const truncateName = (name) => {
    if (window.innerWidth <= 768 && name.length > 6) {
      return name.substring(0, 6) + "...";
    }
    return name;
  };

  return (
    <div className={styles.entireContainer}>
      <div className={styles.playerBox}>
        <div className={styles.avatarBox}>
          <Avatar
            icon={"avatar-" + playerInfo.avatarId}
            imageSize={avatarSize}
          />
        </div>
        <div className={styles.scoreBox}>
          <Typography color="colorBlack" variant={textVariant.playerName}>
            {truncateName(playerInfo.username)}
          </Typography>
          <div className={styles.scoreAndNumber}>
            <Typography
              variant={textVariant.scoreLabel}
              additionalClass={styles.scoreStyling}
            >
              Score
            </Typography>
            <Typography variant={textVariant.scoreNumber} color="colorBlack">
              {scores.playerScore}
            </Typography>
          </div>
        </div>
      </div>
      <div className={styles.opponentBox}>
        <div className={styles.avatarBox}>
          <Avatar
            icon={"avatar-" + opponentInfo.avatarId}
            imageSize={avatarSize}
          />
        </div>
        <div className={styles.scoreBox}>
          <Typography color="colorBlack" variant={textVariant.playerName}>
            {truncateName(opponentInfo.username)}
          </Typography>
          <div className={styles.scoreAndNumber}>
            <Typography
              variant={textVariant.scoreLabel}
              additionalClass={styles.scoreStyling}
            >
              Score
            </Typography>
            <Typography variant={textVariant.scoreNumber} color="colorBlack">
              {scores.opponentScore}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PvPmodeScoreSection;
