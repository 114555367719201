import React from "react";
import styles from "./CongratulationsImage.module.css";
import Icon from "../../atoms/Icon/Icon";
import { useAuth } from "../../../AthContext";

const CongratulationsImage = ({ quizEvaluationResults }) => {
  const illustrationNames = {
    perfect: "illustration_5",
    great: "illustration_22",
    well: "illustration_23",
    fail: "illustration_24",
    tournament: "illustration_26",
  };

  const { loggedIn } = useAuth();

  const illustrationName = !loggedIn
    ? "illustration_5"
    : illustrationNames[quizEvaluationResults] || "illustration_5"; // Default to "perfect" scenario

  const confettiStyle = {
    visibility:
      quizEvaluationResults === "perfect" ||
      quizEvaluationResults === "tournament" ||
      !loggedIn
        ? "visible"
        : "hidden",
  };

  return (
    //<div className={styles["images-container"]}>
      <Icon
        additionalClass={styles["einstein-image"]}
        name={illustrationName}
        folder="characters"
        size="280"
      />
    //   <Icon
    //     additionalClass={styles["confeti-image"]}
    //     name="confeti"
    //     folder="other_icons"
    //     size="660"
    //     style={confettiStyle}
    //   />
    // </div>
  );
};

export default CongratulationsImage;
