import React from "react";
import GameSelectionPanel from "../GameSelectionPanel/GameSelectionPanel";
import styles from "./GamePanels.module.css";
import EinsteinQuizCard from "../EinsteinQuizCard/EinsteinQuizCard";
import { useAuth } from "../../../AthContext";
import { useTranslation } from "react-i18next";

const GamePanels = ({ group }) => {
  const { t } = useTranslation();

  const quizCardContents = {
    popular: [
      {
        title: t("mainNav.homeTab.popularQuizGames.card1.title"),
        body: (
          <>
            {t("mainNav.homeTab.popularQuizGames.card1.description1")}
            <br />
            {t("mainNav.homeTab.popularQuizGames.card1.description2")}
          </>
        ),
        extraInfo: `5 ${t("mainNav.homeTab.popularQuizGames.card1.info")}`,
        color: "#8943B3",
        yAxisPositionImage: "-10",
        xAxisPositionImage: "-5",
        numberOfQuestions: 5,
        selectedCategories: [2, 6, 5, 15, 7, 8, 1, 9, 12],
        id: "play_now_5",
      },
      {
        title: t("mainNav.homeTab.popularQuizGames.card2.title"),
        body: (
          <>
            {t("mainNav.homeTab.popularQuizGames.card2.description1")}
            <br />
            {t("mainNav.homeTab.popularQuizGames.card2.description2")}
          </>
        ),
        extraInfo: `10 ${t("mainNav.homeTab.popularQuizGames.card2.info")}`,
        color: "#76C257",
        yAxisPositionImage: "-13",
        xAxisPositionImage: "-5",
        xAxisPositionImageMobile: "-12",
        numberOfQuestions: 10,
        selectedCategories: [2, 6, 5, 15, 7, 8, 1, 9, 12],
        id: "play_now_10",
      },
      {
        title: t("mainNav.homeTab.popularQuizGames.card3.title"),
        body: (
          <>
            {t("mainNav.homeTab.popularQuizGames.card3.description1")}
            <br />
            {t("mainNav.homeTab.popularQuizGames.card3.description2")}
          </>
        ),
        extraInfo: `15 ${t("mainNav.homeTab.popularQuizGames.card3.info")}`,
        color: "#4A95AC",
        yAxisPositionImage: "-10",
        xAxisPositionImage: "-8",
        numberOfQuestions: 15,
        selectedCategories: [2, 6, 5, 15, 7, 8, 1, 9, 12],
        id: "play_now_15",
      },
    ],
    special: [
      {
        title: t("mainNav.homeTab.specialQuizzes.card3.title"),
        body: (
          <>
            {t("mainNav.homeTab.specialQuizzes.card3.description1")}
            <br />
            {t("mainNav.homeTab.specialQuizzes.card3.description2")}
          </>
        ),
        dateInfo: "May 24 - 26, 2024",
        color: "#944C95",
        buttonText: t("mainNav.homeTab.specialQuizzes.card3.buttonText"),
        yAxisPositionImage: "-17",
        xAxisPositionImage: "-7",
        yAxisPositionImageMobile: "-5",
        xAxisPositionImageMobile: "-14",
      },
      {
        title: t("mainNav.homeTab.specialQuizzes.card1.title"),
        body: t("mainNav.homeTab.specialQuizzes.card1.description1"),
        dateInfo: "May 28, 2024",
        color: "#2299FF",
        buttonText: t("mainNav.homeTab.specialQuizzes.card1.buttonText"),
        yAxisPositionImage: "-5",
        xAxisPositionImage: "-10",
        xAxisPositionImageMobile: "-10",
      },
      {
        title: t("mainNav.homeTab.specialQuizzes.card2.title"),
        body: (
          <>
            {t("mainNav.homeTab.specialQuizzes.card2.description1")}
            <br />
            {t("mainNav.homeTab.specialQuizzes.card2.description2")}
          </>
        ),
        dateInfo: "June 05, 2024",
        color: "#9DC183",
        buttonText: t("mainNav.homeTab.specialQuizzes.card2.buttonText"),
        yAxisPositionImage: "-12",
        xAxisPositionImage: "-6",
        yAxisPositionImageMobile: "-10",
      },
    ],
  };

  const { isMobile, loggedIn } = useAuth();
  const selectedGroup = quizCardContents[group];

  return (
    <div
      className={
        loggedIn
          ? styles.quizCardsWrapperLoggedIn
          : styles.quizCardsWrapperLoggedOut
      }
    >
      {selectedGroup.map((content, index) => (
        <EinsteinQuizCard
          id={content.id}
          key={index}
          title={content.title}
          body={content.body}
          extraInfo={content.extraInfo}
          dateInfo={content.dateInfo}
          color={content.color}
          buttonText={
            content.buttonText
              ? content.buttonText
              : loggedIn
              ? t("generic.Play Now")
              : t("generic.Register Now")
          }
          yAxisPositionImage={
            isMobile && content.yAxisPositionImageMobile
              ? content.yAxisPositionImageMobile
              : content.yAxisPositionImage
          }
          xAxisPositionImage={
            isMobile && content.xAxisPositionImageMobile
              ? content.xAxisPositionImageMobile
              : content.xAxisPositionImage
          }
          numberOfQuestions={content.numberOfQuestions}
          selectedCategories={content.selectedCategories}
        />
      ))}
    </div>
  );
};

export default GamePanels;
