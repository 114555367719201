import React, { useEffect, useState } from "react";
import styles from "./LeaderboardsTemplate.module.css";
import Typography from "../../atoms/Typography/Typography";
import GameModeSlider from "../../molecules/GameModeSlider/GameModeSlider";
import LeaderboardContainer from "../../organisms/LeaderboardContainer/LeaderboardContainer";
import { useAuth } from "../../../AthContext";
import { getAllTimeData, getFriendsLeaderboardData } from "../../../apiHelpers";
import LoadingSpinner from "../../molecules/LoadingSpinner/LoadingSpinner";
import { useLoginExpired } from "../../../customHooks";

const LeaderboardsTemplate = () => {
  const [selectedGameMode, setSelectedGameMode] = useState("friends");
  const [loading, setLoading] = useState(false);
  const loginExpired = useLoginExpired();
  const { isMobile, translateText, updateLeaderboardData } = useAuth();
  const [filteredFriendsData, setFilteredFriendsData] = useState([]);


  useEffect(() => {
    const fetchLeaderboardData = async () => {
      try {
        const leads = await getAllTimeData(0);
        updateLeaderboardData(leads);
      } catch (error) {
        console.error("Error fetching leaderboard data:", error);
        if (error.response?.status === 401) {
          loginExpired()
        }
      } finally {
        setLoading(false);
      }
    };

    fetchLeaderboardData();
  }, []);

  const getFriendsLeaderboard = async (index) => {
    const data = await getFriendsLeaderboardData(index);
    setFilteredFriendsData(data);
  };

  useEffect(() => {
    getFriendsLeaderboard(0);
  }, []);

  const handleGameModeChange = (index) => {
    // Map the selected index to the corresponding game mode
    const modes = ["friends", "global", "cash Tournaments"]; // Assuming "friends" is the second mode
    setSelectedGameMode(modes[index]);
  };

  return (
    <div className={styles.leaderboardsTemplate}>
      <Typography
        variant={isMobile ? "heading2" : "headingL"}
        fontWeight="font700"
        color="colorBlack"
        additionalClass={styles.leaderboardTitle}
      >
        {translateText("mainNav.leaderboardTab.title")}
      </Typography>
    <div className={styles.slideContainer}>
      <GameModeSlider
        mode
        className={styles.slider}
        onChange={handleGameModeChange}
      />
    </div>

      {loading ? (
        <LoadingSpinner />
      ) : (
        <LeaderboardContainer gameMode={selectedGameMode} filteredFriendsData={filteredFriendsData} setFilteredFriendsData={setFilteredFriendsData}/>
      )}
    </div>
  );
};

export default LeaderboardsTemplate;
