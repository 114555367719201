import React from "react";
import styles from "./ProfileModalCard.module.css";
import Icon from "../../atoms/Icon/Icon";
import Button from "../../atoms/Button/Button";
import Typography from "../../atoms/Typography/Typography";
import { useTranslation } from "react-i18next";

const ProfileModalCard = ({ openModal, setOpenModal, children }) => {
  const closeModal = () => {
    // play('button click');
    setOpenModal(false);
  };
  const { t } = useTranslation();
  return (
    <>
      <div
        className={styles.profileModalCardContainer}
        onClick={closeModal}
      ></div>
      <div className={styles.profileModalCardDiv}>
        <Button
          additionalClass={styles.profileModalCardxBtn}
          onClick={() => {
            closeModal();
          }}
        >
          <Icon
            name={"x_3x"}
            folder="settings_modal"
            size={33}
            additionalClass={styles.settingsicon1}
          />
        </Button>
        <Typography
          fontWeight={"font400"}
          color={"colorBlack"}
          variant={"heading2"}
        >
          {t("mainNav.profileTab.buttons.settings.title")}
        </Typography>
        <div className={styles.profileModalCardInnerDiv}>{children}</div>
      </div>
    </>
  );
};

export default ProfileModalCard;
