import React from 'react';
import styles from './AnswerButton.module.css';
import Button from '../../atoms/Button/Button.js';
import Typography from '../../atoms/Typography/Typography.js';

function AnswerButton({ buttonText, additionalClass, onClick }) {
  return (
    <Button
      additionalClass={`${styles.answerButton} ${additionalClass ? styles[additionalClass] : ''}`}
      onClick={onClick}
    >
      <Typography additionalClass={styles.buttonText} variant="heading1" fontWeight="font700" color="colorBlack">
        {buttonText}
      </Typography>
    </Button>
  );
}

export default AnswerButton;
