import React from "react";
import StoreAvatars from "../../molecules/StoreAvatars/StoreAvatars";
import Typography from "../../atoms/Typography/Typography";
import styles from "./StoreAvatarGroup.module.css";

const StoreAvatarGroup = ({ storeDataToRender }) => {
  const avatarsInfo = storeDataToRender[0]?.data;
  const avatarsTitle = storeDataToRender[0]?.title;

  return (
    <div className={styles.storeAvatarGroupContainer}>
      <Typography
        color="colorBlack"
        variant="heading2"
        fontWeight="font900"
        additionalClass={styles.cardsTitles}
      >
        {avatarsTitle}
      </Typography>
      <div className={styles.storeAvatarGroupCards}>
        {avatarsInfo?.map(
          ({
            id,
            image,
            name,
            price,
            priceType,
            androidProductId,
            offerType,
            bonus,
          }) => (
            <StoreAvatars
              key={id}
              imageName={image}
              quantity={name}
              price={price}
              priceType={priceType}
              androidProductId={androidProductId}
              addOn={offerType ? { text: "MOST POPULAR" } : null}
              bonusNum={
                bonus === 5
                  ? { bonusImage: "bonusYellow5" }
                  : bonus === 10
                  ? { bonusImage: "bonusYellow10" }
                  : null
              }
            />
          )
        )}
      </div>
    </div>
  );
};

export default StoreAvatarGroup;
