import React from "react";
import styles from "./PageSeperator.module.css";
import LineSeperator from "../../atoms/LineShape/LineShape";
import Typography from "../../atoms/Typography/Typography";
import { useTranslation } from "react-i18next";

const PageSeperator = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.pageSeperator}>
      <LineSeperator  />
      <Typography variant="body2" fontWeight="font500" color="colorBlack" additionalClass={styles.text}>
        {t("signIn.connectSocial")}
      </Typography>
      <LineSeperator  />
    </div>
  );
};

export default PageSeperator;
