import React, { useState } from "react";
import styles from "./SearchBar.module.css";
import PropTypes from "prop-types";
import Input from "../../atoms/Input/Input";
import Icon from "../../atoms/Icon/Icon";
import Button from "../../atoms/Button/Button";
import Typography from "../../atoms/Typography/Typography";
import { useAuth } from "../../../AthContext";
import { handleSearchSubmit } from "../../../apiHelpers"; // Import the function
import LoadingSpinner from "../../molecules/LoadingSpinner/LoadingSpinner";
import { useTranslation } from "react-i18next";

const SearchBar = ({
  onSearch,
  onUserClick,
  filteredUserList,
  setFilteredUserList,
}) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const { isMobile, language } = useAuth();
  const [isLoading, setIsLoading] = useState(false); // State to track loading state

  const handleSearchChange = (event) => {
    setIsLoading(true); // Set loading state to true while searching
    const query = event.target.value;
    setSearchQuery(query);

    // Check if the search query is more than 3 letters before filtering
    if (query.length > 3) {
      // Call the handleSearchSubmit function to fetch and update the filtered user list
      handleSearchSubmit(query, setFilteredUserList);
    } else {
      // If the search query is less than or equal to 3 letters, clear the filtered list
      setFilteredUserList([]);
    }
    setTimeout(() => {
      setIsLoading(false); // Set loading state to true while searching
    }, 1000); // Simulating a delay of 1 second
  };

  const handleUserClick = (user) => {
    // Pass the selected user's data to the parent component using the onUserClick callback
    onUserClick(user);
  };

  return (
    <div className={styles.search}>
      <div className={styles.inputContainer}>
        <Button
          additionalClass={styles.searchButton}
          onClick={() => handleSearchSubmit(searchQuery, setFilteredUserList)}
        >
          <Icon
            name="search_icon"
            folder="input_icons"
            size={isMobile ? 16 : 24}
          />
        </Button>

        <Input
          type="text"
          placeholder={t("mainNav.friendsTab.searchScreen.title")}
          value={searchQuery}
          onChange={handleSearchChange}
          additionalClass={styles.input}
        />

        {/* Display the filtered usernames */}
      </div>
      {/* Conditionally render the loading spinner */}
      {isLoading && <LoadingSpinner />}
      <div className={styles.filteredUserList}>
        {filteredUserList.map((user, index) => (
          <div
            key={index}
            className={styles.filteredUserItem}
            onClick={() => handleUserClick(user)}
          >
            <Icon
              name={`avatar-${user?.avatarId}`}
              folder="avatars"
              size={90}
              additionalClass={styles.icon}
            />
            <Typography
              variant="heading3"
              fontWeight="font700"
              color="colorBlack"
              additionalClass={styles.avatarUsername}
            >
              {user.username}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
};

SearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired, // Callback function to handle search
  onUserClick: PropTypes.func.isRequired,
};

export default SearchBar;
