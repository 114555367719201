import React from 'react';
import styles from './CounterDownBarNumber.module.css';
import CounterDownNumber from '../../molecules/CounterDownNumber/CounterDownNumber';
import CounterDownBar from '../../molecules/CounterDownBar/CounterDownBar';

const CounterDownBarNumber = ({ seconds,questionIndex }) => {
  return (
    <div className={styles.timerContent}>
      <CounterDownNumber seconds={seconds} questionIndex={questionIndex} />
      <CounterDownBar seconds={seconds} questionIndex={questionIndex} />
    </div>
  );
};

export default CounterDownBarNumber;
