// GameModeSlider.js
import React, { useState, useEffect } from "react";
import styles from "./GameModeSlider.module.css";
import Button from "../../atoms/Button/Button";
import PropTypes from "prop-types";
import Typography from "../../atoms/Typography/Typography";
import { useAuth } from "../../../AthContext";
import { useTranslation } from "react-i18next";

const GameModeSlider = ({ mode, friend, onChange, selectedGameMode }) => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const { isMobile, newMessageNotification } = useAuth();
  const handleOptionClick = (index) => {
    setActiveIndex(index);
    // Call the onChange callback with the selected mode
    onChange && onChange(index);
  };

  const options = ["Quick play", "1 vs 1", "Battle Royale"];
  const modes = ["Friends", "Global"];
  const friends = ["Friends", "Chat", "Search"];

  const translateOptionsTitle = (index) => {
    switch (index) {
      case 0:
        return t("gamemode.quickplay");
      case 1:
        return t("gamemode.1vs1");
      case 2:
        return t("gamemode.battleRoyale");
      default:
        return "";
    }
  };

  const translateFriendTitle = (index) => {
    switch (index) {
      case 0:
        return t("mainNav.friendsTab.friendsScreen.title");
      case 1:
        return t("mainNav.friendsTab.chatScreen.title");
      case 2:
        return t("mainNav.friendsTab.searchScreen.title");
      default:
        return "";
    }
  };
  const translateModesTitle = (index) => {
    switch (index) {
      case 0:
        return t("mainNav.leaderboardTab.modes.friends");
      case 1:
        return t("mainNav.leaderboardTab.modes.global");
      case 2:
        return t("mainNav.leaderboardTab.modes.cashTournaments");
      default:
        return "";
    }
  };
  // for when a user clicks on chat on a friend,to change the selected option from "Friends" to "Chat"
  useEffect(() => {
    if (friend && selectedGameMode) {
      const newIndex = friends.indexOf(selectedGameMode);
      setActiveIndex(newIndex);
    }
  }, [selectedGameMode]);

  return (
    <div className={styles["gameMode-slider"]}>
      {mode
        ? modes.map((mode, index) => (
            <Button
              key={index}
              additionalClass={`${styles["button"]} ${
                activeIndex === index ? styles["active"] : ""
              }`}
              onClick={() => handleOptionClick(index)}
            >
              <Typography
                variant={isMobile ? "caption" : "heading3"}
                fontWeight="font700"
                color={activeIndex === index ? "colorWhite" : "colorBlack"}
              >
                {translateModesTitle(index)}
              </Typography>
            </Button>
          ))
        : friend
        ? friends.map((option, index) => (
            <Button
              key={index}
              additionalClass={`${styles["button"]} ${
                activeIndex === index ? styles["active"] : ""
              }`}
              onClick={() => handleOptionClick(index)}
            >
              <Typography
                variant="heading3"
                fontWeight="font400"
                color={activeIndex === index ? "colorWhite" : "colorBlack"}
              >
                {translateFriendTitle(index)}
              </Typography>
              {index === 1 && newMessageNotification && <div className={`${styles.messageNotification}`}></div>}
            </Button>
          ))
        : options.map((option, index) => (
            <Button
              key={index}
              additionalClass={`${styles["button"]} ${
                activeIndex === index ? styles["active"] : ""
              }`}
              onClick={() => handleOptionClick(index)}
            >
              <Typography
                variant={isMobile ? "caption" : "heading3"}
                fontWeight="font700"
                color={activeIndex === index ? "colorWhite" : "colorBlack"}
                additionalClass={
                  activeIndex !== index ? styles.grey : undefined
                }
              >
                {translateOptionsTitle(index)}
              </Typography>
            </Button>
          ))}
    </div>
  );
};

GameModeSlider.propTypes = {
  mode: PropTypes.bool,
  onChange: PropTypes.func, // Callback function to handle mode change
};

export default GameModeSlider;
