import React from 'react';
import styles from './FancySpinWheelSlices.module.css';
import Icon from '../../atoms/Icon/Icon';

const FancySpinWheelSlices = ({ diameter = 700, isSpinning, rotationDegree }) => {
  const wheelStyle = isSpinning
    ? {
        transition: 'transform 3s',
        transform: `rotate(${rotationDegree}deg)`,
      }
    : {
        transform: `rotate(${rotationDegree}deg)`, // needs to keep the wheel to the latest position
      };

  return (
    <Icon name="wheel_slices" folder="other_icons" size={diameter} additionalClass={styles.slices} style={wheelStyle} />
  );
};

export default FancySpinWheelSlices;
