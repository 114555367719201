import React from "react";
import Icon from "../../atoms/Icon/Icon";
import styles from "./VerticalSocialMediaIcons.module.css";
import { useViewportHeight } from "../../../customHooks";

const VerticalSocialMediaIcons = () => {
  const viewportHeight = useViewportHeight();
  // Social media URLs based on language
  const socialMediaUrls = {
    instagram: {
      en: "https://www.instagram.com/quizdom.battle?igsh=dnltYW53cWNqN2Mz",
      de: "https://www.instagram.com/quizdom.battle?igsh=dnltYW53cWNqN2Mz",
      el: "https://www.tiktok.com/@quizdom.battle.gr?_t=8l1wn8f5VG2",
    },
    tik_tok: {
      en: "https://www.tiktok.com/@quizdom_battle?_t=8l1wowtCmrW&_r=1",
      de: "https://www.tiktok.com/@quizdombattle_de?_t=8lotHBsVVWT&_r=1",
      el: "https://www.tiktok.com/@quizdom.battle.gr?_t=8l1wn8f5VG2",
    },
    youtube: {
      default: "https://youtube.com/@Quizdom.Battle?feature=shared",
    },
    facebook: {
      default:
        "https://www.facebook.com/share/7adEpZGZXj7mQ4Fo/?mibextid=hu50Ix",
    },
  };

  const handleIconClick = (name) => {
    const rawLang = localStorage.getItem("language") || '"en"'; // Assuming language might be stored with extra quotes
    const lang = rawLang.replace(/"/g, ""); // Remove potential quotes
    let url = socialMediaUrls[name]?.[lang] || socialMediaUrls[name]?.default;

    if (url) {
      window.open(url, "_blank");
    }
  };

  let folder = "social_v2";
  return (
    <div className={styles.verticalSocialMediaIcons}>
      <Icon
        name="instagram"
        folder={folder}
        size={viewportHeight < 960 ? 37 : 48}
        onClick={() => handleIconClick("instagram")}
      />
      <Icon
        name="tik_tok"
        folder={folder}
        size={viewportHeight < 960 ? 37 : 48}
        onClick={() => handleIconClick("tik_tok")}
      />
      <Icon
        name="youtube"
        folder={folder}
        size={viewportHeight < 960 ? 37 : 48}
        onClick={() => handleIconClick("youtube")}
      />
      <Icon
        name="facebook"
        folder={folder}
        size={viewportHeight < 960 ? 37 : 48}
        onClick={() => handleIconClick("facebook")}
      />
    </div>
  );
};

export default VerticalSocialMediaIcons;
