import React from "react";
import styles from "./CardProfileInventory.module.css";
import Typography from "../../atoms/Typography/Typography";
import Icon from "../../atoms/Icon/Icon";

const CardProfileInventory = ({ itemName, amount, itemText }) => {
  return (
    <div className={styles.inventoryItem}>
      <Icon name={`${itemName}-1`} folder={"store"} size={70} />
      <div>
        <Typography variant="heading3" fontWeight="font700" color="colorBlack">
          {amount}
        </Typography>
        <Typography
          variant="caption"
          fontWeight="font400"
          color="colorBlack"
          additionalClass={styles.grey}
        >
          {itemText}
        </Typography>
      </div>
    </div>
  );
};

export default CardProfileInventory;
