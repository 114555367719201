import { useEffect, useState } from 'react';
import {
  listenForRefreshSocial,
  listenNewChatMessage,
  stopListenForRefreshSocial,
  stopListenNewChatMessage,
} from './services/sockets'; // Adjust the import path as necessary
import { useNavigate } from 'react-router-dom';
import { useAuth } from "./AthContext";

export const useRefreshSocial = () => {
  // Initialize socialActivity state to false
  const [socialActivity, setSocialActivity] = useState(false);

  useEffect(() => {
    const handleRefreshSocial = () => {
      console.log('Refresh social data event detected.');
      // Toggle the socialActivity state
      setSocialActivity((prevState) => !prevState);
    };

    // Attach the event listener
    listenForRefreshSocial(handleRefreshSocial);

    // Cleanup on component unmount
    return () => {
      stopListenForRefreshSocial(handleRefreshSocial);
    };
  }, []);

  // Return the socialActivity state so it can be used in your components
  return socialActivity;
};

export const useRefreshChat = () => {
  const [chatActivity, setChatActivity] = useState(false);

  useEffect(() => {
    const handleRefreshChat = () => {
      console.log('chat refresh detected');
      setChatActivity((prevState) => !prevState);
    };

    listenNewChatMessage(handleRefreshChat);

    return () => {
      stopListenNewChatMessage(handleRefreshChat);
    };
  }, []);
  return chatActivity;
};

export const useLoginExpired = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  return () => {
    alert('Your login session has expired. Please log in again!');
    logout();
    navigate('/forms', { replace: true });
  };
};

export const useViewportHeight = () => {
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return height;
};