import React from 'react';
import styles from './ProfileOtherUserAssets.module.css';
import Typography from '../../atoms/Typography/Typography';
import Icon from '../../atoms/Icon/Icon';
import Button from '../../atoms/Button/Button';
import OtherProfileButtonSection from '../../molecules/OtherUserProfileButtonSection/OtherUserProfileButtonSection';
import { 
  sendFriendRequest, 
  declineFriendRequest, 
  acceptFriendRequest, 
  cancelFriendRequest,
  removeFriend 
} from '../../../apiHelpers';
import { useTranslation } from 'react-i18next';

const ProfileOtherUserAssets = ({ 
  playerData, 
  friendRequestsList, 
  setFriendRequestsList, 
  outgoingFriendRequestsList, 
  setRefreshSocialTriggered, 
  friendsList,
  setSelectedUser,
  setFilteredUserList,
  setFriendRequestSent
}) => {
  const { t } = useTranslation();

  const handleAddFriendClick = async (userId) => {
    try {
      const response = await sendFriendRequest(userId);
      console.log(response); // Handle success (e.g., show a message)
      setRefreshSocialTriggered(prev => !prev); // Toggle the refresh trigger
      setFriendRequestSent(true)
    } catch (error) {
      console.error(error); // Handle error (e.g., show an error message)
      // Check if the error response contains the data with an error message and alert the user
      if (error.response && error.response.data && error.response.data.error) {
        alert(error.response.data.error);
      } else {
        // If the error format is unknown, you can display a generic message
        alert('An error occurred while sending the friend request. Please try again later.');
      }
    }
  };
  

  const handleCancelFriendClick = async (userId) => {
    try {
      const response = await cancelFriendRequest(userId);
      console.log(response); // Handle success (e.g., show a message)
      setRefreshSocialTriggered(prev => !prev); // Toggle the refresh trigger
    } catch (error) {
      console.error(error); // Handle error (e.g., show an error message)
    }
  };

  const handleAccept = async (userId) => {
    try {
      const result = await acceptFriendRequest(userId);
      console.log(result); // Handle the result accordingly
      setRefreshSocialTriggered(prev => !prev);
    } catch (error) {
      console.error('Failed to decline friend request', error);
      // Handle error (e.g., show an error message)
    }
  };


  const handleReject = async (userId) => {
    try {
      const result = await declineFriendRequest(userId);
      console.log(result); // Handle the result accordingly
      // Update the friendRequestsList to exclude the declined request
      const updatedFriendRequestsList = friendRequestsList.filter(request => request.from.internalId !== userId);
      setFriendRequestsList(updatedFriendRequestsList);
    } catch (error) {
      console.error('Failed to decline friend request', error);
      // Handle error (e.g., show an error message)
    }
  };

  const handleRemoveFriendButton = async (userId) => {
    try {
      const result = await removeFriend(userId);
      setRefreshSocialTriggered(prev => !prev); // Toggle the refresh trigger
      console.log(result); // Handle the result accordingly
    } catch (error) {
      console.error('Failed to decline friend request', error);
      // Handle error (e.g., show an error message)
    }
  }

  const didISendToUserAFriendRequest = outgoingFriendRequestsList.some(outgoingFriendRequest => 
  outgoingFriendRequest.to.internalId === playerData.internalId
  )

  const didTheUserAskedMeToBeFriends = friendRequestsList.some(friendRequest => 
    friendRequest.from.internalId === playerData.internalId
  );

  const theUserIsAlreadyMyFriend = friendsList.some(friend=>
    friend.internalId === playerData.internalId)

    let textButton;

    if (theUserIsAlreadyMyFriend) {
      textButton = 'Remove Friend';
    } else if (didISendToUserAFriendRequest) {
      textButton = 'Cancel Request';
    } else if (didTheUserAskedMeToBeFriends) {
      textButton = 'Reject Request';
    } else {
      textButton = 'Add Friend';
    }
    

  const actionOnClick = () => {
    if (didISendToUserAFriendRequest){
      handleCancelFriendClick(playerData.internalId)
    } else if (didTheUserAskedMeToBeFriends) {
      handleReject(playerData.internalId); // If a friend request has been received, call handleReject
    } else if (theUserIsAlreadyMyFriend){
      handleRemoveFriendButton(playerData.internalId); // If no friend request has been received, call handleAddFriendClick
    } else {
      handleAddFriendClick(playerData.internalId); 
    }
  };

  return (
    <div className={styles.itemsInfo}>
      <div className={styles.itemBox}>
        <span>
          <Icon name={'goldenTrophy'} folder={'other_icons'} size={32} />
          <Typography fontWeight={'font700'} color={'colorBlack'} additionalClass={styles.font24}>
            {playerData.userStats.maxTrophies}
          </Typography>
        </span>
      </div>
      <div className={styles.yellowButtonsSection}>
      <Button onClick={actionOnClick}>
        <Icon name={'addUser'} folder={'other_icons'} />
        <Typography variant="body1" fontWeight="font700">
          {t(`generic.${textButton}`)}
        </Typography>
      </Button>
      {didTheUserAskedMeToBeFriends && (
        <Button onClick={() => handleAccept(playerData.internalId)}>
          <Typography variant="body1" fontWeight="font700">
            {t('generic.Accept Request')}
          </Typography>
        </Button>
      )}
      </div>
      <OtherProfileButtonSection playerData={playerData} setRefreshSocialTriggered={setRefreshSocialTriggered} setSelectedUser={setSelectedUser} setFilteredUserList={setFilteredUserList}/>
    </div>
  );
};

export default ProfileOtherUserAssets;
