import React from "react";
import styles from "./ChatWindowAndTypingSection.module.css";
import ChatWindow from "../ChatWindow/ChatWindow";
import ChatTypingSection from "../ChatTypingSection/ChatTypingSection";

const ChatWindowAndTypingSection = ({
  messages,
  opponentInternalId,
  setChatActivityTriggered,
  setMessages,
}) => {
  return (
    <div className={styles.chatWindowAndTypingSectionWrapper}>
      <ChatWindow messages={messages} />
      <ChatTypingSection
        opponentInternalId={opponentInternalId}
        setChatActivityTriggered={setChatActivityTriggered}
        setMessages={setMessages}
      />
    </div>
  );
};

export default ChatWindowAndTypingSection;
