import React from "react";
import Typography from "../../atoms/Typography/Typography";
import styles from "./LeaderboardHeadings.module.css";
import Icon from "../../atoms/Icon/Icon";

const LeaderboardHeadings = ({ leaderboardHeadings, isMobile }) => {
  return (
    <div className={styles.leaderboardHeadings}>
      {leaderboardHeadings.map((entry, index) => (
        <div className={styles.heading} key={index}>
          <Typography
            variant={isMobile ? "caption" : "body2"}
            fontWeight={"font500"}
            color="colorBlack"
            additionalClass={styles.grey}
          >
            {entry}
          </Typography>
        </div>
      ))}
      <div className={styles.trophy}>
        <Icon folder={"other_icons"} name="goldenTrophy" size={24} />
      </div>
    </div>
  );
};

export default LeaderboardHeadings;
