import React, { useState, useEffect } from 'react';
import styles from './DidYouKnowBox.module.css';
import Typography from '../../atoms/Typography/Typography';

const DidYouKnowBox = ({ item, title }) => {
  const [hasDataCome, setHasDataCome] = useState(false);

  // when the data comes, we raise the setHasDataCome flag so the text-container get another class.
  // A small delay here is crucial,otherwise the smooth transition will not be visible
  useEffect(() => {
    const timer = setTimeout(() => {
      setHasDataCome(true);
    }, 20); // A short delay before expanding
    return () => clearTimeout(timer);
  }, [item]);

  const captionText = item.year ? `${item.year}` : item.author ? `${item.author}` : '';

  return (
    <div className={`${styles['text-container']} ${hasDataCome ? styles.expanded : ''}`}>
      <Typography
        fontWeight="font900"
        color="colorBlack"
        additionalClass={`${styles.header} 
        ${styles.headerBlack}`}
      >
        {title}
      </Typography>

      <Typography
        variant="body1"
        fontWeight="font500"
        color="colorBlack"
        additionalClass={`${styles.knowledge} ${styles.headerBlack}`}
      >
        {item.content || item.quote || item.title}
      </Typography>

      {captionText && (
        <Typography
          variant="body1"
          fontWeight="font500"
          color="colorBlack"
          additionalClass={`${styles.caption} ${styles.headerBlack}`}
        >
          - {captionText} -
        </Typography>
      )}
    </div>
  );
};

export default DidYouKnowBox;
