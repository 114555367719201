import React from 'react';
import styles from './CongratulationsBoxUnlogged.module.css';
import Typography from '../../atoms/Typography/Typography';
import NotificationsButton from '../../molecules/NotificationsButton/NotificationsButton';
import PlayButton from '../../molecules/PlayButton/PlayButton';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../AthContext';
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const CongratulationsBoxUnlogged = () => {
  const { setPlayingModeStatus } = useAuth();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation();

  const rightAnswers = state ? state.rightAnswers : null;

  const onButtonClick = () => {
    setPlayingModeStatus(false); // This will set isUserAboutToPlay to false
    // Use navigate to programmatically navigate and pass state
    navigate('/forms', { replace: true, state: { showLoginPage: false, referralCode: 'free' } });
  };

  return (
    <div className={`${styles['box-container']}`}>
      <div className={`${styles['text-container']}`}>
        <Typography variant="heading2" fontWeight="font700" additionalClass={`${styles['header-text']}`}>
        {t("result1v1Screen.wonFirstText")}
        </Typography>
        <Typography
          variant="body2"
          fontWeight="font700"
          color="colorBlack"
          additionalClass={`${styles['caption-text']}`}
        >
          {t("result1v1Screen.wonSecondText")}
        </Typography>
      </div>
      <div>{`${t("result1v1Screen.wonThirdText")} ${rightAnswers}/5`}</div>
      <div className={`${styles['notifications-container']}`}>
        <NotificationsButton text={t("result1v1Screen.wonFourthText")} imageName="goldenTrophy" />
        <Typography
          variant="body2"
          fontWeight="font700"
          color="colorBlack"
          additionalClass={`${styles['question-text']}`}
        >
          {t("result1v1Screen.wonFifthText")}
        </Typography>
      </div>
      <PlayButton
        primaryClass="skewed-play-button"
        additionalClass="play-now"
        primaryText={t("result1v1Screen.wonTextButton")}
        onClick={onButtonClick}
      />
    </div>
  );
};

export default CongratulationsBoxUnlogged;
