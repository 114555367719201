import React from "react";
import styles from "./ChatMessageBubble.module.css";
import Typography from "../../atoms/Typography/Typography";

const ChatMessageBubble = ({ text, timestamp, senderIsCurrentUser }) => {
  const messageClass = senderIsCurrentUser
    ? styles.outgoingMessage
    : styles.incomingMessage;

  const formattedTimestamp = new Date(timestamp).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <div className={messageClass}>
      <Typography
        variant="heading3"
        fontWeight="font500"
        color="colorBlack"
        additionalClass={styles.messageText}
      >
        {text}
      </Typography>
      <Typography
        variant="caption"
        fontWeight="font400"
        color="colorBlack"
        additionalClass={styles.timestampText}
      >
        {formattedTimestamp}
      </Typography>
    </div>
  );
};

export default ChatMessageBubble;
