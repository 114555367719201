// components/molecules/GameSelectionPanel/CashQuizTournamentsPanel.js
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Typography from "../../atoms/Typography/Typography";
import Icon from "../../atoms/Icon/Icon";
import styles from "./CashQuizTournamentsPanel.module.css";
import Button from "../../atoms/Button/Button";
import { useTranslation } from "react-i18next";

const CashQuizTournamentsPanel = ({
  isTournament,
  title,
  startTime,
  panelColor,
}) => {
  const { t } = useTranslation();
  let folder = "tournaments";

  // State for countdown
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    // Function to calculate the countdown
    const calculateCountdown = () => {
      const currentTime = new Date();
      const difference = startTime - currentTime;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        setCountdown({ days, hours, minutes, seconds });
      }
    };

    // Update countdown every second
    const countdownInterval = setInterval(calculateCountdown, 1000);

    // Initial calculation
    calculateCountdown();

    // Clear interval on component unmount
    return () => clearInterval(countdownInterval);
  }, [startTime]);

  return (
    <div className={styles.panel}>
      <div className={`${styles.content}`}>
        <span className={styles.badge}>
          <Typography variant={"caption"} fontWeight={"font400"}>
            {isTournament
              ? t("generic.getYourTicket")
              : t("generic.playEveryday")}
          </Typography>
        </span>
        <div className={styles.iconGroup}>
          <Icon
            className={styles.character}
            name={"schoolbus"}
            folder={folder}
            size={180}
            color="black"
            additionalClass={styles.icon1}
          />
          <Icon
            className={styles.character}
            name={"books"}
            folder={folder}
            size={150}
            additionalClass={styles.icon2}
            color="black"
          />
          <Icon
            className={styles.character}
            name={"schoolbag"}
            folder={folder}
            size={125}
            additionalClass={styles.icon3}
            color="black"
          />
        </div>
        <div className={styles.textContainer}>
          <Typography variant="heading2" fontWeight="font400">
            {title}
          </Typography>
        </div>
      </div>
      <div className={`${styles.rectangle} ${styles[panelColor]}`}></div>
      <div className={styles.panelInfo}>
        <div className={styles.upperSection}>
          <span>
            <Typography fontWeight={"font400"} color="colorBlack">
              {t("generic.prizePool")}
            </Typography>
            <Button>{"50 €"}</Button>
          </span>
          <span>
            <Typography color="colorBlack">
              {isTournament ? t("generic.startsIn") : t("generic.every")}
            </Typography>
            <Typography
              color="colorBlack"
              variant={"body2"}
              fontWeight={"font400"}
            >
              {isTournament
                ? `${countdown.days}d ${countdown.hours}h ${countdown.minutes}m ${countdown.seconds} s`
                : `30 ${t("generic.min")}`}
            </Typography>
          </span>
          <span>
            <Typography fontWeight={"font400"} color="colorBlack">
              {t("generic.entryFee")}
            </Typography>
            <Button additionalClass={styles.gold}>
              {isTournament ? "50 €" : t("generic.free")}
            </Button>
          </span>
        </div>
        <div className={styles.bottomSection}>
          <Button
            additionalClass={isTournament ? styles.gold : styles.darkGreen}
          >
            <Typography variant="body1">{t("generic.registerNow")}</Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};

CashQuizTournamentsPanel.propTypes = {
  isTournament: PropTypes.bool,
  title: PropTypes.string.isRequired,

  color: PropTypes.oneOf(["red", "blue", "green"]), // Add more colors as needed
  startTime: PropTypes.instanceOf(Date).isRequired,
};

export default CashQuizTournamentsPanel;
