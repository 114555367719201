// HeaderMenu.js
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./HeaderMenu.module.css";
import Button from "../../atoms/Button/Button";
import Icon from "../../atoms/Icon/Icon";
import LoginSlider from "../../molecules/LoginSlider/LoginSlider";
import MenuPlayerInfo from "../../molecules/MenuPlayerInfo/MenuPlayerInfo";
import LanguageMenu from "../../molecules/LanguageMenu/LanguageMenu";
import SettingsMenu from "../../molecules/SettingsMenu/SettingsMenu";
import { useAuth } from "../../../AthContext";
import { Link } from "react-router-dom";

const HeaderMenu = ({ activeIndex, setActiveIndex }) => {
  const { loggedIn, playerInfo, isMobile, setPlayingModeStatus } = useAuth();

  const [showLanguageMenu, setShowLanguageMenu] = useState(false);
  const [showSettingMenu, setShowSettingMenu] = useState(false);

  const toggleLanguageMenu = (e) => {
    e.stopPropagation(); // Prevent click from propagating
    setShowLanguageMenu(!showLanguageMenu);
    setShowSettingMenu(false);
  };

  const toggleSettingMenu = (e) => {
    e.stopPropagation(); // Prevent click from propagating.
    setShowSettingMenu(!showSettingMenu);
    setShowLanguageMenu(false);
  };

  // CLOSING LANGUAGE AND SETTINGS MENU WHEN CLICKING OUTSIDE OF THEM //
  useEffect(() => {
    // Function to close menus when clicking outside
    const closeMenus = () => {
      setShowLanguageMenu(false);
      setShowSettingMenu(false);
    };

    // Add click event listener to the document
    document.addEventListener("click", closeMenus);

    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener("click", closeMenus);
    };
  }, []);

  return (
    <div className={styles.header}>
      <Link to="/">
        <div className={styles.logo}>
          <Icon folder={"logos"} name={"quizdom"} size={isMobile ? 90 : 152} />
        </div>
      </Link>
      <div className={styles.menuRightContainer}>
        {isMobile && loggedIn && (
          <>
            <Button
              additionalClass={styles.iconButton}
              onClick={toggleLanguageMenu}
            >
              <Icon
                folder={"menu_icons"}
                name={showLanguageMenu ? "language_on" : "language"}
                size={isMobile ? 32 : 48}
                additionalClass={styles.languageIcon}
              />
            </Button>
            {showLanguageMenu && (
              <LanguageMenu toggleLanguageModal={setShowLanguageMenu} />
            )}
          </>
        )}
        {(!isMobile || !loggedIn) && (
          <div className={styles.buttonContainer}>
            <Button
              additionalClass={styles.iconButton}
              onClick={toggleLanguageMenu}
            >
              <Icon
                folder={"menu_icons"}
                name={showLanguageMenu ? "language_on" : "language"}
                size={isMobile ? 32 : 48}
                additionalClass={styles.languageIcon}
              />
            </Button>
            {showLanguageMenu && (
              <LanguageMenu toggleLanguageModal={setShowLanguageMenu} />
            )}

            <Button
              additionalClass={styles.iconButton}
              onClick={toggleSettingMenu}
            >
              <Icon
                folder={"menu_icons"}
                name={showSettingMenu ? "setting_on" : "setting"}
                size={isMobile ? 32 : 48}
                additionalClass={styles.settingsIcon}
              />
            </Button>
            {showSettingMenu && <SettingsMenu />}
          </div>
        )}

        {loggedIn ? (
          <MenuPlayerInfo playerInfo={playerInfo} isMobile={isMobile} />
        ) : (
          (!isMobile || !loggedIn) && (
            <LoginSlider
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
            />
          )
        )}
      </div>
    </div>
  );
};

export default HeaderMenu;
