// PanelIcons.js
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Icon from "../../atoms/Icon/Icon";
import Tooltip from "../Tooltip/Tooltip";
import styles from "./PanelIcons.module.css";
import { useTranslation } from "react-i18next";
import { useViewportHeight } from "../../../customHooks";
import { socket } from '../../../services/sockets'; // Import your socket instance
import { useAuth } from "../../../AthContext";

const PanelIcons = () => {
  const location = useLocation(); // Hook to get the current location object
  const [selectedIcon, setSelectedIcon] = useState("");
  const { t } = useTranslation();
  const viewportHeight = useViewportHeight();
  const { newMessageNotification, setNewMessageNotification } = useAuth();

  const panelIconData = [
    {
      name: "home",
      tooltipName: "home",
      folder: "menu_icons",
      linkTo: "/",
    },
    {
      name: "quiz",
      tooltipName: "quiz",
      folder: "menu_icons",
      linkTo: "/quiz",
    },
    {
      name: "leaderboards",
      tooltipName: t("mainNav.leaderboardTab.title"),
      folder: "menu_icons",
      linkTo: "leaderboards",
    },
    {
      name: "wallet",
      tooltipName: t("mainNav.walletTab.title"),
      folder: "menu_icons",
      linkTo: "wallet",
    },
    {
      name: "store",
      tooltipName: "shop",
      folder: "menu_icons",
      linkTo: "store",
    },
    {
      name: "friends",
      tooltipName: t("mainNav.friendsTab.friendsScreen.title"),
      folder: "menu_icons",
      linkTo: "friends",
    },
    {
      name: "profile",
      tooltipName: t("mainNav.profileTab.title"),
      folder: "menu_icons",
      linkTo: "profile",
    },
  ];

  useEffect(() => {
    // gives the url items seperated by "/"
    // https://localhost:3000/home/text/user will give ["home","text","user"]
    const pathSegments = location.pathname.split("/").filter(Boolean); // gives the url items seperated by "/"
    const firstPath = pathSegments.length === 0 ? "home" : pathSegments[0];
    setSelectedIcon(firstPath);
  }, [location]); // Re-run this effect if the location changes

  useEffect(() => {
    // Handle new chat message received via socket
    const handleNewChatMessage = () => {
      setNewMessageNotification(true); // Update the context state
    };

    socket.on("new chat message", handleNewChatMessage);

    return () => {
      socket.off("new chat message", handleNewChatMessage);
    };
  }, [setNewMessageNotification]);

  useEffect(() => {
    // Handle changes in the context (e.g., when the same app is open in multiple tabs)
    const handleStorageChange = (event) => {
      if (event.key === 'newChatMessageFlag') {
        setNewMessageNotification(event.newValue === 'true');
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [setNewMessageNotification]);

  const handleIconClick = (iconName) => {
    console.log(`Clicked on ${iconName}`);
    setSelectedIcon(iconName === selectedIcon ? null : iconName);
  };

  return (
    <div className={styles.panelIcons}>
      {panelIconData.map((icon, index) => (
        <Link key={index} to={icon.linkTo}>
          <Tooltip text={icon.tooltipName}>
            <Icon
              name={selectedIcon === icon.name ? `${icon.name}_on` : icon.name}
              folder={icon.folder}
              size={viewportHeight < 960 ? 40 : 60}
              onClick={() => handleIconClick(icon.name)}
            />
            {icon.name === "friends" && newMessageNotification && (
              <div className={styles.messageNotification}></div>
            )}
          </Tooltip>
        </Link>
      ))}
    </div>
  );
};

export default PanelIcons;
