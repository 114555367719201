import React from 'react';
import styles from './ComingSoon.module.css';
import Icon from '../../atoms/Icon/Icon';
import Typography from '../../atoms/Typography/Typography';
import { useTranslation } from 'react-i18next';

const ComingSoon = ({ children, variant = 'default' }) => {
  const { t } = useTranslation();

  const isGoldVariant = variant === 'gold';
  const headingText = isGoldVariant ? t('generic.playFree') : t('generic.COMING SOON');
  const extraText = isGoldVariant ? t('generic.summerOffer') : t('generic.stayTuned');

  return (
    <div className={styles.comingSoonWrapper}>
      <div className={styles.comingSoonContent}>
        <div>
          <div className={styles.einsteinImageWrapper}>
            <Icon name="illustration_9" folder="characters" size={290} additionalClass={styles.einsteinImage} />
          </div>
          <Typography
            variant="headingXL"
            fontWeight="font900"
            color="colorBlack"
            additionalClass={isGoldVariant ? styles.goldHeading : styles.heading}
          >
            {headingText}
          </Typography>
        </div>
        <Typography
          variant="heading2"
          fontWeight="font900"
          color="colorWhite"
          additionalClass={isGoldVariant ? styles.goldText : styles.text}
        >
          {extraText}
        </Typography>
      </div>
      <div className={isGoldVariant ? styles.blurredChildren : styles.unblurredChildren}>{children}</div>
    </div>
  );
};

export default ComingSoon;
