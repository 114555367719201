import React from "react";
import styles from "./RemixImages.module.css";
import Icon from "../../atoms/Icon/Icon";
import { useAuth } from "../../../AthContext";
import { useTranslation } from "react-i18next";

const RemixImages = ({ groupName }) => {
  const { isMobile } = useAuth();
  const { t } = useTranslation();

  const titleKey_FlashQuiz = t("mainNav.homeTab.popularQuizGames.card1.title");
  const titleKey_EpicEncounter = t(
    "mainNav.homeTab.popularQuizGames.card2.title"
  );
  const titleKey_BrainMarathon = t(
    "mainNav.homeTab.popularQuizGames.card3.title"
  );
  const titleKey_BurgerDay = t("mainNav.homeTab.specialQuizzes.card1.title");
  const titleKey_CannesFilmFest = t(
    "mainNav.homeTab.specialQuizzes.card2.title"
  );
  const titleKey_Formula1GP = t("mainNav.homeTab.specialQuizzes.card3.title");

  const imageGroups = {
    [titleKey_FlashQuiz]: {
      primary: {
        name: "illustration_11",
        folder: "characters",
        size: "100%",
      },
      secondary: {
        name: "lightning",
        folder: "other_icons",
        size: "25%",
        position: { top: 17, left: 5 },
      },
    },
    [titleKey_EpicEncounter]: {
      primary: {
        name: "illustration_12",
        mobileName: "illustration_18",
        folder: "characters",
        size: "100%",
      },
      secondary: {
        name: "crossed_swords",
        folder: "other_icons",
        size: "55%",
        position: { top: 7, left: -6.5 },
        mobilePosition: { top: 7, left: -16.5 },
      },
    },
    [titleKey_BrainMarathon]: {
      primary: {
        name: "illustration_13",
        folder: "characters",
        size: "90%",
      },
      secondary: {
        name: "brain",
        folder: "other_icons",
        size: "50%",
        position: { top: 2, left: -5.5 },
        mobilePosition: { top: 4, left: -8.5 },
      },
    },
    [titleKey_BurgerDay]: {
      primary: {
        name: "illustration_14",
        mobileName: "illustration_19",
        folder: "characters",
        size: "85%",
      },
      secondary: {
        name: "burger",
        folder: "other_icons",
        size: "40%",
        position: { top: 20, left: -3 },
      },
    },
    [titleKey_CannesFilmFest]: {
      primary: {
        name: "illustration_25",
        mobileName: "illustration_25",
        folder: "characters",
        size: "90%",
        mobileSize: "85%",
      },
      // secondary: {
      //   name: "movie_popcorn",
      //   folder: "other_icons",
      //   size: "58%",
      //   mobileSize: "55%",
      //   position: { top: 10, left: -25.5 },
      //   mobilePosition: { top: 10, left: -21.5 },
      // },
    },
    [titleKey_Formula1GP]: {
      primary: {
        name: "illustration_16",
        folder: "characters",
        size: "90%",
        mobileSize: "80%",
      },
      secondary: {
        name: "race_flags",
        folder: "other_icons",
        size: "77%",
        mobileSize: "68%",
        position: { top: 4, left: -34 },
        mobilePosition: { top: -3, left: -23.5 },
      },
    },
  };

  const group = imageGroups[groupName];

  const secondaryIconStyle =
    isMobile && group.secondary?.mobilePosition
      ? {
          top: `${group.secondary?.mobilePosition.top}%`,
          left: `${group.secondary?.mobilePosition.left}%`,
        }
      : {
          top: `${group.secondary?.position.top}%`,
          left: `${group.secondary?.position.left}%`,
        };

  return (
    <div className={styles.imagesContainer}>
      <Icon
        name={
          isMobile && group.primary.mobileName
            ? group.primary.mobileName
            : group.primary.name
        }
        folder={group.primary.folder}
        size={
          isMobile && group.primary.mobileSize
            ? group.primary.mobileSize
            : group.primary.size
        }
        additionalClass={styles.primaryImage}
      />
      {group.secondary && (
        <Icon
          name={group.secondary.name}
          folder={group.secondary.folder}
          size={
            isMobile && group.secondary.mobileSize
              ? group.secondary.mobileSize
              : group.secondary.size
          }
          additionalClass={styles.secondaryImage}
          style={secondaryIconStyle}
        />
      )}
    </div>
  );
};

export default RemixImages;
