import React from "react"
import styles from "./PlayButton.module.css"
import Button from "../../atoms/Button/Button.js"
import Typography from "../../atoms/Typography/Typography.js"
import Icon from "../../atoms/Icon/Icon.js"

const PlayButton = ({
  additionalClass,
  primaryText,
  secondaryText,
  imageNameLeft,
  imageNameRight,
  onClick,
  disabled = false,
  extraClass,
  id = "",
}) => {
  return (
    <Button
      id={id}
      additionalClass={`${styles["skewed-play-button"]} ${styles[additionalClass] || ""} ${extraClass || ""}`}
      onClick={onClick} disabled={disabled} 
    >
      <div className={`${styles["button-content"]}`}>
        {imageNameLeft && <Icon name={imageNameLeft} folder="other_icons" size={56} />}

        <Typography id={id} variant="heading2" fontWeight="font900">
          {primaryText}
        </Typography>

        {secondaryText && (
          <Typography variant="body2" fontWeight="font700" color="colorBlack">
            {secondaryText}
          </Typography>
        )}
        {imageNameRight && <Icon name={imageNameRight} folder="other_icons" size={56} />}
      </div>
    </Button>
  )
}

export default PlayButton
