import React from 'react';
import Typography from '../../atoms/Typography/Typography';
import { useTranslation } from 'react-i18next';

const NumberOfQuestions = ({ currentQuestionNumber, totalQuestions }) => {
  const { t } = useTranslation();
  
  return (
    <Typography variant="heading2" fontWeight="font700" color="colorBlack">
      {t('game1v1Screen.question')}   {currentQuestionNumber + 1}/{totalQuestions}
    </Typography>
  );
};

export default NumberOfQuestions;
