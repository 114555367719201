import React, { useEffect } from 'react';
import styles from './ChatPersonsList.module.css';
import ChatPerson from '../ChatPerson/ChatPerson';
import { useAuth } from '../../../AthContext'; // Adjust the import path as needed

const ChatPersonsList = ({
  discussions,
  activeDiscussion,
  setActiveDiscussionId,
  onPersonClick,
  setChatActivityTriggered
}) => {
  const { playerInfo } = useAuth(); // Assuming you have access to the current user's info here

  // this is repeated in ChatOrganism
  const giveToUsersRolesToChat = (discussion) => {
    if (!discussion || !playerInfo) return { currentUserRole: '', opponentUserRole: '' };

    const opponentUserRole = playerInfo.internalId === discussion.user1.internalId ? 'user2' : 'user1';

    return { opponentUserRole };
  };

  // when you click on a discussion,needs to get active
  // checks if the discussion that clicked is contained in the discussions and the position of it
  const handlePersonClick = async (discussion) => {
    setActiveDiscussionId(discussion.chatId);
    setChatActivityTriggered((prev) => !prev);
    onPersonClick(); // needs for mobile to open the chat window
  };

  return (
    <div className={styles.chattingPersonListWrapper}>
      {discussions?.length > 0 &&
        discussions.map((discussion) => {
          const { opponentUserRole } = giveToUsersRolesToChat(discussion);
          return (
            <ChatPerson
              key={discussion.chatId}
              id={discussion.chatId}
              isDiscussionSelected={discussion === activeDiscussion}
              onClick={() => handlePersonClick(discussion)}
              avatarId={discussion[opponentUserRole].avatarId}
              username={discussion[opponentUserRole].username}
              message={discussion.lastMessage.message}
              timeStamp={discussion.lastMessage.createdAt}
            />
          );
        })}
    </div>
  );
};

export default ChatPersonsList;
