import React from 'react';
import styles from './ChatWindowTopSection.module.css';
import Icon from '../../atoms/Icon/Icon';
import Typography from '../../atoms/Typography/Typography';
import Button from '../../atoms/Button/Button';

const ChatWindowTopSection = ({ discusserAvatarId, discusserName, closeChatWindow }) => {
  return (
    <div className={styles.chatWindowTopSectionContainer}>
      <Button additionalClass={styles.backButton} onClick={closeChatWindow}>
        <Icon name="arrowLeft2" folder="arrows" size={32} />
      </Button>
      <Icon name={`avatar-${discusserAvatarId}`} folder="avatars" size={48} additionalClass={styles.discusserAvatar} />
      <Typography variant="body1" fontWeight="font700" color="colorBlack">
        {discusserName}
      </Typography>
    </div>
  );
};

export default ChatWindowTopSection;
