import React, { useEffect, useState, useRef } from "react";
import {
  emitNewGameVersus,
  emitStartBotVersus,
  listenGameStartVersus,
  stopListenGameStartVersus,
  emitLeaveLobbyVersus,
  listenFinalizeVersus,
  stopListenFinalizeVersus,
  emitGetQuestionVersus,
  emitUserAnswerVersus,
  listenOpponentAnsweredVersus,
  stopListenOpponentAnsweredVersus,
  emitLeaveGameVersus,
} from "../../../services/sockets";
import styles from "./PvPmode.module.css";
import NumberOfQuestions from "../../molecules/NumberOfQuestions/NumberOfQuestions";
import CounterDownBarNumber from "../../organisms/CounterDownBarNumber/CounterDownBarNumber";
import QuestionAndAnswersRealTime from "../../organisms/QuestionAndAnswersRealTime/QuestionAndAnswersRealTime";
import PvPmodeScoreSection from "../../organisms/PvPmodeScoreSection/PvPmodeScoreSection";
import { useAuth } from "../../../AthContext";
import PvPmodeResultsTemplate from "../PvPmodeResultsTemplate/PvPmodeResultsTemplate";
import WaitingScreen from "../../organisms/WaitingScreen/WaitingScreen";
import { useLoginExpired } from "../../../customHooks";

const PvPmode = () => {
  const [gameActive, setGameActive] = useState(false);
  const [gameId, setGameId] = useState(null);
  const [question, setQuestion] = useState("");
  const [questionNo, setQuestionNo] = useState(1);
  const [userAnswer, setUserAnswer] = useState(null);
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [userScore, setUserScore] = useState(0);
  const [finalResults, setFinalResults] = useState(null);
  const [opponentInfo, setOpponentInfo] = useState(null);
  const loginExpired = useLoginExpired();

  const latestUserAnswer = useRef(userAnswer);
  const startTime = useRef(0);
  const { playerInfo } = useAuth();

  const opponentScoreRef = useRef(0);

  useEffect(() => {
    // Use effect that starts and Ends the game
    let gameStartTimer;

    listenGameStartVersus((response) => {
      setOpponentInfo(response.players.opponent);
      setGameActive(true);
      setGameId(response.gameId);
      clearTimeout(gameStartTimer);
    });

    emitNewGameVersus((response) => {
      //console.log(response);
      // if response.code === 0 , the game is created
      // else the game is not created and we want to loginExpired()
      if (response.code !== 0) {
        //console.log("Game not created");
        loginExpired();
      }
    });

    gameStartTimer = setTimeout(() => {
      emitStartBotVersus((response) => {
        //console.log(response);
      });
    }, 7000);

    listenOpponentAnsweredVersus((response) => {
      opponentScoreRef.current = response.opponentScore; // Update opponent's score ref
    });

    listenFinalizeVersus(gameFinalization);

    return () => {
      emitLeaveLobbyVersus(() => {});
      emitLeaveGameVersus(() => {});
      stopListenGameStartVersus(() => {});
      stopListenFinalizeVersus(() => {});
      stopListenOpponentAnsweredVersus(() => {});
    };
  }, []);

  const gameFinalization = (response) => {
    setFinalResults(response);
  };

  const questionResultHanlder = (response) => {
    setUserScore(parseInt(response.userScore));
    setCorrectAnswer(parseInt(response.correctAnswer));
  };

  const fetchNextQuestion = () => {
    setTimeout(() => {
      if (questionNo < 7) {
        //console.log("Proceeding to question: ", questionNo + 1);
        setQuestionNo((prevQuestion) => prevQuestion + 1);
      } else {
        setGameActive(false);
      }
    }, 3000); // 3 seconds delay
  };

  // Use effect that handles the game loop
  useEffect(() => {
    if (gameActive) {
      setCorrectAnswer(null);
      setUserAnswer(null);
      startTime.current = Date.now();
      emitGetQuestionVersus({ gameId, questionNo }, (response) => {
        //console.log(response);
        setQuestion(response.questionData);
        latestUserAnswer.current = null; // Update latestUserAnswer ref
        const answerTimeout = setTimeout(() => {
          if (!latestUserAnswer.current) {
            emitUserAnswerVersus(
              { gameId, questionNo, userAnswer: 5, timeToAnswer: 12 },
              (response) => {
                //console.log(response);
                questionResultHanlder(response);
                fetchNextQuestion();
              }
            );
          }
        }, 12000);
        return () => clearTimeout(answerTimeout);
      });
    }
  }, [gameActive, questionNo, gameId]);

  const handleUserAnswer = (selectedAnswer) => {
    // If the user has already answered OR the timer has expired, ignore the click
    if (latestUserAnswer.current || Date.now() - startTime.current >= 12000) {
      return;
    }

    latestUserAnswer.current = selectedAnswer; // Update latestUserAnswer ref
    setUserAnswer(selectedAnswer);
    const answerTimestamp = Date.now(); // Record the timestamp of the button click

    const elapsedTime = answerTimestamp - startTime.current;
    const remainingTime = 12000 - elapsedTime;

    setTimeout(() => {
      emitUserAnswerVersus(
        {
          gameId,
          questionNo,
          userAnswer: selectedAnswer,
          timeToAnswer: elapsedTime,
        },
        (response) => {
          questionResultHanlder(response);
          fetchNextQuestion();
        }
      );
    }, remainingTime);
  };

  return (
    <div className={styles.questionAnswersContainer}>
      {!finalResults && !gameActive && <WaitingScreen />}
      {gameActive && (
        <>
          <PvPmodeScoreSection
            playerInfo={playerInfo}
            opponentInfo={opponentInfo}
            scores={{
              playerScore: userScore,
              opponentScore: opponentScoreRef.current,
            }}
          />
          <NumberOfQuestions
            currentQuestionNumber={questionNo - 1}
            totalQuestions={7}
          />
          <CounterDownBarNumber seconds={12} questionIndex={questionNo} />
          <QuestionAndAnswersRealTime
            currentQuestion={question}
            handleUserAnswer={handleUserAnswer}
            userAnswer={userAnswer}
            correctAnswer={correctAnswer}
          />
        </>
      )}
      {finalResults && !gameActive && (
        <PvPmodeResultsTemplate results={finalResults} />
      )}
    </div>
  );
};

export default PvPmode;
