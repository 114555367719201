import React from 'react';
import styles from './WaitingLobbyTournaments.module.css';
import TournamentInformation from '../../molecules/TournamentInformation/TournamentInformation';
import TournamentParticipants from '../TournamentParticipants/TournamentParticipants';
import CountdownTimer from '../../molecules/CountdownTimer/CountdownTimer';
import { useAuth } from '../../../AthContext';

const WaitingLobbyTournaments = ({
  tournament = {
    name: '',
    description: '',
  },
  players = [],
  startTime = '',
  completed = false,
}) => {
  const { playerInfo } = useAuth();

  const playersInfo = players.map((player) => ({
    username: player.username,
    avatarId: player.avatarId,
    amountWon: `${player.amountWon} €`,
  }));

  const playerIndex = playersInfo.findIndex(
    (player) => player.username === playerInfo.username
  );
  if (playerIndex !== -1) {
    const player = playersInfo.splice(playerIndex, 1);
    playersInfo.unshift(player[0]);
  }

  return (
    <div className={styles.waitingLobbyContainer}>
      <TournamentInformation
        name={tournament?.name}
        description={tournament?.description}
      />
      {startTime && <CountdownTimer startTime={startTime} />}
      <TournamentParticipants players={playersInfo} completed={completed} />
    </div>
  );
};

export default WaitingLobbyTournaments;
