import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import gsap from 'gsap';
import styles from "./SinglePlayerMode.module.css";
import NumberOfQuestions from "../../molecules/NumberOfQuestions/NumberOfQuestions";
import CounterDownBarNumber from "../../organisms/CounterDownBarNumber/CounterDownBarNumber";
import QuestionAndAnswers from "../../organisms/QuestionAndAnswers/QuestionAndAnswers";
import WelcomePage from "../WelcomePage/WelcomePage";
import DidYouKnow from "../../organisms/DidYouKnow/DidYouKnow";
import BoostersTemplate from "../BoostersTemplate/BoostersTemplate";
import he from 'he'; // converts "απ&#039;το σπιτι" to "απ'το σπιτι".Convert it to apostrophe
import { useAuth } from "../../../AthContext";
import { useGame } from "../../../GameContext";
import { submitAnswerForSpecificQuestion,submitAnswerForSpecificQuestionForGuest,getResultsQuiz,getUnloggedResultsQuiz,getQuotesAndInfosForTheDay } from '../../../apiHelpers';
import Icon from '../../atoms/Icon/Icon';

const SinglePlayerMode = ({ seconds = 10 }) => {
  const navigate = useNavigate();
  const { state } = useGame();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [areResultsDisplayed, setAreResultsDisplayed] = useState(false);
  const [count, setCount] = useState(seconds);
  const [showWelcome, setShowWelcome] = useState(true);
  const [showDidYouKnow, setShowDidYouKnow] = useState(false);
  const [showCongratulations, setShowCongratulations] = useState(false);
  const [showBoosters, setShowBoosters] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [timerSpeed, setTimerSpeed] = useState(1); // Control the speed of the countdown when user gives answer
  const { boostersToPlay, loggedIn, quizId, language } = useAuth();
  const [item, setItem] = useState(null);
  const [title, setTitle] = useState('');
  const iconRef = useRef(null); // Reference for the icon
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null); // Track if the answer is correct


  // in the state, the questionData that fetched, have the following structure
      // playerInternalId:...
      // question1Data:....
      // question2Data:....
      // score1:....
  // so we need to seperate the questions{number}Data
  // also make sure that keeps the same order
  useEffect(() => {
    const fetchedQuestions = Object.keys(state.questionData || {})
      .filter((key) => key.startsWith('question') && key.endsWith('Data')) // Ensures key matches the pattern
      .sort((a, b) => {
        // Extract question numbers from keys and compare them to sort
        const numA = parseInt(a.replace(/^\D+/g, ''), 10); // Removes non-digits and parse
        const numB = parseInt(b.replace(/^\D+/g, ''), 10); // Removes non-digits and parse
        return numA - numB; // Sort by numerical value
      })
      .map((key) => {
        try {
          // Parse each question data string to an object
          return JSON.parse(state.questionData[key]);
        } catch (error) {
          console.error(`Error parsing ${key}:`, error);
          return null; // Return null or an error object as needed
        }
      })
      .filter((question) => question !== null); // Removes any nulls resulting from parse errors

    // console.log("All Parsed Questions in correct order:", fetchedQuestions);

    setQuestions(fetchedQuestions);
  }, [state.questionData]);

  useEffect(() => {
    if (showWelcome) {
      // bring the quotes from that page so will be time to render the fetched data
      getQuotesAndInfosForTheDay(language)
      .then((data) => {
        // handling greek api
        if (language === 'el') {
          let randomItem;
          let title;

          if (data.didyouknow) {
            randomItem = data.didyouknow[Math.floor(Math.random() * data.didyouknow.length)];
            randomItem.content = he.decode(randomItem.content);
            title = 'Ήξερες οτι..';
          } else if (data.quotes) {
            randomItem = data.quotes[Math.floor(Math.random() * data.quotes.length)];
            randomItem.quote = he.decode(randomItem.quote);
            title = 'Κάποτε είπαν..';
          } else if (data.calendar) {
            randomItem = data.calendar[Math.floor(Math.random() * data.calendar.length)];
            randomItem.title = he.decode(randomItem.title);
            title = 'Σαν σήμερα..';
          }
          setItem(randomItem);
          setTitle(title);
        } else {
          // Handling API fetch for other languages
          if (data.thisDayEvent) {
            const randomItem = {
              content: data.thisDayEvent.text, // Use 'text' as the content
              year: data.thisDayEvent.year,
            };
            const title = data.thisDayEvent.title;
            if (title === 'Wusstest du...') {
              setTitle('Wusstest du schon...');
            } else {
              setTitle(title || 'Did You Know..');
            }
            setItem(randomItem);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });

      const welcomeTimer = setTimeout(() => {
        setShowWelcome(false);
        setShowDidYouKnow(true);
      }, 3000);
      return () => clearTimeout(welcomeTimer);
    }
  }, [showWelcome]);

  useEffect(() => {
    if (showDidYouKnow) {
      const didYouKnowTimer = setTimeout(() => {
        setShowDidYouKnow(false);
        goToNextQuestion();
      }, 4000);
      return () => clearTimeout(didYouKnowTimer);
    }
  }, [showDidYouKnow]);

  useEffect(() => {
    if (!showWelcome && !showBoosters) {
      if (count <= 0) {
        if (!selectedAnswer) { 
          const indexOfRenderedQuestion = currentQuestionIndex + 1;
          console.log("user didn't answer");
          loggedIn ? 
          submitAnswerForSpecificQuestion(quizId, indexOfRenderedQuestion, 5) : 
          submitAnswerForSpecificQuestionForGuest(quizId, indexOfRenderedQuestion, 5);
        }
        setAreResultsDisplayed(true);
        const resultTimer = setTimeout(() => {
          goToNextQuestion();
        }, 3000);
        return () => clearTimeout(resultTimer);
      }

      const countdownTimer = setTimeout(() => {
        setCount(count - timerSpeed);
      }, 1000/timerSpeed);

      return () => clearTimeout(countdownTimer);
    }
  }, [count, currentQuestionIndex, showWelcome, timerSpeed]);

  useEffect(() => {
    if (
      !showWelcome &&
      currentQuestionIndex === questions.length &&
      !areResultsDisplayed
    ) {
      setShowCongratulations(true);
    }
  }, [currentQuestionIndex, showWelcome, areResultsDisplayed]);

  // Animate the icon when results are displayed
  useEffect(() => {
    if (areResultsDisplayed) {
      const tl = gsap.timeline();
      tl.fromTo(
        iconRef.current, 
        { y: -100, opacity: 0.5 },  // Start above the screen
        { y: window.innerHeight / 5, opacity: 1, duration: 1, ease: 'power1.out' } // Move to 1/3 of the screen
      )
      .to(
        iconRef.current, 
        { y: -200, opacity: 0.5, duration: 0.3, ease: 'power1.in' }, // Launch faster back up
        "+=1.75" // Delay of 0.5 seconds before launching up
      );
    }
  }, [areResultsDisplayed]);

  const goToNextQuestion = async () => {
    setIsAnswerCorrect(false);
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setCount(seconds);
      setSelectedAnswer('');
      setAreResultsDisplayed(false);
      setTimerSpeed(1);
    } else {
      if (loggedIn) {
        // Only execute the following block if the user is logged in
        try {
          const response = await getResultsQuiz(quizId);
          navigate('/congratulations', {
            state: {
              trophiesWon: response.trophiesWon,
              rightAnswers: response.rightanswers,
              questionsLength: questions.length,
              pointsEarned: response.pointsFromAnswers,
              totalScore: response.trophiesWon,
              completetionBonus: response.completetionBonus,
              perfectionBonus: response.perfectBonus,
            },
          });
        } catch (error) {
          console.error('Error fetching quiz results:', error);
          // Here, you might want to handle the error or navigate with an error state
          navigate('/error', { state: { message: 'Failed to fetch quiz results.' } });
        }
      } else {
        // Execute this block if the user is not logged in
        try {
          const response = await getUnloggedResultsQuiz(quizId);
          navigate('/congratulations', {
            state: {
              rightAnswers: response.rightanswers,
              questionsLength: questions.length,
            },
          });
        } catch (error) {
          console.error('Error fetching quiz results for unlogged user:', error);
          // Navigate to an error page or handle differently according to your app's flow
          navigate('/error', { state: { message: 'Failed to fetch quiz results for guests.' } });
        }
      }
    }
  };

  const handleButtonClick = async (answer, index) => {
    if (selectedAnswer || areResultsDisplayed) return;
    const indexOfRenderedQuestion = currentQuestionIndex + 1
    const indexOfSelectedAnswer = index + 1;
    console.log(indexOfRenderedQuestion,indexOfSelectedAnswer)

    // Submit the answer and determine if it was correct
    let result;
    if (loggedIn) {
      result = await submitAnswerForSpecificQuestion(quizId, indexOfRenderedQuestion, indexOfSelectedAnswer);
    } else {
      result = await submitAnswerForSpecificQuestionForGuest(quizId, indexOfRenderedQuestion, indexOfSelectedAnswer);
    }
    // Check if the answer is correct based on the result returned
    setIsAnswerCorrect(result.isCorrectAnswer); // Assuming result has an 'isCorrect' field

    setSelectedAnswer(answer);
    setTimerSpeed(2.5);

    setTimeout(() => {
      if (count <= 0) {
        setAreResultsDisplayed(true);
      }
    }, (count + 1) * 1000);
  };

  return (
    <>
      {showWelcome && <WelcomePage />}
      {showDidYouKnow && <DidYouKnow item={item} title={title}/>}
      {/* {showBoosters && <BoostersTemplate onOkClick={() => okButtonClicked()} />} */}

      {!showWelcome &&
        !showCongratulations &&
        !showBoosters &&
        !showDidYouKnow &&
        boostersToPlay && (
          <div className={styles.questionAnswersContainer}>
            <NumberOfQuestions
              currentQuestionNumber={currentQuestionIndex}
              totalQuestions={questions.length}
            />
            <CounterDownBarNumber
              seconds={seconds}
              questionIndex={currentQuestionIndex}
            />
            <QuestionAndAnswers
              currentQuestion={questions[currentQuestionIndex]}
              handleButtonClick={handleButtonClick}
              areResultsDisplayed={areResultsDisplayed}
              selectedAnswer={selectedAnswer}
            />
            {/* <BoostersToPlay boosterData={boostersToPlay} /> */}
            {/* Conditionally render the Icon with animation */}
          </div>
      )}
    </>
  );
};

export default SinglePlayerMode;
