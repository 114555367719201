import React, { useEffect, useState } from "react";
import styles from "./BoostersTemplate.module.css";
import BoostersToPlay from "../../organisms/BoostersToPlay/BoostersToPlay";
import BoostersToBuy from "../../organisms/BoostersToBuy/BoostersToBuy";
import PlayButton from "../../molecules/PlayButton/PlayButton";
import ComingSoon from "../../molecules/ComingSoon/ComingSoon"
import Typography from "../../atoms/Typography/Typography";
import { useAuth } from "../../../AthContext";

const boostersToBuyData = [
  {
    boosterIcon: "coin1",
    iconSize: 150,
    boosterText: "Erase the mistake",
  },
  {
    boosterIcon: "coin2",
    iconSize: 150,
    boosterText: "Erase the mistake x2!",
  },
  {
    boosterIcon: "coin3",
    iconSize: 150,
    boosterText: "Erase the mistake x3!",
  },
  {
    boosterIcon: "audience",
    iconSize: 98,
    boosterText: "The audience spoke!",
  },
  {
    boosterIcon: "blurred",
    iconSize: 90,
    boosterText: "Blurred!",
  },
  {
    boosterIcon: "slip",
    iconSize: 116,
    boosterText: "Slip everywhere!",
  },
  {
    boosterIcon: "letters",
    iconSize: 140,
    boosterText: "Anagram!",
  },
  {
    boosterIcon: "mirror",
    iconSize: 122,
    boosterText: "Mirror",
  },
  {
    boosterIcon: "dice",
    iconSize: 127,
    boosterText: "in...luck!",
  },
];

const initialBoostersToPlayData = [{}, {}, {}];

const BoostersTemplate = ({ onOkClick }) => {
  const { isMobile, playerInfo, updatePlayerInfo, updateBoostersToPlay } =
    useAuth();
  const [boostersToPlay, setBoostersToPlay] = useState(
    initialBoostersToPlayData
  );
  const [boostersToPlayCount, setBoostersToPlayCount] = useState(
    countPopulatedElements(initialBoostersToPlayData)
  );

  const addBoosterToPlay = (selectedBooster, index) => {
    if (canAddBooster(selectedBooster)) {
      const emptyIndex = boostersToPlay.findIndex(
        (booster) => !booster || Object.keys(booster).length === 0
      );

      // Only execute if there's an empty slot available
      if (emptyIndex !== -1) {
        const newBoostersToPlay = [...boostersToPlay];
        newBoostersToPlay[emptyIndex] = selectedBooster;

        // Check if the booster has already been decremented
        if (!selectedBooster.decremented) {
          decrementBoosterAmount(selectedBooster, index);
          // Set the flag to true to indicate decrement has happened
          selectedBooster.decremented = true;
        }

        setBoostersToPlay(newBoostersToPlay);
        setBoostersToPlayCount(countPopulatedElements(newBoostersToPlay));
      }
    }
  };

  const canAddBooster = (selectedBooster) => {
    return (
      selectedBooster &&
      !boostersToPlay.some(
        (booster) =>
          booster && booster.boosterText === selectedBooster.boosterText
      )
    );
  };

  const decrementBoosterAmount = (selectedBooster, index) => {
    const updatedBoosters = [...playerInfo.availableBoosters];
    const selectedBoosterIndex = boostersToBuyData.findIndex(
      (boosterData) => boosterData.boosterText === selectedBooster.boosterText
    );

    if (index < 8 && updatedBoosters[selectedBoosterIndex].amount > 0) {
      updatedBoosters[selectedBoosterIndex].amount--;
      updatePlayerInfo({ ...playerInfo, availableBoosters: updatedBoosters });
    }
  };

  const removeBoosterFromPlay = (index) => {
    const removedBooster = boostersToPlay[index];
    const newBoostersToPlay = [...boostersToPlay];
    newBoostersToPlay[index] = {}; // Replace the selected booster with an empty object

    // Find the index of the removed booster in boostersToBuyData
    const removedBoosterIndex = boostersToBuyData.findIndex(
      (booster) => booster.boosterText === removedBooster.boosterText
    );

    // If the removed booster is found, increment its amount
    if (removedBoosterIndex !== -1 && removedBoosterIndex !== 8) {
      const updatedBoosters = [...playerInfo.availableBoosters];
      updatedBoosters[removedBoosterIndex].amount++;
      updatePlayerInfo({ ...playerInfo, availableBoosters: updatedBoosters });
    }

    setBoostersToPlay(newBoostersToPlay);
    boostersToPlayCount > 0 && setBoostersToPlayCount(boostersToPlayCount - 1);
  };

  useEffect(() => {
    return () => {
      // Update the boostersToPlay data in AuthProvider when unmounting
      updateBoostersToPlay(boostersToPlay);
    };
  }, [boostersToPlay, updateBoostersToPlay]);

  return (
    <div className={styles.boosterTemplateContainer}>
      <div className={styles.boostersToPlaySection}>
        <div className={styles.boostersHeader}>
          <Typography color="colorBlack">Choose Booster</Typography>
        </div>
        <BoostersToPlay
          boosterData={boostersToPlay}
          onBoosterSelect={removeBoosterFromPlay}
        />
        <div>
          <Typography
            color="colorBlack"
            fontWeight="font400"
            additionalClass={styles.grey}
            variant={isMobile ? "body2" : "body1"}
          >
            You have selected {boostersToPlayCount}/3
          </Typography>
        </div>
        <PlayButton
          additionalClass="play-now"
          primaryText="Start"
          onClick={onOkClick}
        />
      </div>
      <ComingSoon>
         <BoostersToBuy
        boosterData={boostersToBuyData}
        onBoosterSelect={addBoosterToPlay}
      />
      </ComingSoon>
    </div>
  );
};

const countPopulatedElements = (array) => {
  return array.filter(
    (booster) => booster !== null && Object.keys(booster).length > 0
  ).length;
};

export default BoostersTemplate;
