import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../atoms/Button/Button';
import styles from './NotificationsButtonV2.module.css';
import Typography from '../../atoms/Typography/Typography';
import Icon from '../../atoms/Icon/Icon';

const NotificationsButtonLoggedV2 = ({ text, imageName }) => {
  //======  HANDLE THE SCENARIO OF A WRONG IMAGENAME  ==== //
  // Define an array of allowed image names
  const allowedImageNames = ['coin', 'goldenTrophy'];

  // Check if the provided imageName is valid
  if (imageName && !allowedImageNames.includes(imageName)) {
    console.error(`Invalid imageName: ${imageName}. Use 'coin' or 'goldenTrophy'.`);
    return null; // Or handle the error in an appropriate way
  }
  //======  =======================================  ==== //

  return (
    <Button additionalClass={`${styles['notification-button']} ${styles['shiny-button']}`}>
      {text && (
        <Typography variant="heading3" fontWeight="font500" additionalClass={styles.notificationButtonText}>
          {text}
        </Typography>
      )}
      {imageName && <Icon name={imageName} folder="other_icons" size={32} />}
      {/* The shiny effect */}
      <div className={styles['shiny-effect']}></div>
    </Button>
  );
};

NotificationsButtonLoggedV2.propTypes = {
  text: PropTypes.string,
  imageName: PropTypes.oneOf(['coin', 'goldenTrophy']),
};

export default NotificationsButtonLoggedV2;
