import React from "react";
import Icon from "../../atoms/Icon/Icon";
import Typography from "../../atoms/Typography/Typography";
import { useAuth } from "../../../AthContext";
import styles from "./Panel1v1.module.css"; // Import the CSS module

const Panel1v1 = ({}) => {
  const { isMobile } = useAuth();

  return (
    <div className={styles.container}>
      <Icon
        folder={"quiz_selection"}
        name={"einstein1v1panel"}
        size={isMobile ? 250 : 370}
      />
      <div className={styles.bottomImageContainer}>
        <Icon folder={"quiz_selection"} name={"VS"} size={isMobile ? 55 : 90} />
      </div>
    </div>
  );
};

export default Panel1v1;
