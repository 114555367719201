import React from 'react';
import { useState } from 'react';
import styles from './IngameHeader.module.css';
import Icon from '../../atoms/Icon/Icon';
import { useAuth } from '../../../AthContext';
import Button from '../../atoms/Button/Button';
import Typography from '../../atoms/Typography/Typography';
import ModalWith2Buttons from '../../molecules/ModalWith2Buttons/ModalWith2Buttons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getTickets } from '../../../apiHelpers';

const IngameHeader = ({}) => {
  const { isMobile } = useAuth();
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className={styles.header}>
      <div className={styles.logo}>
        <Icon folder={'logos'} name={'quizdom'} size={isMobile ? 90 : 152} />
      </div>
      {modalVisible && (
        <ModalWith2Buttons
          onClickContainer={() => {
            setModalVisible(false);
          }}
          mainTitle={t('inGameHeader.confirmation')}
          closeRightButtonTitle={t('generic.yes').toUpperCase()}
          closeLeftButtonTitle={t('generic.no').toUpperCase()}
          modalVisible={modalVisible}
          onPressLeft={() => {
            setModalVisible(false);
          }}
          onPressRight={() => {
            navigate('/');
          }}
        />
      )}
      <Button
        additionalClass={isMobile ? styles.buttonSmallRed : styles.buttonRed}
        onClick={() => {
          setModalVisible(true);
        }}
      >
        <Typography variant="heading3" fontWeight="font900">
          {t('inGameHeader.buttonText')}
        </Typography>
      </Button>
    </div>
  );
};

export default IngameHeader;
