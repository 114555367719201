import button_click from "../assets/sounds/button_click.mp3";

export const play = async (url = button_click) => {
  const audio = new Audio(url);
  let areSoundsAllowed = await localStorage.getItem("sounds");
  let language = await localStorage.getItem("language");
  const allowSounds = () => {
    let message = {
      en: "Do you permit 'Quizdom Battle' to play sounds?",
      el: "Επιτρέπεις στο «Quizdom Battle» να παίζει ήχους;",
      de: "Erlauben Sie „Quizdom Battle“, Sounds abzuspielen?",
    };
    let res = window.confirm(
      message[language] ? message[language] : message["en"]
    );
    if (res) {
      localStorage.setItem("sounds", "Allowed");
      audio.play().catch((e) => {
        console.log(e);
      });
      audio.addEventListener("ended", () => audio.pause());
      return () => {
        audio.removeEventListener("ended", () => audio.pause());
      };
    } else {
      localStorage.setItem("sounds", "Not Permitted");
    }
  };

  if (areSoundsAllowed === "Allowed") {
    audio.play().catch((e) => {});
  } else if (!areSoundsAllowed) {
    allowSounds();
  }
};

export const toggleBodyScroll = (disableScroll) => {
  const body = document.body;
  if (disableScroll) {
    body.style.overflow = "hidden"; // Disable scrolling
  } else {
    body.style.overflow = ""; // Enable scrolling
  }
};
