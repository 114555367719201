import React from "react";
import styles from "./WalletButton.module.css";
import Icon from "../../atoms/Icon/Icon";
import Typography from "../../atoms/Typography/Typography";
import Button from "../../atoms/Button/Button";
import { useAuth } from "../../../AthContext";

const WalletButton = ({ leftIcon, rightIcon, text, onClick }) => {
  const { isMobile } = useAuth();
  const HandleClick = () => {
    onClick();
  };
  return (
    <Button additionalClass={styles.walletButton} onClick={HandleClick}>
      {leftIcon && (
        <Icon name={leftIcon} folder="wallet_icons" size={isMobile ? 54 : 64} />
      )}
      <Typography variant={"body1"} fontWeight="font700" color="colorBlack">
        {text}
      </Typography>
    </Button>
  );
};

export default WalletButton;
