import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getUserInfo } from "../../../apiHelpers";
import styles from "./CongratulationsTemplate.module.css";
import CongratulationsImage from "../../molecules/CongratulationsImage/CongratulationsImage";
import CongratulationsBoxUnlogged from "../../organisms/CongratulationsBoxUnlogged/CongratulationsBoxUnlogged";
import { useAuth } from "../../../AthContext";
import CongratulationsBoxLoggedV2 from "../../organisms/CongratulationsBoxLoggedV2/CongratulationsBoxLoggedV2";
import {
  useLaunchConfetti,
  useThumbsUpEffect,
} from "../../../services/animationHooks";

const CongratulationsTemplate = () => {
  const { loggedIn } = useAuth();
  const location = useLocation();
  const {
    trophiesWon,
    questionsLength,
    rightAnswers,
    pointsEarned,
    totalScore,
    completetionBonus,
    perfectionBonus,
  } = location.state || {}; // Ensure fallback to an empty object
  const [userID, setUserId] = useState();
  const pollfishApiToken = "3bbca6c9-c5cf-49c7-a865-4cee6ce45757";

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const userInfo = await getUserInfo();
        setUserId(userInfo.internalId);
      } catch (error) {
        console.error("Error fetching user info", error);
      }
    };
    fetchUserId();
  }, []);

  const successPercentage = rightAnswers / questionsLength;
  const launchConfetti = useLaunchConfetti;
  const thumbsUpEffect = useThumbsUpEffect;

  let quizEvaluationResults;
  if (successPercentage >= 0.8) {
    quizEvaluationResults = "perfect";
    launchConfetti();
  } else if (successPercentage > 0.6 && successPercentage < 1) {
    quizEvaluationResults = "great";
    thumbsUpEffect();
  } else if (successPercentage > 0.3 && successPercentage <= 0.6) {
    quizEvaluationResults = "well";
    thumbsUpEffect();
  } else if (successPercentage <= 0.3) {
    quizEvaluationResults = "fail";
  }

  return (
    <div className={`${styles["congratulations-template"]}`}>
      <div
        className={`${styles["congratulations-image"]} ${
          quizEvaluationResults === "fail" ? styles["shake"] : ""
        }`}
      >
        <CongratulationsImage quizEvaluationResults={quizEvaluationResults} />
      </div>
      <div className={`${styles["congratulationsBox"]}`}>
        {loggedIn ? (
          <CongratulationsBoxLoggedV2
            quizEvaluationResults={quizEvaluationResults}
            questionsLength={questionsLength}
            rightAnswers={rightAnswers}
            pointsEarned={pointsEarned}
            totalScore={totalScore}
            completetionBonus={completetionBonus}
            perfectionBonus={perfectionBonus}
            userID={userID}
            pollfishApiToken={pollfishApiToken}
          />
        ) : (
          <CongratulationsBoxUnlogged trophiesWon={trophiesWon} />
        )}
      </div>
    </div>
  );
};

export default CongratulationsTemplate;
