import React from "react";
import Typography from "../../atoms/Typography/Typography.js";
import Icon from "../../atoms/Icon/Icon.js";
import TransactionButton from "../StoreTransactionButton/StoreTransactionButton.js";
import StoreValueAddon from "../StoreValueAddon/StoreValueAddon.js";
import StoreBonusAddOn from "../StoreBonusAddOn/StoreBonusAddOn.js";
import styles from "./StoreItemCard.module.css";

const StoreItemCard = ({
  cardBackground,
  additionalClass,
  imageName,
  quantity,
  price,
  priceType,
  addOn,
  bonusNum,
  androidProductId
}) => {
  console.log(androidProductId);
  const cardClasses = [
    styles.storeItemCard,
    cardBackground,
    addOn ? styles.storeItemCardEnableRedBoarder : "", // Add styles only if addOn is true
  ].join(" ");
  return (
    <div className={cardClasses}>
      {addOn && (
        <div className={styles.storeItemCardAddOn}>
          <StoreValueAddon
            background={"#FFFFFF"}
            textColor={"colorRed"}
            text={addOn.text}
          />
        </div>
      )}
      {imageName && (
        <Icon
          name={imageName}
          folder="store"
          size={"200"}
          additionalClass={styles.storeItemIcon}
        ></Icon>
      )}
      <div className={styles.storeItemCardText}>
        <Typography color="colorWhite" fontWeight="font900" variant="heading3">
          {quantity}
        </Typography>
        {bonusNum && <StoreBonusAddOn imageName={bonusNum.bonusImage} />}
      </div>
      <TransactionButton
        text={`${price} ${priceType}`}
        additionalClass={additionalClass}
        androidProductId={androidProductId}
      />
    </div>
  );
};

export default StoreItemCard;
