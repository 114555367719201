import React, { useEffect } from "react";
import { play, toggleBodyScroll } from "../../../services/helpers";
import styles from "./ModalWith2Buttons.module.css";
import PlayButton from "../PlayButton/PlayButton";
import { useAuth } from "../../../AthContext";
import Button from "../../atoms/Button/Button";
import Typography from "../../atoms/Typography/Typography";

const ModalWith2Buttons = ({
  mainTitle,
  closeLeftButtonTitle,
  closeRightButtonTitle,
  onPressLeft,
  onPressRight,
  children,
  onClickContainer = () => {},
}) => {
  const { isMobile } = useAuth();

  useEffect(() => {
    toggleBodyScroll(true); // Run on mount to disable scrolling
    return () => {
      toggleBodyScroll(false); // Run on unmount to enable scrolling
    };
  }, []);

  return (
    <div
      className={styles.modalWith2ButtonsContainer}
      onClick={() => {
        onClickContainer();
      }}
    >
      <div className={styles.modalWith2ButtonsDiv}>
        <div className={styles.modalWith2ButtonsInnerDiv}>
          <Typography
            variant={"body1"}
            color="colorBlack"
            fontWeight={"font400"}
          >
            {mainTitle}
          </Typography>
          {children}
        </div>
        <div className={styles.modalWith2ButtonsButtonsWrap}>
          <Button
            additionalClass={
              isMobile ? styles["modal-button-small"] : styles["modal-button"]
            }
            onClick={() => {
              onPressLeft();
            }}
          >
            <Typography>{closeLeftButtonTitle}</Typography>
          </Button>
          <Button
            additionalClass={
              isMobile
                ? styles["modal-button-small-red"]
                : styles["modal-button-red"]
            }
            onClick={() => {
              onPressRight();
            }}
          >
            <Typography>{closeRightButtonTitle}</Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ModalWith2Buttons;
