import React from 'react';
import styles from './ChatPerson.module.css';
import Icon from '../../atoms/Icon/Icon';
import Typography from '../../atoms/Typography/Typography';

const ChatPerson = ({ avatarId, username, message, timeStamp, isDiscussionSelected, onClick }) => {
  const handleClick = () => onClick();

  const wrapperClass = isDiscussionSelected ? styles.chattingPersonWrapperActive : styles.chattingPersonWrapper;

  const formattedTimestamp = new Date(timeStamp).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });

  return (
    <div className={wrapperClass} onClick={handleClick}>
      <div className={styles.chattingPersonInfos}>
        <div className={styles.avatar}>
          <Icon name={`avatar-${avatarId}`} folder="avatars" size={48} />
        </div>
        <div className={styles.nameAndMessage}>
          <Typography variant="body1" color="colorBlack" additionalClass={styles.userNameText}>
            {username}
          </Typography>
          {message && (
            <Typography variant="caption" fontWeight="font400" color="colorBlack" additionalClass={styles.messageText}>
              {message}
            </Typography>
          )}
        </div>
      </div>
      <Typography variant="caption" fontWeight="font400" color="colorBlack" additionalClass={styles.timestamp}>
        {formattedTimestamp}
      </Typography>
    </div>
  );
};

export default ChatPerson;
