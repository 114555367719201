import React, { useEffect, useRef } from 'react';
import styles from './AvatarEditModule.module.css';
import { useAuth } from '../../../AthContext';
import Icon from '../../atoms/Icon/Icon';

const AvatarEditModule = ({ selectedAvatarOnEdit, setSelectedAvatarOnEdit }) => {
  const { playerInfo } = useAuth();
  const avatars = [
    { name: 'avatar-1', id: '1' },
    { name: 'avatar-2', id: '2' },
    { name: 'avatar-3', id: '3' },
    { name: 'avatar-4', id: '4' },
    { name: 'avatar-5', id: '5' },
    { name: 'avatar-6', id: '6' },
    { name: 'avatar-7', id: '7' },
    { name: 'avatar-8', id: '8' },
    { name: 'avatar-9', id: '9' },
    { name: 'avatar-10', id: '10' },
    { name: 'avatar-11', id: '11' },
    { name: 'avatar-12', id: '12' },
    { name: 'avatar-13', id: '13' },
    { name: 'avatar-14', id: '14' },
    { name: 'avatar-15', id: '15' },
    { name: 'avatar-16', id: '16' },
    { name: 'avatar-17', id: '17' },
    { name: 'avatar-18', id: '18' },
    { name: 'avatar-19', id: '19' },
    { name: 'avatar-20', id: '20' },
    { name: 'avatar-21', id: '21' },
    { name: 'avatar-22', id: '22' },
    { name: 'avatar-23', id: '23' },
    { name: 'avatar-24', id: '24' },
    { name: 'avatar-25', id: '25' },
    { name: 'avatar-26', id: '26' },
    { name: 'avatar-27', id: '27' },
    { name: 'avatar-28', id: '28' },
    { name: 'avatar-29', id: '29' },
    { name: 'avatar-30', id: '30' },
    { name: 'avatar-31', id: '31' },
    { name: 'avatar-32', id: '32' },
    { name: 'avatar-33', id: '33' },
    { name: 'avatar-34', id: '34' },
    { name: 'avatar-35', id: '35' },
    { name: 'avatar-36', id: '36' },
    { name: 'avatar-37', id: '37' },
    { name: 'avatar-38', id: '38' },
    { name: 'avatar-39', id: '39' },
    { name: 'avatar-40', id: '40' },
    { name: 'avatar-41', id: '41' },
    { name: 'avatar-42', id: '42' },
    { name: 'avatar-43', id: '43' },
    { name: 'avatar-44', id: '44' },
    { name: 'avatar-45', id: '45' },
    { name: 'avatar-46', id: '46' },
    { name: 'avatar-47', id: '47' },
    { name: 'avatar-48', id: '48' },
    { name: 'avatar-49', id: '49' },
    { name: 'avatar-50', id: '50' },
    { name: 'avatar-51', id: '51' },
    { name: 'avatar-52', id: '52' },
  ];

  const avatarRefs = useRef({});

  const ownedAvatars = playerInfo.ownedAvatars.map((ownedAvatarNickname) => {
    const avatar = avatars.find((avatar) => avatar.id === ownedAvatarNickname.toString()); // Find the avatar in the avatars array that matches the ownedAvatarNickname
    return avatar ? { name: avatar.name, id: avatar.id } : { name: 'Unknown Avatar', id: 'Unknown ID' }; // Return an object with the avatar's name and id or a placeholder if not found
  });

  const isSelectedAvatar = (avatar) => {
    return selectedAvatarOnEdit && selectedAvatarOnEdit.id === avatar.id;
  };

  const handleAvatarClick = (avatar) => {
    setSelectedAvatarOnEdit(avatar);
  };

  // Scroll to the selected avatar if it exists and is rendered
  useEffect(() => {
    const timer = setTimeout(() => {
      if (selectedAvatarOnEdit && avatarRefs.current[selectedAvatarOnEdit.id]) {
        avatarRefs.current[selectedAvatarOnEdit.id].scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    }, 500); // adjust the timeout as needed based on your app's behavior
  
    return () => clearTimeout(timer); // cleanup the timer
  }, [selectedAvatarOnEdit]);

  console.log(ownedAvatars)

  return (
    <>
      {ownedAvatars.map((avatar) => (
        <div key={avatar.id} ref={(el) => (avatarRefs.current[avatar.id] = el)} className={styles.avatarContainer}>
          <Icon
            onClick={() => handleAvatarClick(avatar)}
            name={avatar.name}
            folder="avatars"
            size={110}
            additionalClass={`${isSelectedAvatar(avatar) ? styles.selectedAvatarToEdit : styles.avatarToEdit}`}
          />
          {isSelectedAvatar(avatar) && (
            <div className={styles.checkIconBackground}>
              <Icon name="check" folder="other_icons" size={30} className={styles.checkIcon} additionalClass={styles.checkIcon}/>
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default AvatarEditModule;
