import React, { useState, useEffect } from "react";
import styles from "./SpinWheelTemplate.module.css";
import SpinWheel from "../../organisms/SpinWheel/SpinWheel";
import PlayButton from "../../molecules/PlayButton/PlayButton";
import SpinWheelText from "../../molecules/SpinWheelText/SpinWheelText";
import { useNavigate } from "react-router-dom";
import Typography from "../../atoms/Typography/Typography";
import FancySpinWheel from "../../organisms/FancySpinWheel/FancySpinWheel";
import { useTranslation } from "react-i18next";

const SpinWheelTemplate = () => {
  const { t } = useTranslation();
  const [rotationDegree, setRotationDegree] = useState(0);
  const [isSpinning, setIsSpinning] = useState(false);
  const [remainingSpins, setRemainingSpins] = useState(5);
  const [buttonClass, setButtonClass] = useState("play-now");
  const [buttonText, setButtonText] = useState(t("spinWheel.buttonSpin"));
  const [playerInfo, setPlayerInfo] = useState(null); // State to hold player info
  const [showNoSpinsMessage, setShowNoSpinsMessage] = useState(false);
  const [hasNavigatedAway, setHasNavigatedAway] = useState(false);

  const navigate = useNavigate();

  // useEffect(() => {
  //   // Load playerInfo from localStorage on component mount
  //   const storedPlayerInfo = JSON.parse(localStorage.getItem("playerInfo"));
  //   if (storedPlayerInfo) {
  //     setPlayerInfo(storedPlayerInfo);
  //     setRemainingSpins(storedPlayerInfo.availableSpins);

  //     // Check if availableSpins is 0 and set the initial state accordingly
  //     if (storedPlayerInfo.availableSpins === 0) {
  //       setButtonText("OK");
  //       setShowNoSpinsMessage(true);
  //     }
  //   }
  // }, []);

  const handleSpinClick = () => {
    if (buttonText === "OK" && !hasNavigatedAway) {
      // Handle the case when the button text is "OK"
      setIsSpinning(true);
      setShowNoSpinsMessage(true);

      // Update availableSpins in localStorage
      const updatedPlayerInfo = {
        ...playerInfo,
        availableSpins: 0,
      };
      localStorage.setItem("playerInfo", JSON.stringify(updatedPlayerInfo));

      // Navigate immediately without changing the button text
      setHasNavigatedAway(true);
      navigate("/store");
    } else if (remainingSpins > 0) {
      // Handle the first click logic for spins
      const randomDegree = Math.floor(Math.random() * (800 - 400 + 1)) + 1000;
      setRotationDegree((prevDegree) => prevDegree + randomDegree);
      setIsSpinning(true);
      setRemainingSpins((prevRemainingSpins) => prevRemainingSpins - 1);

      // Update button class and text
      setButtonClass("deactivated");
      setButtonText(t("spinWheel.buttonSpinning"));

      setTimeout(() => {
        setIsSpinning(false);
        setButtonClass("play-now");
        setButtonText("Spin");

        // Update availableSpins in localStorage
        const updatedPlayerInfo = {
          ...playerInfo,
          availableSpins: remainingSpins - 1,
        };
        localStorage.setItem("playerInfo", JSON.stringify(updatedPlayerInfo));
      }, 3000);

      if (remainingSpins === 1) {
        setShowNoSpinsMessage(true);
      }
    } else if (!hasNavigatedAway) {
      // Handle the case when remainingSpins is 0
      setIsSpinning(true);
      setShowNoSpinsMessage(true);

      // Update button text to "OK" on the final spin
      setButtonText("OK");

      // Update availableSpins in localStorage
      const updatedPlayerInfo = {
        ...playerInfo,
        availableSpins: 0,
      };
      localStorage.setItem("playerInfo", JSON.stringify(updatedPlayerInfo));

      // Show message and navigate after 3 seconds
      setTimeout(() => {
        setIsSpinning(false);
        setHasNavigatedAway(true);
        navigate("/store");
      }, 3000);
    }
  };

  return (
    <div className={styles["spin-wheel-template"]}>
      <SpinWheelText remainingSpins={remainingSpins} />
      <FancySpinWheel isSpinning={isSpinning} rotationDegree={rotationDegree} />
      {/* <SpinWheel isSpinning={isSpinning} rotationDegree={rotationDegree} /> */}
      {remainingSpins === 0 && showNoSpinsMessage && (
        <div className={styles.message}>
          <Typography variant={"heading3"} color="colorBlack">
            {t("spinWheel.noMore")}
          </Typography>
        </div>
      )}
      <PlayButton
        primaryText={buttonText}
        additionalClass={buttonClass}
        onClick={handleSpinClick}
      />
    </div>
  );
};

export default SpinWheelTemplate;
