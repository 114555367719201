import React from 'react';
import styles from './DisqualificationBox.module.css';
import Typography from '../../atoms/Typography/Typography';
import { useAuth } from '../../../AthContext';
import { useTranslation } from 'react-i18next';

const DisqualificationBox = ({ disqualified }) => {
  const { isMobile } = useAuth();
  const { t } = useTranslation();
  return (
    <div
      className={`${styles.box} ${disqualified ? styles.red : styles.green}`}
    >
      <Typography
        variant="heading3"
        color="colorWhite"
        fontWeight="font700"
        additionalClass={isMobile ? styles.mobileText : ''}
      >
        {disqualified
          ? t('tourInfo.disqualified.true')
          : t('tourInfo.disqualified.false')}
      </Typography>
    </div>
  );
};

export default DisqualificationBox;
