import React, { createContext, useContext, useState, useEffect } from "react";
import {
  connectSocket,
  disconnectSocket,
  emitInit,
  emitLogout,
  emitNewGameVersus,
  socket,
} from "./services/sockets";
import { useTranslation } from "react-i18next";
import axios from "axios";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { t } = useTranslation();
  const storedLoggedIn = localStorage.getItem("loggedIn") === "true";
  const storedPlayerInfo = JSON.parse(localStorage.getItem("userInfo"));
  const lang = localStorage.getItem("language")
    ? JSON.parse(localStorage.getItem("language"))
    : "en";
  const playingStatus = localStorage.getItem("playingModeStatus");
  const storedNewMessageNotification =
    localStorage.getItem("newMessageNotification") === "true";
  const [sound, setSound] = useState(true);
  const [loggedIn, setLoggedIn] = useState(storedLoggedIn);
  const [playerInfo, setPlayerInfo] = useState(storedPlayerInfo);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [language, setLanguage] = useState(lang);
  const [isUserAboutToPlay, setIsUserAboutToPlay] = useState(playingStatus);
  const [boostersToPlay, setBoostersToPlay] = useState([]);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [quizId, setQuizId] = useState("");
  const [userWantsToBuyItem, setUserWantsToBuyItem] = useState(false);
  const [newMessageNotification, setNewMessageNotification] = useState(
    storedNewMessageNotification
  );

  const [formData, setFormData] = useState({
    type: "email",
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    agreeToTerms: false,
    isOver18: false,
    avatar: "",
    avatarId: "",
    referralCode: "your_referral_code",
  });

  // Function to update form data
  const updateFormData = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const updateLeaderboardData = (data) => {
    // Extract the leaderboardList and userData from data object
    const otherUsersLeaderboard = data.leaderboardList;
    // Filter the banned player list from the leaderboard
    const bannedPlayers = new Set(["‎‎‎‎‎‎‎"]);
    const updatedLeaderboard = otherUsersLeaderboard.filter(
      (user) => !bannedPlayers.has(user.username)
    );
    // Update positions in the updated leaderboard
    updatedLeaderboard.forEach((user, index) => {
      user.position = index + 1; // Update position based on filtered order
    });
    const mySelfLeaderboard = data.userData;

    const leaderboardCombination = [mySelfLeaderboard, ...updatedLeaderboard];

    setLeaderboardData(leaderboardCombination);
  };

  // Function to handle form input change
  const handleInputChange = (name, value) => {
    updateFormData(name, value);
  };
  // Function to update avatar in form data
  const handleAvatarSelect = (avatar) => {
    setFormData((prevData) => ({
      ...prevData,
      avatar: avatar.name,
      avatarId: avatar.id,
    }));
  };
  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    // Your form submission logic here...
  };

  // Function to update playerInfo within context
  const updatePlayerInfo = (updatedInfo) => {
    setPlayerInfo((currentInfo) => ({ ...currentInfo, ...updatedInfo }));
  };

  // function where sets the value 'isUserAboutToPlay' true or false
  const setPlayingModeStatus = (boolean) => {
    setIsUserAboutToPlay(boolean);
  };

  const handleFacebookLogin = async (response, token) => {
    const { email, id } = response;
    try {
      const loginResponse = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/user/login`,
        {
          type: "facebook",
          email: email,
          id: id,
        }
      );
      // Extract token and user id from login response
      const { token, id: id2 } = loginResponse.data;
      console.log("token:", token, "id:", id2);

      // Store token and id in local storage
      localStorage.setItem("token", token);
      localStorage.setItem("id", id2);
      console.log("login response:", loginResponse);
      return { email, id, token, errorCode: null }; // Include Google OAuth token here
    } catch (loginError) {
      console.error("Error during backend login:", loginError);
      // Return user data and error code directly without throwing an error
      console.log(token);
      return {
        email,
        id,
        token,
        errorCode: loginError.response ? loginError.response.data.code : null,
      };
    }
  };

  const handleGoogleLogin = async (tokenResponse) => {
    try {
      const token = tokenResponse.access_token;
      console.log("token", token);
      const language = localStorage.getItem("language");
      const defaultConfig = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          lang: language,
        },
      };
      const userInfoResponse = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const { email, sub } = userInfoResponse.data;

      try {
        const loginResponse = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/user/login`,
          {
            type: "google",
            email: email,
            id: sub,
          }
        );
        // Extract token and user id from login response
        const { token, id } = loginResponse.data;
        console.log("token:", token, "id:", id);

        // Store token and id in local storage
        localStorage.setItem("token", token);
        localStorage.setItem("id", id);
        console.log("login response:", loginResponse);
        return { email, sub, token, errorCode: null }; // Include Google OAuth token here
      } catch (loginError) {
        console.error("Error during backend login:", loginError);
        // Return user data and error code directly without throwing an error
        return {
          email,
          sub,
          token,
          errorCode: loginError.response ? loginError.response.data.code : null,
        };
      }

      // Return user data if login was successful without any specific error code
      return { email, sub, errorCode: null };
    } catch (error) {
      console.error("Error decoding token or fetching user info:", error);
      return null; // Indicate an unrecoverable error occurred
    }
  };

  const handleAppleLogin = async ({ code, idToken }) => {
    console.log(
      `4. handleAppleLogin V2 started. Code is ${code} and idToken is ${idToken}`
    );
    try {
      try {
        const loginResponse = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/user/login`,
          {
            type: "apple",
            id: code, // Correct parameter passing
            token: idToken, // Ensure idToken matches expected key
          }
        );

        // Rename to avoid conflict with function parameters
        const { token: responseToken } = loginResponse.data;
        console.log("Response token:", responseToken);

        // Saving to local storage
        localStorage.setItem("token", responseToken);

        return { token: responseToken, errorCode: null };
      } catch (loginError) {
        console.error("Error during backend login:", loginError);
        const type = "apple";
        // Return user data and error code directly without throwing an error
        return {
          // email,
          code,
          idToken,
          type,
          errorCode: loginError.response ? loginError.response.data.code : null,
        };
      }
    } catch (error) {
      console.error("Error during Apple login:", error);

      return {
        code: null,
        id_token: null,
        errorCode: error.response ? error.response.data.code : null,
      };
    }
  };

  const translateText = (text) => {
    return t(text);
  };

  const setSoundStatus = (boolean) => {
    setSound(boolean);
  };

  const login = async (playerData) => {
    try {
      // Connect socket and initialize
      //connectSocket()

      // Set authentication state and player information
      setLoggedIn(true);
      setPlayerInfo(playerData);
      //await emitInit()
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  const logout = async () => {
    try {
      setLoggedIn(false);
      setPlayerInfo(null);
      localStorage.clear();

      const acknowledgement = await emitLogout();

      // if (acknowledgement === "success") {
      //   disconnectSocket();
      // } else {
      //   console.error("Logout failed:", acknowledgement);
      // }
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const startNewGame = () => {
    emitNewGameVersus((response) => {
      console.log("New game initiated:", response);
    });
  };

  const updateBoostersToPlay = (boosters) => {
    setBoostersToPlay(boosters);
  };

  useEffect(() => {
    localStorage.setItem("loggedIn", loggedIn.toString());
    localStorage.setItem("playerInfo", JSON.stringify(playerInfo));
    localStorage.setItem("language", JSON.stringify(language));
    localStorage.setItem("sound", JSON.stringify(sound));
    localStorage.setItem(
      "playingModeStatus",
      JSON.stringify(isUserAboutToPlay)
    );
    localStorage.setItem("boostersToPlay", JSON.stringify(boostersToPlay));
    localStorage.setItem(
      "newMessageNotification",
      newMessageNotification.toString()
    ); // Save to localStorage
  }, [
    loggedIn,
    playerInfo,
    language,
    isUserAboutToPlay,
    boostersToPlay,
    sound,
    newMessageNotification,
  ]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        playerInfo,
        login,
        logout,
        isMobile,
        language,
        setLanguage,
        setPlayingModeStatus,
        isUserAboutToPlay,
        updatePlayerInfo,
        startNewGame,
        updateBoostersToPlay,
        sound,
        setSoundStatus,
        translateText,
        leaderboardData,
        updateLeaderboardData,
        formData,
        boostersToPlay,
        handleInputChange,
        handleSubmit,
        handleGoogleLogin,
        handleAppleLogin,
        setFormData,
        handleAvatarSelect,
        handleFacebookLogin,
        quizId,
        setQuizId,
        userWantsToBuyItem,
        setUserWantsToBuyItem,
        newMessageNotification,
        setNewMessageNotification,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
