import React, { useEffect, useState, useRef } from 'react';
import styles from './DidYouKnow.module.css';
import DidYouKnowImage from '../../molecules/DidYouKnowImage/DidYouKnowImage';
import DidYouKnowBox from '../../molecules/DidYouKnowBox/DidYouKnowBox';
import LoadingSpinner from '../../molecules/LoadingSpinner/LoadingSpinner';


const DidYouKnow = ({item,title}) => {

  return (
    <div className={styles['didYouKnow-container']}>
      <div className={styles['didYouKnow-image']}>
        <DidYouKnowImage />
      </div>
      {item && (
        <div className={styles['didYouKnow-box']}>
          <DidYouKnowBox item={item} title={title} />
        </div>
      )}
      <LoadingSpinner />
    </div>
  );
};

export default DidYouKnow;
