import React from "react";
import styles from "./SectionProfileInventory.module.css";
import CardProfileInventory from "../../molecules/CardProfileInventory/CardProfileInventory";

const SectionProfileInventory = ({ playerInfo }) => {
  const availableProfileItems = playerInfo;

  const profileData = [
    {
      id: 1,
      itemName: "ticket",
      itemText: "Available Tournament Tickets",
      amount: availableProfileItems.availableTickets,
    },
    {
      id: 2,
      itemName: "coin",
      itemText: "Available Coins",
      amount: availableProfileItems.coins,
    },
  ];

  return (
    <div className={styles.container}>
      {profileData?.map(({ id, itemName, itemText, amount }) => (
        <CardProfileInventory
          key={id}
          itemName={itemName}
          itemText={itemText}
          amount={amount}
        />
      ))}
    </div>
  );
};

export default SectionProfileInventory;
