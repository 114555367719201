import React, { useEffect } from "react";
import { toggleBodyScroll } from "../../../services/helpers";
import styles from "./ModalWith1Button.module.css";
import { useAuth } from "../../../AthContext";
import Button from "../../atoms/Button/Button";
import Typography from "../../atoms/Typography/Typography";

const ModalWith1Button = ({
  mainTitle,
  secondaryTitle,
  closeButtonTitle,
  onClick = () => {},
}) => {
  const { isMobile } = useAuth();

  useEffect(() => {
    toggleBodyScroll(true); // Run on mount to disable scrolling
    return () => {
      toggleBodyScroll(false); // Run on unmount to enable scrolling
    };
  }, []);
  return (
    <div className={styles.modalWith1ButtonContainer}>
      <div className={styles.modalWith1ButtonDiv}>
        <div className={styles.modalWith1ButtonInnerDiv}>
          <Typography
            variant={"body2"}
            color="colorBlack"
            fontWeight={"font900"}
          >
            {mainTitle}
          </Typography>
          <Typography
            variant={"body1"}
            color="colorBlack"
            fontWeight={"font400"}
          >
            {secondaryTitle}
          </Typography>
        </div>
        <div className={styles.modalWith1ButtonButtonWrap}>
          <Button
            additionalClass={styles.modalButtonBlue}
            onClick={() => {
              onClick();
            }}
          >
            <Typography>{closeButtonTitle}</Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ModalWith1Button;
