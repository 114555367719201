// Carousel.js
import React, { useEffect, useState } from "react";
import styles from "./Carousel.module.css";
import DesktopBanner from "../DesktopBanner/DesktopBanner";
import { useTranslation } from "react-i18next";

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { t } = useTranslation();
  const [touchPosition, setTouchPosition] = useState(null);

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  const desktopBannerOptions = [
    {
      backgroundImage: "desktopBanner1",
      einsteinImage: "illustration_4",
      title:(
        //"banner1" was replaced with "summerBanner" temporarly for the summer. 
        <>
        {t("mainNav.homeTab.summerBanner.title1")} {t("mainNav.homeTab.summerBanner.title2")} <span>{t("mainNav.homeTab.summerBanner.title3")}&nbsp;{t("mainNav.homeTab.summerBanner.title4")}</span>
        </>
      ),
      subTitle: t("mainNav.homeTab.banner1.subTitle")
    },
    {
      backgroundImage: 'desktopBanner2',
      einsteinImage: 'illustration_20',
      title: (
        <>
          <span>{t('mainNav.homeTab.banner2.title1')}</span> {t('mainNav.homeTab.banner2.title2')} <span>{t('mainNav.homeTab.banner2.title3')}</span>
        </>
      ),
      subTitle: (
        <>
          {t('mainNav.homeTab.banner2.subTitle1')} <b>{t('mainNav.homeTab.banner2.subTitle2')} </b>
          {t('mainNav.homeTab.banner2.subTitle3')}
        </>
      ),
    },
    {
      backgroundImage: "desktopBanner3",
      einsteinImage: "illustration_21",
      title:(
        <>
        <span>{t("mainNav.homeTab.banner3.title1")}</span> {t("mainNav.homeTab.banner3.title2")}
        </>
      ),
      subTitle: (
        <>
          {t("mainNav.homeTab.banner3.subTitle1")} <br/> <b>{t("mainNav.homeTab.banner3.subTitle2")}</b>
        </>
      )
    }
  ];

  // Array of static custom components
  const customComponents = desktopBannerOptions.map((item, index) => (
   <DesktopBanner
    key={index}
    backgroundImage={item.backgroundImage}
    einsteinImage={item.einsteinImage}
    title={item.title}
    subTitle={item.subTitle}
  />
  ));

  // Automatic sliding every 7 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      const nextIndex = (currentIndex + 1) % customComponents.length;
      goToSlide(nextIndex);
    }, 10000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [currentIndex, customComponents.length]);

  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      goToSlide((currentIndex + 1) % customComponents.length);
    } else if (diff < -5) {
      const previousIndex = (currentIndex - 1 + customComponents.length) % customComponents.length;
      goToSlide(previousIndex);
    }

    setTouchPosition(null); // reset touch position
  };

  return (
    <div className={styles.carousel} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
      <div className={styles['carousel-container']} style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {customComponents.map((component, index) => (
          <div key={index} className={styles.slide}>
            {index === currentIndex && component}
          </div>
        ))}
      </div>
      <div className={styles.dots}>
        {customComponents.map((_, index) => (
          <span
            key={index}
            className={index === currentIndex ? styles.dotActive : styles.dot}
            onClick={() => goToSlide(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
