import React from "react";
import styles from "./PvPmodeResultsTemplate.module.css";
import CongratulationsImage from "../../molecules/CongratulationsImage/CongratulationsImage";
import CongratulationsBoxPvPmode from "../../organisms/CongratulationsBoxPvPmode/CongratulationsBoxPvPmode";

const PvPmodeResultsTemplate = ({ results }) => {
  // Determine Winner
  const quizEvaluationResults = results.userWon ? "perfect" : "fail";
  // Determine reason for results
  const reasonForResult =
    results.user.score === results.opponent.score
      ? results.userWon
        ? "faster"
        : "slow"
      : null;

  return (
    <div className={`${styles["PvPmodeResultsTemplate"]}`}>
      <div>
        <CongratulationsImage quizEvaluationResults={quizEvaluationResults} />
      </div>
      <div className={`${styles["PvPmodeResultsBox"]}`}>
        <CongratulationsBoxPvPmode
          quizEvaluationResults={quizEvaluationResults}
          reason={reasonForResult}
          userCorrectAnswers={results.user.score}
          opponentCorrectAnswer={results.opponent.score}
          userPoints={results.user.trophies}
          questionPoints={
            results.user.trophiesWon -
            results.user.completionBonus -
            results.user.winnerBonus
          }
          completionBonus={results.user.completionBonus}
          winnerBonus={results.user.winnerBonus}
        />
      </div>
    </div>
  );
};

export default PvPmodeResultsTemplate;
