import React, { useState, useEffect } from 'react';
import styles from "./MobileMenu.module.css";
import { Link, useLocation } from "react-router-dom";
import Icon from "../../atoms/Icon/Icon";
import Typography from "../../atoms/Typography/Typography";
import { useAuth } from "../../../AthContext";
import { socket } from '../../../services/sockets'; // Import your socket instance

const MobileMenu = () => {
  const { translateText, newMessageNotification, setNewMessageNotification } = useAuth();
  const location = useLocation(); // Hook to get the current location object
  const [selectedIcon, setSelectedIcon] = useState(null);


  useEffect(() => {
    // gives the url items seperated by "/"
    // https://localhost:3000/home/text/user will give ["home","text","user"]
    const pathSegments = location.pathname.split("/").filter(Boolean); // gives the url items seperated by "/"
    const firstPath = pathSegments.length === 0 ? "home" : pathSegments[0];
    setSelectedIcon(firstPath);
  }, [location]); // Re-run this effect if the location changes


  const panelIconData = [
    {
      name: "home",
      folder: "menu_icons",
      linkTo: "/",
    },
    {
      name: "quiz",
      folder: "menu_icons",
      linkTo: "/quiz",
    },
    {
      name: "leaderboards",
      folder: "menu_icons",
      linkTo: "leaderboards",
    },
    {
      name: "wallet",
      folder: "menu_icons",
      linkTo: "wallet",
    },
    {
      name: "store",
      folder: "menu_icons",
      linkTo: "store",
    },
    {
      name: "friends",
      folder: "menu_icons",
      linkTo: "friends",
    },
    {
      name: "profile",
      folder: "menu_icons",
      linkTo: "profile",
    },
  ];

  const translateIconTitle = (index) => {
    switch (index) {
      case 0:
        return translateText("mainNav.homeTab.title");
      case 1:
        return translateText("mainNav.quizTab.title");
      case 2:
        return translateText("mainNav.leaderboardTab.title");
      case 3:
        return translateText("mainNav.walletTab.title");
      case 4:
        return translateText("mainNav.storeTab.title");
      case 5:
        return translateText("mainNav.friendsTab.friendsScreen.title");
      case 6:
        return translateText("mainNav.profileTab.title");
      default:
        return "";
    }
  };
  const handleIconClick = (iconName) => {
    console.log(`Clicked on ${iconName}`);
    setSelectedIcon(iconName === selectedIcon ? null : iconName);
  };

  const convertIconName = (name) => {
    return name.charAt(0).toUpperCase() + name.slice(1).replace(/_/g, " ");
  };

  useEffect(() => {
    // Handle new chat message received via socket
    const handleNewChatMessage = () => {
      setNewMessageNotification(true); // Update the context state
    };

    socket.on("new chat message", handleNewChatMessage);

    return () => {
      socket.off("new chat message", handleNewChatMessage);
    };
  }, [setNewMessageNotification]);

  useEffect(() => {
    // Handle changes in the context (e.g., when the same app is open in multiple tabs)
    const handleStorageChange = (event) => {
      if (event.key === 'newChatMessageFlag') {
        setNewMessageNotification(event.newValue === 'true');
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [setNewMessageNotification]);

  return (
    <div className={styles.MobileMenu}>
      <div className={styles.panelIcons}>
        {panelIconData.map((icon, index) => (
          <Link key={index} to={icon.linkTo}>
            <div
              className={`${styles.menuItem} ${
                icon.name === "wallet" && styles.walletIcon
              }`}
            >
              {icon.name === "wallet" && (
                <div className={styles.walletBubble}>
                  <Icon
                    name={
                      selectedIcon === icon.name ? `${icon.name}_on` : icon.name
                    }
                    folder={icon.folder}
                    size={48}
                    onClick={() => handleIconClick(icon.name)}
                  />
                </div>
              )}
              {icon.name !== "wallet" && (
                <div className={styles.mobileIconContainer}>
                  {index === 5 && newMessageNotification && <div className={`${styles.messageNotification}`}></div>}
                  <Icon
                    name={
                      selectedIcon === icon.name ? `${icon.name}_on` : icon.name
                    }
                    folder={icon.folder}
                    size={32}
                    onClick={() => handleIconClick(icon.name)}
                  />
                  <Typography
                    variant={"small"}
                    fontWeight={"font400"}
                    color="colorBlack"
                    additionalClass={
                      selectedIcon === icon.name ? styles.blue : null
                    }
                  >
                    {translateIconTitle(index)}
                  </Typography>
                </div>
              )}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};
export default MobileMenu;
