// Define action types
export const START_NEW_GAME_1_VS_1 = "START_NEW_GAME_1_VS_1";
export const UPDATE_QUESTION_DATA = "UPDATE_QUESTION_DATA";
export const CHECK_CORRECT_ANSWER_USER_SCORE =
  "CHECK_CORRECT_ANSWER_USER_SCORE";
export const UPDATE_OPPONENT_ANSWER_SCORE = "UPDATE_OPPONENT_ANSWER_SCORE";
export const UPDATE_FINAL_1_VS_1 = "UPDATE_FINAL_1_VS_1";
export const CLEAR_GAME_INFO = "CLEAR_GAME_INFO";
export const START_BOT_GAME = "START_BOT_GAME";
export const BOOSTER_1_SELECTED = "BOOSTER_1_SELECTED";
export const BOOSTER_2_SELECTED = "BOOSTER_2_SELECTED";
export const BOOSTER_3_SELECTED = "BOOSTER_3_SELECTED";
export const CLEAR_RESULT = "CLEAR_RESULT";
export const SET_CURRENT_QUESTION = "SET_CURRENT_QUESTION";
export const SET_CURRENT_QUESTION_STARTING_TIME =
  "SET_CURRENT_QUESTION_STARTING_TIME";
export const SET_CURRENT_RESULT_STARTING_TIME =
  "SET_CURRENT_RESULT_STARTING_TIME";
export const SET_GIVEN_ANSWER = "SET_GIVEN_ANSWER";
export const SET_USED_BOOSTER1 = "SET_USED_BOOSTER1";
export const SET_USED_BOOSTER2 = "SET_USED_BOOSTER2";
export const SET_USED_BOOSTER3 = "SET_USED_BOOSTER3";
export const SET_ANSWERS_HIDDEN = "SET_ANSWERS_HIDDEN";
export const SET_ANSWER_PERCENTAGES = "SET_ANSWER_PERCENTAGES";
export const SET_FOG = "SET_FOG";
export const SET_SPLATTER = "SET_SPLATTER";
export const SET_BOOSTERED_ANSWERS = "SET_BOOSTERED_ANSWERS";
export const SET_GAME_INVITATION_INFO = "SET_GAME_INVITATION_INFO";
