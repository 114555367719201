import React from "react";
import PropTypes from "prop-types";
import Icon from "../../atoms/Icon/Icon";
import styles from "./Avatar.module.css";
import { useAuth } from "../../../AthContext";
// import CircularProgressBar from "../../atoms/CircularProgressBar/CircularProgressBar";
// import StarBadge from "../StarBadge/StarBadge";

const Avatar = ({ icon, imageSize, additionalClass, editAvatar, onEditClick }) => {
  const imageSizeInPixels =
    imageSize === "xsmall"
      ? 40
      : imageSize === "small"
      ? 60
      : imageSize === "large"
      ? 120
      : imageSize === "medium"
      ? 80
      : null;

  const { isMobile } = useAuth();

  return (
    <div className={styles.avatarContainer}>
      <div className={styles.avatar}>
        <Icon
          name={icon}
          folder="avatars"
          size={imageSizeInPixels}
          additionalClass={additionalClass}
        />
        {/* {progress && (
          <CircularProgressBar
            percentage={progress}
            colour="blue"
            r={imageSizeInPixels / 2}
          />
        )} */}
      </div>
      {/* {badgeNumber && (
        <StarBadge badgeNumber={badgeNumber} badgeSize={imageSize} />
      )} */}

      {editAvatar && (
        <Icon
          onClick={() => onEditClick()}
          name={"editAvatar"}
          folder="avatars"
          size={isMobile ? 24 : 32}
          additionalClass={styles.editAvatarBadge}
        />
      )}
    </div>
  );
};

Avatar.propTypes = {
  badgeNumber: PropTypes.number,
  icon: PropTypes.string.isRequired,
  imageSize: PropTypes.string.isRequired,
  progress: PropTypes.number, // Progress value (0 to 100)
  additionalClass: PropTypes.string,
  editAvatar: PropTypes.bool,
};

export default Avatar;
