import React from "react";
import styles from "./MenuPanel.module.css";
import VerticalSocialMediaIcons from "../../molecules/VerticalSocialMediaIcons/VerticalSocialMediaIcons";
import PanelIcons from "../../molecules/PanelIcons/PanelIcons";

const MenuPanel = () => {
  return (
    <>
      <div className={styles.menuPanel}>
        <PanelIcons />
        <VerticalSocialMediaIcons />
      </div>
    </>
  );
};

export default MenuPanel;
