import React from 'react';
import styles from './ModalNotification.module.css';
import Icon from '../../atoms/Icon/Icon';
import Typography from '../../atoms/Typography/Typography';
import PlayButton from '../PlayButton/PlayButton';
import ModalBox from '../../atoms/ModalBox/ModalBox';

const ModalNotification = ({ imageName, heading, text, onClose }) => {
  const handleButtonClick = () => {
    onClose();
  };

  return (
    <>
      <div className={styles.modalContainerBackground}></div>
      <div className={styles.modalContainer}>
        <Icon name={imageName} folder="modals" size={180} additionalClass={styles.modalImage} />
        <ModalBox>
          <div className={styles.modalContent}>
            <div className={styles.textSection}>
              {heading && (
                <Typography
                  variant="heading2"
                  fontWeight="font500"
                  color="colorBlack"
                  additionalClass={styles.modalHeading}
                >
                  {heading}
                </Typography>
              )}
              <Typography variant="heading3" fontWeight="font500" color="colorBlack">
                {text}
              </Typography>
            </div>

            <div className={styles.buttonsSection}>
              <PlayButton primaryText="Ok" additionalClass="play-now" extraClass={styles.okButton} onClick={handleButtonClick} />
            </div>
          </div>
        </ModalBox>
      </div>
    </>
  );
};

export default ModalNotification;
