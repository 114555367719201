import React, { useState, useEffect } from "react";
import styles from "./TemplateProfile.module.css";
import Typography from "../../atoms/Typography/Typography";
import Icon from "../../atoms/Icon/Icon";
import Avatar from "../../molecules/Avatar/Avatar";
import ProfileUserStatistics from "../../organisms/ProfileUserStatistics/ProfileUserStatistics";
import ProfileUserAssets from "../../organisms/ProfileUserAssets/ProfileUserAssets";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../AthContext";
import {
  updateAvatar,
  unblockUser,
  getUserData,
  getUserInfo,
} from "../../../apiHelpers";
import BlockedPersons from "../../organisms/BlockedPersons/BlockedPersons";
import ProfileSettingsModal from "../../organisms/ProfileSettingsModal/ProfileSettingsModal";
import ModalWith2Buttons from "../../molecules/ModalWith2Buttons/ModalWith2Buttons";
import { useTranslation } from "react-i18next";
import axios from "axios";
import AvatarEditModule from "../../molecules/AvatarEditModule/AvatarEditModule";
import { toggleBodyScroll } from "../../../services/helpers";
import ProfileButtonSection from "../../molecules/ProfileButtonSection/ProfileButtonSection";
import ComingSoon from "../../molecules/ComingSoon/ComingSoon";
import { useLoginExpired } from "../../../customHooks";
import SectionProfileInventory from "../../organisms/SectionProfileInventory/SectionProfileInventory";

const avatars = [
  { name: "avatar-1", id: "1" },
  { name: "avatar-2", id: "2" },
  { name: "avatar-3", id: "3" },
  { name: "avatar-4", id: "4" },
  { name: "avatar-5", id: "5" },
  { name: "avatar-6", id: "6" },
  { name: "avatar-7", id: "7" },
  { name: "avatar-8", id: "8" },
  { name: "avatar-9", id: "9" },
  { name: "avatar-10", id: "10" },
  { name: "avatar-11", id: "11" },
  { name: "avatar-12", id: "12" },
  { name: "avatar-13", id: "13" },
  { name: "avatar-14", id: "14" },
  { name: "avatar-15", id: "15" },
  { name: "avatar-16", id: "16" },
  { name: "avatar-17", id: "17" },
  { name: "avatar-18", id: "18" },
  { name: "avatar-19", id: "19" },
  { name: "avatar-20", id: "20" },
  { name: "avatar-21", id: "21" },
  { name: "avatar-22", id: "22" },
  { name: "avatar-23", id: "23" },
  { name: "avatar-24", id: "24" },
  { name: "avatar-25", id: "25" },
  { name: "avatar-26", id: "26" },
  { name: "avatar-27", id: "27" },
  { name: "avatar-28", id: "28" },
  { name: "avatar-29", id: "29" },
  { name: "avatar-30", id: "30" },
  { name: "avatar-31", id: "31" },
  { name: "avatar-32", id: "32" },
  { name: "avatar-33", id: "33" },
];

const TemplateProfile = () => {
  const loginExpired = useLoginExpired();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { logout, isMobile, playerInfo, updatePlayerInfo } = useAuth();
  const [showSelectAvatarModule, setShowSelectAvatarModule] = useState(false);
  const [blockedUsers, setBlockedUsers] = useState([]);
  const [renderBlockedUsers, setRenderBlockedUsers] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const initialSelectedAvatar = avatars.find(
    (avatar) => avatar.id === String(playerInfo.avatarId)
  );
  const [selectedAvatarOnEdit, setSelectedAvatarOnEdit] = useState(
    initialSelectedAvatar
  );
  const id = JSON.parse(localStorage?.getItem("id"));

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getUserData();
        if (id) {
          const userInfo = await getUserInfo(id);
          // Update player info
          updatePlayerInfo(userInfo);
        }
      } catch (error) {
        // Handle errors
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const userWantsToLogout = () => {
    setModalVisible(true);
  };

  const onLogoutConfirm = () => {
    logout();
    navigate("/");
  };

  const handleEditAvatarClick = () => {
    setShowSelectAvatarModule(true);
    toggleBodyScroll(true);
  };

  const handleSettingsClick = () => {
    setShowSettingsModal(true);
  };

  const handleOverlayClick = async () => {
    try {
      toggleBodyScroll(false);
      await updateAvatar(selectedAvatarOnEdit); // Wait for the avatar update to complete
      // Update the playerInfo with the new avatar ID
      await updatePlayerInfo({ avatarId: selectedAvatarOnEdit.id });
      setShowSelectAvatarModule(false); // Close the module
    } catch (error) {
      console.error("Error on updating avatar:", error);
      if (error.response?.status === 401) {
        loginExpired();
      }
    }
  };

  const deleteAccount = async () => {
    // Display confirmation dialog to the user
    const isConfirmed = window.confirm(
      "Are you sure you want to delete your account?"
    );

    // Proceed only if the user confirms
    if (isConfirmed) {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1.0/user/deleteAccount`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token ? `Bearer ${token}` : "",
            },
          }
        );

        if (response.status === 200) {
          alert("Account successfully deleted.");
          onLogoutConfirm();
        } else {
          alert("Failed to delete account. Please try again.");
        }
      } catch (error) {
        console.error("Error deleting account:", error);
        alert("An error occurred. Please try again.");
      }
    } else {
      // If user cancels, handle accordingly (optional)
      console.log("Account deletion cancelled by the user.");
    }
  };

  const handleUnblockUser = async (userId) => {
    try {
      const result = await unblockUser(userId);
      // console.log(result);
      // Remove the unblocked user from the list
      setBlockedUsers((currentBlockedUsers) =>
        currentBlockedUsers.filter((user) => user.internalId !== userId)
      );
    } catch (error) {
      console.error("Failed to unblock user", error);
    }
  };

  return renderBlockedUsers ? (
    <BlockedPersons
      blockedUsers={blockedUsers}
      onUnblockUser={handleUnblockUser}
      onClose={() => setRenderBlockedUsers((prev) => !prev)}
    />
  ) : (
    <div className={styles.profileTemplate}>
      <div className={styles.profileInfoSection}>
        <div className={styles.avatarArea}>
          <Avatar
            icon={"avatar-" + playerInfo.avatarId}
            imageSize={isMobile ? "medium" : "large"}
            additionalClass={styles.avatar}
            editAvatar
            onEditClick={handleEditAvatarClick}
          />
          <Typography
            fontWeight={"font700"}
            color={"colorBlack"}
            additionalClass={styles.font24}
          >
            {playerInfo?.username}
          </Typography>
        </div>
        {/* <ComingSoon>
          <div className={styles.profileUserAssets}>
            <ProfileUserAssets />
          </div>
        </ComingSoon> */}
      </div>
      <div className={styles.profileUserStatistics}>
        <ProfileUserStatistics />
      </div>
      <div className={styles.inventorySection}>
        <SectionProfileInventory
          playerInfo={playerInfo}
        />
      </div>

      <div className={styles.profileButtonSection}>
        <ProfileButtonSection
          userWantsToLogout={userWantsToLogout}
          setBlockedUsers={setBlockedUsers} // prop drilling
          renderBlockedUsers={renderBlockedUsers} // prop drilling
          setRenderBlockedUsers={setRenderBlockedUsers} // prop drilling
          openSettings={handleSettingsClick}
        />
      </div>

      {showSelectAvatarModule && (
        <>
          <div
            className={styles.avatarSelectionModuleBackground}
            onClick={handleOverlayClick}
          ></div>
          <div className={styles.avatarSelectionModule}>
            <div className={styles.topAreaModuleSelectAvatar}>
              <Typography
                additionalClass={styles.avatarSelectionModuleHeading}
                variant="heading2"
                fontWeight="font700"
                color="colorBlack"
              >
                Avatars
              </Typography>
              <Icon
                additionalClass={styles.closeModuleButton}
                name="x_icon"
                folder="other_icons"
                size={32}
                onClick={handleOverlayClick}
              />
            </div>

            <div className={styles.moduleAvatarsArea}>
              <AvatarEditModule
                selectedAvatarOnEdit={selectedAvatarOnEdit}
                setSelectedAvatarOnEdit={setSelectedAvatarOnEdit}
              />
            </div>
          </div>
        </>
      )}
      {modalVisible && (
        <ModalWith2Buttons
          onClickContainer={() => {
            setModalVisible(false);
          }}
          mainTitle={t("mainNav.profileTab.modalLogoutTitle")}
          closeRightButtonTitle={t("generic.yes").toUpperCase()}
          closeLeftButtonTitle={t("generic.no").toUpperCase()}
          modalVisible={modalVisible}
          onPressLeft={() => {
            setModalVisible(false);
          }}
          onPressRight={onLogoutConfirm}
        />
      )}
      {showSettingsModal && (
        <ProfileSettingsModal
          setOpenSettings={() => setShowSettingsModal(false)}
          openSettings={() => setShowSettingsModal(true)}
          deleteUserAccount={() => deleteAccount()}
        />
      )}
    </div>
  );
};

export default TemplateProfile;
