import React from 'react';
import StripeCheckoutForm from '../StripeCheckoutForm/StripeCheckoutForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useLocation } from 'react-router-dom';

export const StripeModal = () => {
  console.log('Using Stripe key:', process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
  const location = useLocation();
  const { clientSecret, productId, priceToPay} = location.state || {};

  const options = {
    clientSecret,
    productId,
    priceToPay
  };

  return (
    <div>
      <Elements options={options} stripe={stripePromise}>
        <StripeCheckoutForm />
      </Elements>
    </div>
  );
};
