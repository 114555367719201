/* global trackdesk */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./FormSignup.module.css";
import Typography from "../../atoms/Typography/Typography";
import PlayButton from "../../molecules/PlayButton/PlayButton";
import Input from "../../atoms/Input/Input";
import Icon from "../../atoms/Icon/Icon";
import SelectAvatarCarousel from "../../molecules/SelectAvatarCarousel/SelectAvatarCarousel";
import PageSeperator from "../../molecules/PageSeperator/PageSeperator";
import LoginIcons from "../../molecules/LoginIcons/LoginIcons";
import Button from "../../atoms/Button/Button";
import { useAuth } from "../../../AthContext";
import { registerUser, checkEmailAvailability } from "../../../apiHelpers";
import ModalNotification from "../../molecules/ModalNotification/ModalNotification";
import { useTranslation } from "react-i18next";

const FormSignup = ({
  togglePage,
  setShowLoginPage,
  showAvatarInput,
  setShowAvatarInput,
  socialRegister,
  setSocialRegister,
  emailFromSocial,
  setEmailFromSocial,
  passwordFromSocial,
  setPasswordFromSocial,
  tokenFromGoogle,
  setTokenFromGoogle,
  onLogin,
  referralCode,
  setReferralCode,
  setSocialLoginType,
  socialLoginType,
}) => {
  const navigate = useNavigate();
  const {
    handleInputChange,
    formData,
    handleAvatarSelect,
    setFormData,
    handleGoogleLogin,
  } = useAuth();
  const [signupErrorOccurred, setSignupErrorOccurred] = useState(false);
  const [signupErrorMessage, setSignupErrorMessage] = useState(null);
  const [signupErrorCode, setSignupErrorCode] = useState(null);
  const languageCode = localStorage.getItem("language")?.replace(/"/g, "");
  const { t } = useTranslation();

  console.log("this is", referralCode);

  const languagePropForPrivacyAndTerms =
    languageCode === "en" ? "En" : languageCode === "de" ? "De" : "";
  const termsAndConditionsLink = `https://api.quizdombattle.com/terms${languagePropForPrivacyAndTerms}`;
  const privacyAndPolicyLink = `https://api.quizdombattle.com/privacy${languagePropForPrivacyAndTerms}`;

  const [formErrors, setFormErrors] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    agreeToTerms: "",
    isOver18: "",
    avatar: "",
    avatarId: "",
  });

  const initialFormData = {
    type: "",
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    agreeToTerms: false,
    isOver18: false,
    avatar: "avatar-1",
    avatarId: "1",
    referralCode: "your_referral_code",
  };

  useEffect(() => {
    setFormData(initialFormData);
  }, []);

  const handleLinkClick = () => {
    togglePage();
  };

  console.log(formData);

  useEffect(() => {
    console.log("important");
    setFormData((prevData) => ({
      ...prevData,
      referralCode, // Add or update the referralCode in your formData
    }));
  }, [referralCode]); // This effect runs whenever referralCode prop changes

  const trimedUsername = formData.username.trim();
  const specialCharacterRegex = /[^a-zA-Z0-9 ]/;

  const handleSubmit = async (event) => {
    event.preventDefault();

    const errors = {
      username:
        formData.username.trim() === ""
          ? "* Username is required."
          : formData.username.trim().length < 6
          ? "* Username must be at least 6 characters long."
          : formData.username.trim().length > 32
          ? "* Username must be at most 32 characters long."
          : specialCharacterRegex.test(trimedUsername)
          ? "* Username must contain only letters and numbers."
          : "",
      email:
        formData.email.trim() === ""
          ? "* Email is required."
          : !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)
          ? "* Invalid email format."
          : "",
      password:
        formData.password.trim() === ""
          ? "* Password is required."
          : formData.password.length < 6
          ? "* Password must be at least 6 characters."
          : "",
      confirmPassword:
        formData.confirmPassword.trim() === ""
          ? "* Confirm Password is required."
          : formData.confirmPassword !== formData.password
          ? "* Passwords do not match."
          : "",
      agreeToTerms: !formData.agreeToTerms
        ? "* You must agree to the terms."
        : "",
      isOver18: !formData.isOver18
        ? "* You must confirm that you are over 18."
        : "",
    };

    setFormErrors(errors);

    if (Object.values(errors).some((error) => error !== "")) {
      console.error("Validation errors:", errors);
      return;
    }

    // Call the registration function with the form data
    try {
      const registrationResponse = await registerUser(formData);

      // Handle the registration response
      console.log("Registration Response:", registrationResponse);

      // You can perform any logic you need with the registration response data
    } catch (error) {
      // Handle the registration error
      console.error("Registration Error:", error);
      const signupErrorMessageResponse = error?.response?.data?.error;
      const signupErrorCodeResponse = error?.response?.data?.code;
      setSignupErrorMessage(signupErrorMessageResponse);
      setSignupErrorCode(signupErrorCodeResponse);
      setSignupErrorOccurred(true);
      console.log(signupErrorCodeResponse);
      //Trackdesk Start
      console.log("Trackdesk function - Start");
      trackdesk("quizdombattle", "conversion", {
        conversionType: "registration",
      });
      //Trackdesk End
      if (signupErrorCodeResponse === 4) {
        setReferralCode(""); // Assuming setReferralCode is accessible here
        setShowAvatarInput(false);
      }
      // navigate("/home");
      // You can throw the error or handle it based on your needs
    }
  };

  console.log(emailFromSocial);
  console.log(passwordFromSocial);
  console.log(tokenFromGoogle, "hvhjvhjhjvhjvhj");

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      type: socialLoginType,
      email: emailFromSocial,
      id: passwordFromSocial,
      token: tokenFromGoogle,
    }));
  }, [socialRegister]);

  useEffect(() => {
    // Check if the showAvatarInput state is false and then reset the username field
    setFormData((prevData) => ({
      ...prevData,
      username: "",
    }));
  }, [showAvatarInput]);

  const handleSocialSubmit = async (event) => {
    event.preventDefault();

    const errors = {
      username:
        formData.username.trim() === ""
          ? "* Username is required."
          : formData.username.trim().length < 6
          ? "* Username must be at least 6 characters long."
          : formData.username.trim().length > 32
          ? "* Username must be at most 32 characters long."
          : specialCharacterRegex.test(trimedUsername)
          ? "* Username must contain only letters and numbers."
          : "",
      agreeToTerms: !formData.agreeToTerms
        ? "* You must agree to the terms."
        : "",
      isOver18: !formData.isOver18
        ? "* You must confirm that you are over 18."
        : "",
    };

    setFormErrors(errors);
    if (Object.values(errors).some((error) => error !== "")) {
      // setShowAvatarInput(false);
      console.error("Validation errors:", errors);
      return;
    }

    // Call the registration function with the form data
    try {
      const registrationResponse = await registerUser(formData);

      // Handle the registration response
      console.log("Registration Response:", registrationResponse);
      //Trackdesk Start
      console.log("Trackdesk function - Start");
      trackdesk("quizdombattle", "conversion", {
        conversionType: "registration",
      });
      //Trackdesk End
      setShowAvatarInput(false);
      setShowLoginPage(true);
      // You can perform any logic you need with the registration response data
    } catch (error) {
      // Handle the registration error
      console.error("Registration Error:", error);
      const signupErrorMessageResponse = error?.response?.data?.error;
      const signupErrorCodeResponse = error?.response?.data?.code;
      // setShowAvatarInput(false);
      setSignupErrorMessage(signupErrorMessageResponse);
      setSignupErrorCode(signupErrorCodeResponse);
      setSignupErrorOccurred(true);
      // navigate("/home");
      // You can throw the error or handle it based on your needs
    }
  };

  const handleErrorModalClose = () => {
    setSignupErrorOccurred(false);
    if (signupErrorCode === 4) {
      togglePage();
    }
  };

  const errorCodeOptions = {
    1: {
      imageName: "exclamation_mark",
    },
    2: {
      imageName: "exclamation_mark",
    },
    3: {
      imageName: "exclamation_mark",
    },
    4: {
      imageName: "verify_email",
      modalHeading: "Verify your email",
    },
  };

  const handleOkButton = async () => {
    const errors = {
      email:
        formData.email.trim() === ""
          ? "* Email is required."
          : !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)
          ? "* Invalid email format."
          : "",
      password:
        formData.password.trim() === ""
          ? "* Password is required."
          : formData.password.length < 6
          ? "* Password must be at least 6 characters."
          : "",
      confirmPassword:
        formData.confirmPassword.trim() === ""
          ? "* Confirm Password is required."
          : formData.confirmPassword !== formData.password
          ? "* Passwords do not match."
          : "",
      agreeToTerms: !formData.agreeToTerms
        ? "* You must agree to the terms."
        : "",
      isOver18: !formData.isOver18
        ? "* You must confirm that you are over 18."
        : "",
    };

    handleInputChange("type", "email");

    // Update formErrors with the results of these checks
    setFormErrors(errors);

    // Check if any error messages have been set
    const isValid = !Object.values(errors).some((error) => error !== "");

    if (!isValid) {
      console.error("Validation errors:", errors);
      return; // Stop the function if there are validation errors
    }

    // If initial validations pass, check email availability
    try {
      const emailAvailable = await checkEmailAvailability(formData.email);
      console.log("email available", emailAvailable.status);

      if (emailAvailable.status === "Allowed") {
        setShowAvatarInput(true);
        console.log("Email is available, showing avatar input");
      } else {
        // Handle the case where the email is not available
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: "* Invaliddd email format",
        }));
        console.error("Email is not available");
      }
    } catch (error) {
      // Handle API error
      console.error("Error checking email availability:", error);
      const apiErrorMessage =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : "* Error checking email availability. Please try again.";
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        email: apiErrorMessage,
      }));
    }
  };

  const { isMobile } = useAuth();
  return signupErrorOccurred && signupErrorMessage ? (
    <ModalNotification
      imageName={errorCodeOptions[signupErrorCode]?.imageName}
      heading={errorCodeOptions[signupErrorCode]?.modalHeading || ""}
      text={signupErrorMessage}
      onClose={handleErrorModalClose}
    />
  ) : (
    <div className={styles.formContainer}>
      <form onSubmit={handleSubmit}>
        {!showAvatarInput && (
          <div className={styles.formInputsContainer}>
            <h2>
              <Typography
                color="colorBlack"
                variant={isMobile ? "heading2" : "heading1"}
                fontWeight="font700"
              >
                {t("signUpFirstStep.signUpButton")}
              </Typography>
            </h2>
            <div className={styles.inputContainer}>
              <Icon name="envelope_icon" folder="input_icons" />
              <Input
                type="email"
                value={formData.email}
                placeholder={t("generic.email")}
                onChange={(e) => handleInputChange("email", e.target.value)}
              />
              {formErrors.email && (
                <Typography
                  additionalClass={styles.errorMessage}
                  variant="caption"
                  fontWeight="font400"
                >
                  {formErrors.email}
                </Typography>
              )}
            </div>
            <div className={styles.inputContainer}>
              <Icon name="key_icon" folder="input_icons" />
              <Input
                type="password"
                value={formData.password}
                placeholder={t("generic.password")}
                onChange={(e) => handleInputChange("password", e.target.value)}
              />
              {formErrors.password && (
                <Typography
                  additionalClass={styles.errorMessage}
                  variant="caption"
                  fontWeight="font400"
                >
                  {formErrors.password}
                </Typography>
              )}
            </div>
            <div className={styles.inputContainer}>
              <Icon name="key_icon" folder="input_icons" />
              <Input
                type="password"
                value={formData.confirmPassword}
                placeholder={t("generic.confirmPassword")}
                onChange={(e) =>
                  handleInputChange("confirmPassword", e.target.value)
                }
              />
              {formErrors.confirmPassword && (
                <Typography
                  additionalClass={styles.errorMessage}
                  variant="caption"
                  fontWeight="font400"
                >
                  {formErrors.confirmPassword}
                </Typography>
              )}
            </div>

            <div className={styles.inputContainer}>
              <label
                htmlFor="agreeWithTermsCheckbox"
                className={styles.labelInputWrapper}
              >
                <Input
                  id="agreeWithTermsCheckbox"
                  additionalClass={styles.checkboxInput}
                  type="checkbox"
                  label=""
                  checked={formData.agreeToTerms}
                  onChange={(e) =>
                    handleInputChange("agreeToTerms", e.target.checked)
                  }
                />
                <label
                  htmlFor="agreeWithTermsCheckbox"
                  className={styles.linkArea}
                >
                  {t("signUpSecondStep.checkBoxFirstPart")}
                  <a
                    href={termsAndConditionsLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.linkButtonWrapper}
                  >
                    <Button additionalClass={styles.linkButton}>
                      {t("signUpSecondStep.checkBoxSecondPart")}
                    </Button>
                  </a>
                  {t("signUpSecondStep.checkBoxThirdPart")}
                  <a
                    href={privacyAndPolicyLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.linkButtonWrapper}
                  >
                    <Button additionalClass={styles.linkButton}>
                      {t("signUpSecondStep.checkBoxFourthPart")}
                    </Button>
                  </a>
                  {t("signUpSecondStep.checkBoxFifthPart")}
                </label>
              </label>
              {formErrors.agreeToTerms && (
                <Typography
                  additionalClass={styles.errorMessage}
                  variant="caption"
                  fontWeight="font400"
                >
                  {formErrors.agreeToTerms}
                </Typography>
              )}
            </div>

            <div className={styles.inputContainer}>
              <label className={styles.labelInputWrapper}>
                <Input
                  id="isOver18Checkbox"
                  additionalClass={styles.checkboxInput}
                  type="checkbox"
                  label=""
                  checked={formData.isOver18}
                  onChange={(e) =>
                    handleInputChange("isOver18", e.target.checked)
                  }
                />
                <label
                  htmlFor="isOver18Checkbox"
                  className={styles.secondLabel}
                >
                  {t("signUpSecondStep.checkBox2")}
                </label>
              </label>
              {formErrors.isOver18 && (
                <Typography
                  additionalClass={styles.errorMessage}
                  variant="caption"
                  fontWeight="font400"
                >
                  {formErrors.isOver18}
                </Typography>
              )}
            </div>
          </div>
        )}

        {showAvatarInput && !socialRegister ? (
          <>
            <h2 className={styles.avatarTitle}>
              <Typography
                color="colorBlack"
                variant={isMobile ? "heading2" : "heading1"}
                fontWeight="font700"
              >
                {t("signUpSecondStep.chooseAvatar")}
              </Typography>
            </h2>
            <div className={styles.inputContainer}>
              <Icon name="person_icon" folder="input_icons" />
              <Input
                type="text"
                value={formData.username}
                placeholder="Username"
                onChange={(e) => handleInputChange("username", e.target.value)}
              />
              {formErrors.username && (
                <Typography
                  additionalClass={styles.errorMessage}
                  variant="caption"
                  fontWeight="font400"
                >
                  {formErrors.username}
                </Typography>
              )}
            </div>
            <div className={styles.avatarInput}>
              <SelectAvatarCarousel onAvatarSelect={handleAvatarSelect} />
              <div className={styles.avatarInputButton}></div>
              <PlayButton
                id="register"
                additionalClass="play-now"
                primaryText="OK"
                onClick={handleSubmit}
              />
            </div>
          </>
        ) : null}

        {showAvatarInput && socialRegister ? (
          <>
            <h2>
              <Typography
                color="colorBlack"
                variant={isMobile ? "heading2" : "heading1"}
                fontWeight="font700"
              >
                {"Choose Username"}
              </Typography>
            </h2>
            <div className={styles.inputContainer}>
              <Icon name="person_icon" folder="input_icons" />
              <Input
                type="text"
                value={formData.username}
                placeholder="Username"
                onChange={(e) => handleInputChange("username", e.target.value)}
              />
              {formErrors.username && (
                <Typography
                  additionalClass={styles.errorMessage}
                  variant="caption"
                  fontWeight="font400"
                >
                  {formErrors.username}
                </Typography>
              )}
            </div>
            <div className={styles.avatarInput}>
              <h2>
                <Typography
                  color="colorBlack"
                  variant={isMobile ? "heading2" : "heading1"}
                  fontWeight="font700"
                >
                  {"Select Avatar"}
                </Typography>
              </h2>
              <SelectAvatarCarousel onAvatarSelect={handleAvatarSelect} />
              <div className={styles.avatarInputButton}></div>
              <div className={styles.inputWrapper2}>
                <div className={styles.inputContainer}>
                  <label
                    htmlFor="agreeWithTermsCheckbox"
                    className={styles.labelInputWrapper}
                  >
                    <Input
                      id="agreeWithTermsCheckbox"
                      additionalClass={styles.checkboxInput}
                      type="checkbox"
                      label=""
                      checked={formData.agreeToTerms}
                      onChange={(e) =>
                        handleInputChange("agreeToTerms", e.target.checked)
                      }
                    />
                    <label
                      htmlFor="agreeWithTermsCheckbox"
                      className={styles.linkArea}
                    >
                      {t("signUpSecondStep.checkBoxFirstPart")}
                      <Button additionalClass={styles.linkButton}>
                        {t("signUpSecondStep.checkBoxSecondPart")}
                      </Button>
                      {t("signUpSecondStep.checkBoxThirdPart")}
                      <Button additionalClass={styles.linkButton}>
                        {t("signUpSecondStep.checkBoxFourthPart")}{" "}
                      </Button>
                    </label>
                  </label>
                  {formErrors.agreeToTerms && (
                    <Typography
                      additionalClass={styles.errorMessage}
                      variant="caption"
                      fontWeight="font400"
                    >
                      {formErrors.agreeToTerms}
                    </Typography>
                  )}
                </div>

                <div className={styles.inputContainer}>
                  <label className={styles.labelInputWrapper}>
                    <Input
                      id="isOver18Checkbox"
                      additionalClass={styles.checkboxInput}
                      type="checkbox"
                      label=""
                      checked={formData.isOver18}
                      onChange={(e) =>
                        handleInputChange("isOver18", e.target.checked)
                      }
                    />
                    <label
                      htmlFor="isOver18Checkbox"
                      className={styles.secondLabel}
                    >
                      {t("signUpSecondStep.checkBox2")}
                    </label>
                  </label>
                  {formErrors.isOver18 && (
                    <Typography
                      additionalClass={styles.errorMessage}
                      variant="caption"
                      fontWeight="font400"
                    >
                      {formErrors.isOver18}
                    </Typography>
                  )}
                </div>
              </div>

              <PlayButton
                id="register"
                additionalClass="play-now"
                primaryText="OK"
                onClick={handleSocialSubmit}
              />
            </div>
          </>
        ) : null}
      </form>

      {!showAvatarInput && (
        <div className={styles.createSection}>
          <div className={styles.linkArea2}>
            <PlayButton
              additionalClass="play-now"
              primaryText="Create"
              onClick={handleOkButton}
            />
            <Typography fontWeight="font700" variant="body2" color="colorBlack">
              {t("signUpFirstStep.linkQuote")}
              <Button
                onClick={handleLinkClick}
                additionalClass={styles.linkButton}
              >
                {t("signUpFirstStep.linkToLogin")}
              </Button>
            </Typography>
          </div>
          <PageSeperator />
          <LoginIcons
            setShowAvatarInput={setShowAvatarInput}
            setShowLoginPage={setShowLoginPage}
            setSocialRegister={setSocialRegister}
            setEmailFromSocial={setEmailFromSocial}
            setPasswordFromSocial={setPasswordFromSocial}
            tokenFromGoogle={tokenFromGoogle}
            setTokenFromGoogle={setTokenFromGoogle}
            onLogin={onLogin}
            setSocialLoginType={setSocialLoginType}
          />
        </div>
      )}
    </div>
  );
};

export default FormSignup;
