export const initialState = {
  auth: {
    error: "",
    user: null,
    type: "email",
    email: "",
    password: "",
    avatarId: "",
    username: "",
    socialEmail: "",
    id: "",
    token: "",
  },
  social: {
    error: "",
    pending: [],
    sentPending: [],
    usersSearched: [],
    searchLetters: "",
    friends: [],
    blockedUsers: [],
    opponentUserInfo: {},
  },
  game: {
    error: "",
    gameId: "",
    players: {},
    questionData: {},
    correctAnswer: null,
    userScore: 0,
    opponentScore: 0,
    opponentHasAnswered: false,
    result: null,
    botGame: false,
    booster1Id: 10,
    booster2Id: 10,
    booster3Id: 11,
    currentQuestion: 1,
    currentQuestionTimeStamp: 0,
    currentResultTimestamp: 0,
    userGivenAnswer: 0,
    usedBooster1: false,
    usedBooster2: false,
    usedBooster3: 0,
    answerPercentages: [],
    answersHidden: [],
    boosteredAnswers: null,
    inFog: false,
    splattered: false,
    gameInvitationInfo: {},
  },
  leaderboard: {
    error: "",
    season: {
      leaderboardList: [],
      userData: {},
    },
    allTime: {
      leaderboardList: [],
      userData: {},
    },
  },
  chat: {
    error: "",
    chatList: [],
    chatParticipants: {},
  },
  achievement: {
    error: "",
    achievementData: [],
  },
  init: {
    version_maintenance: {},
  },
  store: {
    error: "",
    purchase: "",
    storeData: [],
    purchaseInStores: false,
  },
  battle: {
    error: "",
    battleId: "",
    opponentsNeeded: 0,
    players: [],
    playersInLobby: [],
    questionData: {},
    correctAnswer: null,
    userInBattle: false,
    userScoreInBattle: 0,
    battleContinues: false,
  },
};
