import React from "react";
import styles from "./WelcomePage.module.css";
import Icon from "../../atoms/Icon/Icon";
import BackgroundGeneric from "../../molecules/BackgroundGeneric/BackgroundGeneric";

const WelcomePage = () => {
  return (
    <div className={styles["welcome-page-content"]}>
      <Icon name="quizdomLarge" folder="logos" size={552} />
    </div>
  );
};

export default WelcomePage;
