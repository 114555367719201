import React from 'react';

const Input = ({
  id = '',
  label,
  name,
  type = 'text',
  onChange,
  value,
  additionalClass = '',
  placeholder,
  required = true,
  style,
  onKeyDown
}) => (
  <div className={`${additionalClass}`}>
    <label>{label}</label>
    <input
      id={id}
      name={name}
      type={type}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      required={required}
      style={style}
      onKeyDown={onKeyDown}
    />
  </div>
);

export default Input;
