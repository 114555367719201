import React from 'react';
import styles from './CountdownTimer.module.css';
import { useState } from 'react';
import Typography from '../../atoms/Typography/Typography';
import { useTranslation } from 'react-i18next';

const CountdownTimer = ({ startTime }) => {
  const { t } = useTranslation();
  const [timeRemaining, setTimeRemaining] = React.useState(
    new Date(startTime) - new Date()
  );
  const timeRemainingMinutes = Math.floor(timeRemaining / 60000);
  const timeRemainingSeconds = Math.floor((timeRemaining % 60000) / 1000);
  // Update the time remaining every second
  React.useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(new Date(startTime) - new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, [startTime]);
  return (
    <div className={styles.countdownTimer}>
      {timeRemaining > 0 ? (
        <Typography
          variant="heading3"
          fontWeight={'font900'}
          color="colorBlack"
        >
          {t('tourInfo.tournamentCountdown')}
          {timeRemainingMinutes}:
          {timeRemainingSeconds < 10
            ? `0${timeRemainingSeconds}`
            : timeRemainingSeconds}
        </Typography>
      ) : (
        <Typography
          variant="heading3"
          fontWeight={'font900'}
          color="colorBlack"
        >
          {t('tourInfo.hasStarted')}
        </Typography>
      )}
    </div>
  );
};

export default CountdownTimer;
