import React, { useEffect, useState } from "react";
import { getTournamentInfo, getTournamentIds } from "../../../apiHelpers";
import { useAuth } from "../../../AthContext";
import { useTranslation } from "react-i18next";
import { useLoginExpired } from "../../../customHooks";
import CashQuizTournamentsPanelV2 from "../../molecules/CashQuizTournamentsPanelV2/CashQuizTournamentsPanelV2";
import styles from "./CashTournamentsSectionV2.module.css";

const CashTournamentsSectionV2 = () => {
  const { t } = useTranslation();
  const [availableTournamentIDs, setAvailableTournamentIDs] = useState([]);
  const [availableTournamentData, setAvailableTournamentData] = useState([]);
  const { loggedIn } = useAuth();
  const loginExpired = useLoginExpired();

  // Production ID
  // const tournamentsList = [
  //   // "66cd84c831b5693b62cbbe97" /*Monday 2/9*/,
  //   // "66d5c8a24a70aa7da271914e" /*Wednesday 04/9:*/,
  //   // "66d5c98f4a70aae26871ac06" /*Friday 06/9*/,
  //   // "66d5ca254a70aa1c6771b516" /*Sunday 8/9*/,
  //   // "66d5caab4a70aa2a0571b903" /*Monday 9/9*/,
  //   // "66e01019f51d86300bb31372" /*Wednesday 11/9:*/,
  //   // "66e01130f51d8638adb321ae" /*Friday 13/9*/,
  //   // "66e01203f51d865012b3299d" /*Sunday 15/9*/,
  //   // "66e012c1f51d8634eeb32fc1" /*Monday 16/9*/,
  //   // "66e97892f51d8647bc1ce1af" /*Wednesday 18/9*/,
  //   // "66e9793df51d86cda91cf269" /*Friday 21/9*/,
  //   // "66e97cc6f51d867ab91d2e04" /*Sunday 23/9*/,
  //   // "66e979ebf51d86416c1cf83c" /*Monday 14/9*/,
  //   "66f15db9f51d86178a768ce6" /*Wednesday 25/9*/,
  //   "66f15e97f51d8628787699eb" /*Friday 27/9*/,
  //   "66f15fc5f51d86171876a25f" /*Sunday 29/9*/,
  //   "66f15f35f51d866971769dd1" /*Monday 30/9*/,
  // ];

  // Development ID
  // const tournamentsList = [
  //   // "663e3265fc02e79501d8fd5c",
  //   // "666008038784abc1da0202a4",
  //   // "6661327da0af59842a197af2",
  //   // "6662cfdc4dbdc1f5ec0db672", /* Monday-old*/
  //   // "666805212a10f09383f11e31", /* Wednesday12-6*/
  //   // "666c134b220494cc6d1c6036", /* Friday12-6*/
  //   // "6672afc463a535a38b10395f", /* Sunday23-6*/
  //   // "667548e5d0aab9c07189d3ae" /* Monday-24/6*/,
  //   // "667a83b33a9b117d0dc61b1b" /* Wednesday26-6*/,
  //   // "667a83c13a9b117d0dc61b1c" /* Friday28-6*/,
  //   "66f27fe339a88ec7ab2a3496",
  //   "66f2806039a88e42152a349b",
  // ];

  const tournamentPanelsInfo = [
    {
      id: 1,
      weekDay: "Sunday Cash Quiz",
      imageName1: "einstein1",
      textQuestionsPrize: "15 QUESTIONS 200€ PRIZE",
      topBackgroundColor: "blueBackground",
      prize: 200,
      remainingTime: t("mainNav.homeTab.prizeTournaments.remainingTime2"),
      requiredTickets: "3",
    },
    {
      id: 2,
      weekDay: "Monday Cash Quiz",
      imageName1: "einstein2",
      textQuestionsPrize: "10 QUESTIONS 50€ PRIZE",
      topBackgroundColor: "lightPurpleBackground",
      prize: 50,
      remainingTime: t("mainNav.homeTab.prizeTournaments.remainingTime2"),
      requiredTickets: "1",
    },
    {
      id: 3,
      weekDay: "Tuesday Cash Quiz",
      imageName1: "einstein3",
      textQuestionsPrize: "10 QUESTIONS 50€ PRIZE",
      topBackgroundColor: "brownBackground",
      prize: 50,
      remainingTime: t("mainNav.homeTab.prizeTournaments.remainingTime2"),
      requiredTickets: "1",
    },
    {
      id: 4,
      weekDay: "Wednesday Cash Quiz",
      imageName1: "einstein4",
      textQuestionsPrize: "10 QUESTIONS 50€ PRIZE",
      topBackgroundColor: "darkPurpleBackground",
      prize: 50,
      remainingTime: t("mainNav.homeTab.prizeTournaments.remainingTime2"),
      requiredTickets: "1",
    },
    {
      id: 5,
      weekDay: "Thurday Cash Quiz",
      imageName1: "einstein5",
      textQuestionsPrize: "10 QUESTIONS 50€ PRIZE",
      topBackgroundColor: "greenBackground",
      prize: 50,
      remainingTime: t("mainNav.homeTab.prizeTournaments.remainingTime2"),
      requiredTickets: "1",
    },
    {
      id: 6,
      weekDay: "Friday Cash Quiz",
      imageName1: "einstein6",
      textQuestionsPrize: "10 QUESTIONS 50€ PRIZE",
      topBackgroundColor: "redBackground",
      prize: 50,
      remainingTime: t("mainNav.homeTab.prizeTournaments.remainingTime2"),
      requiredTickets: "1",
    },
  ];

  //Fetch the IDs of all available Cash Tournaments

  useEffect(() => {
    const fetchTournamentIDs = async () => {
      try {
        const tournamentIDs = await getTournamentIds();
        setAvailableTournamentIDs(tournamentIDs);
      } catch (error) {
        console.error("Error fetching store data:", error);
        if (error.response?.status === 401) {
          loginExpired();
        }
      }
    };
    fetchTournamentIDs();
  }, []);

  //Fetch the Data of all available Cash Tournaments based on the available tournament IDs

  useEffect(() => {
    const fetchTournamentInfo = async () => {
      try {
        const tournamentData = await Promise.all(
          availableTournamentIDs.map((tournamentId) =>
            getTournamentInfo(tournamentId)
          )
        );
        setAvailableTournamentData(
          tournamentData.map((data) => data.tournament)
        );
      } catch (error) {
        console.error("Error in getting tournament info:", error);
        loginExpired();
      }
    };
    if (loggedIn && availableTournamentIDs.length > 0) {
      fetchTournamentInfo();
    }
  }, [loggedIn, availableTournamentIDs]);

  useEffect(() => {
    const calculateRemainingTime = (startTime) => {
      const now = new Date().getTime();
      const start = new Date(startTime).getTime();
      const diff = start - now;

      if (diff <= 0) {
        return "Finished";
      }

      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);

      return `${hours}h ${minutes}m ${seconds}s`;
    };
    let interval;
    if (loggedIn) {
      interval = setInterval(() => {
        setAvailableTournamentData((prevTournaments) =>
          prevTournaments.map((tournament) => ({
            ...tournament,
            remainingTime: calculateRemainingTime(tournament.startingAt),
          }))
        );
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [loggedIn]);

  const sortedTournaments = loggedIn
    ? availableTournamentData.sort((a, b) => {
        if (a.name === "SUNDAY") return -1;
        if (b.name === "SUNDAY") return 1;
        if (!a.finished && b.finished) return -1;
        if (a.finished && !b.finished) return 1;
        if (a.startingAt < b.startingAt) return -1;
        if (b.startingAt > a.startingAt) return 1;
        return 0;
      })
    : [];

  return (
    <div className={styles.tournamentPanelsContainer}>
      {!loggedIn &&
        tournamentPanelsInfo.map(
          ({
            id,
            weekDay,
            imageName1,
            textQuestionsPrize,
            topBackgroundColor,
            prize,
            requiredTickets,
            remainingTime,
          }) => (
            <CashQuizTournamentsPanelV2
              key={id}
              weekDay={weekDay}
              imageName1={imageName1}
              textQuestionsPrize={textQuestionsPrize}
              topBackgroundColor={topBackgroundColor}
              prize={prize}
              remainingTime={remainingTime}
              requiredTickets={requiredTickets}
            />
          )
        )}
      {loggedIn &&
        sortedTournaments?.map((tournament) => {
          return (
            <CashQuizTournamentsPanelV2
              key={tournament?._id}
              weekDay={tournament?.name}
              imageName1={tournament?.backgroundImage}
              textQuestionsPrize={tournament?.description}
              topBackgroundColor={tournament?.backgroundColor}
              prize={tournament?.amount}
              remainingTime={tournament?.remainingTime}
              requiredTickets={tournament?.cost}
              tournamentId={tournament?._id}
              date={tournament?.startingAt}
            />
          );
        })}
    </div>
  );
};

export default CashTournamentsSectionV2;
