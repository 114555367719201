import React from 'react';
import styles from './FancySpinWheel.module.css';
import FancySpinWheelBase from '../../molecules/FancySpinWheelBase/FancySpinWheelBase';
import FancySpinWheelSlicesBase from '../../molecules/FancySpinWheelSlicesBase/FancySpinWheelSlicesBase';
import FancySpinWheelSlices from '../../molecules/FancySpinWheelSlices/FancySpinWheelSlices';
import FancySpinWheelArrow from '../../molecules/FancySpinWheelArrow/FancySpinWheelArrow';

const FancySpinWheel = ({ size = 400, isSpinning, rotationDegree }) => {
  const topValue = 31 * (size / 100); //for positioning the arrow correct according to the size of the wheel

  return (
    <div className={styles.spinWheelWrapper}>
      <div className={styles.spinWheel} style={{ width: size, height: size }}>
        <FancySpinWheelBase numOfLeds={8} baseDiameter={size} baseColor="red" ledDiameter="2.7%" />
        <FancySpinWheelSlicesBase diameter="98%" />
        <FancySpinWheelSlices isSpinning={isSpinning} rotationDegree={rotationDegree} diameter={size * 1.15} />
        <FancySpinWheelArrow size="24%" top={topValue} />
      </div>
    </div>
  );
};

export default FancySpinWheel;
