import React from "react";
import styles from "./ParticipantEntry.module.css"; // Import your styles
import Icon from "../../atoms/Icon/Icon";
import Typography from "../../atoms/Typography/Typography";
import { useAuth } from "../../../AthContext";

const ParticipantEntry = ({ index, position, avatarId, username, points }) => {
  const { isMobile } = useAuth();

  return (
    <div className={styles.leaderboardEntry}>
      <span className={styles.skewedBorder}></span>
      <div className={styles.entryContent}>
        {!points && (
          <div className={styles.position}>
            <Typography
              variant={isMobile ? "body1" : "heading2"}
              fontWeight="font900"
              additionalClass={styles.bronze}
            >
              {position || index + 1}
            </Typography>
          </div>
        )}
        <Icon
          folder="avatars"
          name={`avatar-${avatarId}`}
          size={isMobile ? 32 : 48}
        />
        <div className={styles.userData}>
          <div className={styles.username}>
            <Typography
              variant={isMobile ? "caption" : "body1"}
              fontWeight={"font700"}
              color="colorBlack"
            >
              {username || ""}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParticipantEntry;
