import React, { useState } from 'react';
import styles from './ForgotPasswordForm.module.css';
import Typography from '../../atoms/Typography/Typography';
import Icon from '../../atoms/Icon/Icon';
import Input from '../../atoms/Input/Input';
import PlayButton from '../../molecules/PlayButton/PlayButton';
import { forgotPassword } from '../../../apiHelpers';
import { useTranslation } from "react-i18next";

const ForgotPasswordForm = ({
  
  setShowForgotPasswordForm,
  setShowModalNotificationSendEmail,
  setSubmitFormErrorOccurred,
  setSubmitFormErrorMessage,
}) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    email: '',
  });

  const [formErrors, setFormErrors] = useState({
    email: '',
  });

  const handleInputChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const emailToSendPassword = formData.email;

  const resetPasswordClicked = async () => {
    console.log('about to reset password');
    try {
      const response = await forgotPassword(emailToSendPassword);
      // Assuming the response being OK means the operation was successful
      if (response.status >= 200 && response.status < 300) {
        // Assuming the response status 200 indicates success
        setShowModalNotificationSendEmail(true);
        setShowForgotPasswordForm(false);
        // Optionally reset form state or handle further on success
      } else {
        // Handle other status codes or server-side validation errors
        // You might want to update formErrors state here based on the response
      }
    } catch (error) {
      // Handle unexpected errors or rejections from the promise
      console.error('Password reset failed:', error.response.data.error);
      setSubmitFormErrorOccurred(true);
      setSubmitFormErrorMessage(error.response.data.error);
      setShowForgotPasswordForm(false);
      // Optionally update form state to reflect the error
    }
  };

  return (
    <div className={styles.formContainer}>
      <Typography color="colorBlack" variant="heading1" additionalClass={styles.headerFormForgotPassword}>
        {t("generic.forgotPassword")}
      </Typography>
      <form className={styles.inputContainer}>
        <Icon name="envelope_icon" folder="input_icons" />
        <Input
          type="email"
          value={formData.email}
          id="loginEmail"
          placeholder="Email"
          onChange={(e) => handleInputChange('email', e.target.value)}
        />
        {/* {formErrors.email && (
          <Typography additionalClass={styles.errorMessage} variant="caption" fontWeight="font400">
            {formErrors.email}
          </Typography>
        )} */}
      </form>
      <PlayButton primaryText={t("forgotPasswordScreen.restorePassword")} extraClass={styles.sendEmailButton} onClick={resetPasswordClicked} />
    </div>
  );
};

export default ForgotPasswordForm;
