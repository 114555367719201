import React, { useState,useEffect } from 'react';

import styles from './SignupLoginTemplate.module.css';
import FormLogin from '../../organisms/FormLogin/FormLogin';
import FormSignup from '../../organisms/FormSignup/FormSignup';
import { useAuth } from '../../../AthContext';
import { useLocation } from 'react-router-dom';

const SignupLoginTemplate = ({ playerData }) => {
  const { login } = useAuth();
  const location = useLocation();


  const [showLoginPage, setShowLoginPage] = useState(true);
  const [showAvatarInput, setShowAvatarInput] = useState(false);
  const [socialRegister, setSocialRegister] = useState(false);
  const [emailFromSocial, setEmailFromSocial] = useState('')
  const [passwordFromSocial, setPasswordFromSocial] = useState('')
  const [tokenFromGoogle, setTokenFromGoogle] = useState('')
  const [referralCode, setReferralCode] = useState('');
  const [socialLoginType, setSocialLoginType] = useState('');


  const togglePage = () => setShowLoginPage(!showLoginPage);
  const handleLogin = () => {
    // Call the login function from the context to update the state
    login(playerData);
  };

  useEffect(() => {
    // Check if state exists and set values accordingly
    if (location.state) {
      if (location.state.showLoginPage === false) {
        setShowLoginPage(false);
      }
      if (location.state.referralCode) {
        setReferralCode(location.state.referralCode);
      }
    }
  }, [location]);

  console.log(referralCode)

  return (
    <div className={styles.signupLoginPageContainer}>
      {showLoginPage ? (
        <FormLogin
          togglePage={togglePage}
          setShowLoginPage={setShowLoginPage}
          onLogin={handleLogin}
          setShowAvatarInput={setShowAvatarInput}
          setSocialRegister={setSocialRegister}
          setEmailFromSocial={setEmailFromSocial}
          setPasswordFromSocial={setPasswordFromSocial}
          tokenFromGoogle={tokenFromGoogle}
          setTokenFromGoogle={setTokenFromGoogle}
          setSocialLoginType={setSocialLoginType}
          socialLoginType={socialLoginType}
        />
      ) : (
        <FormSignup
          togglePage={togglePage}
          setShowLoginPage={setShowLoginPage}
          onLogin={handleLogin}
          showAvatarInput={showAvatarInput}
          setShowAvatarInput={setShowAvatarInput}
          socialRegister={socialRegister}
          setSocialRegister={setSocialRegister}
          emailFromSocial={emailFromSocial}
          setEmailFromSocial={setEmailFromSocial}
          passwordFromSocial={passwordFromSocial}
          setPasswordFromSocial={setPasswordFromSocial}
          tokenFromGoogle={tokenFromGoogle}
          setTokenFromGoogle={setTokenFromGoogle}
          referralCode={referralCode}
          setReferralCode={setReferralCode}
          socialLoginType={socialLoginType}
        />
      )}
    </div>
  );
};

export default SignupLoginTemplate;
