import React from "react";
import styles from "./BoostersToBuy.module.css";
import BoosterButton from "../../molecules/BoosterButton/BoosterButton";
import { useAuth } from "../../../AthContext";

const BoostersToBuy = ({ boosterData, onBoosterSelect }) => {
  const { playerInfo } = useAuth();
  return (
    <div className={styles.boostersContainer}>
      {boosterData.map((booster, index) => (
        <BoosterButton
          key={index}
          {...booster}
          onClick={() => onBoosterSelect(booster, index)}
          available={playerInfo?.availableBoosters[index]?.amount}
        />
      ))}
    </div>
  );
};

export default BoostersToBuy;
