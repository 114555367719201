import React from "react";
import styles from "./TournamentParticipants.module.css";
import LoadingSpinner from "../../molecules/LoadingSpinner/LoadingSpinner";
import ParticipantEntry from "../../molecules/ParticipantEntry/ParticipantEntry";
import WinnerEntries from "../../molecules/WinnerEntries/WinnerEntries";
import { useAuth } from "../../../AthContext";

const TournamentParticipants = ({ players = [], completed = false }) => {
  const { isMobile } = useAuth();

  // If completed and players.length is 0, return a Jackpot
  if (completed && !players.length) {
    return (
      <div className={styles.jackpotContainer}>
        <div className={styles.jackpotText}>Jackpot</div>
      </div>
    );
  }
  if (!players.length) {
    return <LoadingSpinner />;
  }
  // if mobile , only keep 6 characters per name
  if (isMobile) {
    players = players.map((player) => ({
      ...player,
      username: player.username.slice(0, 6),
    }));
  }
  // split players into 50 and 50 for the two columns
  const firstHalf = players.slice(0, players.length / 2);
  const secondHalf = players.slice(players.length / 2);

  if (completed) {
    return (
      <div className={styles.entireContainerCompleted}>
        <div className={styles.participantsContainerCompleted}>
          {players.map((entry, index) => (
            <WinnerEntries
              key={index}
              index={index}
              username={entry?.username}
              avatarId={entry?.avatarId?.toString()}
              money={entry?.amountWon}
            />
          ))}
        </div>
      </div>
    );
  }
  return (
    <div className={styles.entireContainer}>
      <div className={styles.participantsContainer2}>
        {firstHalf.map((entry, index) => (
          <ParticipantEntry
            key={index}
            index={2 * index}
            username={entry?.username}
            avatarId={entry?.avatarId.toString()}
          />
        ))}
      </div>
      <div className={styles.participantsContainer}>
        {secondHalf.map((entry, index) => (
          <ParticipantEntry
            key={index}
            index={2 * index + 1}
            username={entry?.username}
            avatarId={entry?.avatarId.toString()}
          />
        ))}
      </div>
    </div>
  );
};

export default TournamentParticipants;
