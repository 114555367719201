import React, { useState, useEffect } from "react";
import styles from "./QuizSelectionTemplate.module.css";
import Typography from "../../atoms/Typography/Typography";
import GameModeSlider from "../../molecules/GameModeSlider/GameModeSlider";
import CategorySelectionPanel from "../../molecules/CategorySelectionPanel/CategorySelectionPanel";
import PlayButton from "../../molecules/PlayButton/PlayButton";
import CashTournamentsSection from "../../organisms/CashTournamentsSection/CashTournamentsSection";
import { useAuth } from "../../../AthContext";
import SinglePlayerMode from "../SinglePlayerMode/SinglePlayerMode";
import ComingSoon from "../../molecules/ComingSoon/ComingSoon";
import { startNewQuizGame, fetchQuestions } from "../../../apiHelpers";
import { useGame } from "../../../GameContext";
import * as ActionTypes from "../../../ActionTypes";
import { useTranslation } from "react-i18next";
import { useLoginExpired } from "../../../customHooks";
import { useNavigate } from "react-router-dom";
import Panel1v1 from "../../organisms/Panel1v1/Panel1v1";

const QuizSelectionTemplate = () => {
  const [selectedMode, setSelectedMode] = useState("Quick play");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [playNowClicked, setPlayNowClicked] = useState(false); // State to track if "Play Now" button is clicked
  const { dispatch } = useGame();
  const { loggedIn, isMobile, setPlayingModeStatus, setQuizId } = useAuth();
  const { t } = useTranslation();
  const loginExpired = useLoginExpired();
  const navigate = useNavigate();

  console.log(selectedMode);

  let lang = localStorage.getItem("language");
  // Remove extra quotes from lang string
  lang = lang.replace(/"/g, "");

  const categories = [
    {
      id: 1,
      categoriesId: [2, 6, 5, 15, 7, 8, 1, 9, 12],
      title: "General Knowledge",
      icon: "GeneralKnowledge",
      color: "orange",
    },
    {
      id: 2,
      categoriesId: [2, 6],
      title: "TV & Music",
      icon: "TVMusic",
      color: "green",
    },
    {
      id: 3,
      categoriesId: 5,
      title: "Science",
      icon: "Science",
      color: "blue",
    },
    {
      id: 4,
      categoriesId: 15,
      title: "Business",
      icon: "Business",
      color: "darkOrange",
    },
    { id: 5, categoriesId: 7, title: "Sport", icon: "Sport", color: "purple" },
    {
      id: 6,
      categoriesId: 8,
      title: "Food",
      icon: "Food",
      color: "lightOrange",
    },
    {
      id: 7,
      categoriesId: 1,
      title: "Celebrities",
      icon: "Celebrities",
      color: "red",
    },
    {
      id: 8,
      categoriesId: 9,
      title: "History",
      icon: "History",
      color: "lightGreen",
    },
    {
      id: 9,
      categoriesId: 12,
      title: "Geography",
      icon: "Geography",
      color: "magenta",
    },
  ];

  const handleModeChange = (index) => {
    const options = [
      "Quick play",
      "1 vs 1",
      "Battle Royale",
      "Cash Tournaments",
    ];
    setSelectedMode(options[index]);
  };

  // useEffect(() => {
  //   // If language changes and it's not 'el', make everything unselected
  //   if (language !== 'el') {
  //     setSelectedCategories([]); // Reset or adjust based on your state management
  //   }
  // }, [language]);

  const handleCategorySelect = (categoryId) => {
    const category = categories.find((cat) => cat.id === categoryId);
    const isCategorySelected = selectedCategories.some(
      (selCat) => selCat.id === categoryId
    );

    if (isCategorySelected) {
      // Remove the category
      setSelectedCategories(
        selectedCategories.filter((selCat) => selCat.id !== categoryId)
      );
    } else {
      // Add the new category
      setSelectedCategories([
        ...selectedCategories,
        { id: categoryId, categoriesId: category.categoriesId },
      ]);
    }
  };

  const categoryIdsToPlay = selectedCategories.flatMap(
    (selCat) => selCat.categoriesId
  );

  // Handler for Solo Quiz Mode
  const handlePlayNowClick = async () => {
    try {
      const startGameResponse = await startNewQuizGame(5, categoryIdsToPlay);
      console.log(startGameResponse.gameId);

      if (startGameResponse.gameId) {
        const questionsResponse = await fetchQuestions(
          startGameResponse.gameId
        );
        console.log(questionsResponse);
        setQuizId(startGameResponse.gameId);

        dispatch({
          type: ActionTypes.UPDATE_QUESTION_DATA,
          payload: {
            questionData: questionsResponse,
          },
        });
        setPlayNowClicked(true); // Set playNowClicked to true when "Play Now" button is clicked
        setPlayingModeStatus(true);
      }
    } catch (error) {
      console.error("Error in starting new quiz:", error);
      if (error.response?.status === 401) {
        loginExpired();
      }
    }
  };

  // Handler for 1vs1 mode
  const handlePvPmodePlay = () => {
    navigate("/versus");
  };

  console.log(categoryIdsToPlay);
  return (
    <>
      {!playNowClicked && (
        <div className={styles.quizSelectionSection}>
          <div className={styles.title}>
            <Typography
              variant={isMobile ? "heading2" : "headingL"}
              fontWeight="font700"
              color="colorBlack"
            >
              Quiz Games
            </Typography>
          </div>
          <GameModeSlider options onChange={handleModeChange} />
          <>
            {selectedMode === "Quick play" && (
              <div
                className={styles.quizSelectionSectionCategories}
                style={{
                  marginTop: "20px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <>
                  <Typography
                    variant="heading1"
                    color="colorBlack"
                    additionalClass={styles.infoHeading}
                  >
                    {t("generic.Selectupto5categories")}
                  </Typography>
                  <div div className={styles.categorySelectionContainer}>
                    {categories.map((category) => (
                      <CategorySelectionPanel
                        key={category.id}
                        selected={selectedCategories.some(
                          (selCat) => selCat.id === category.id
                        )} // Check if the category is selected
                        title={t(`quizcategories.${category.title}`)}
                        icon={category.icon}
                        color={category.color}
                        onClick={() => handleCategorySelect(category.id)}
                        canSelectMore={selectedCategories.length < 5}
                      />
                    ))}
                  </div>
                </>
              </div>
            )}
            {selectedMode === "1 vs 1" && (
              <div
                className={styles.quizSelectionSectionCategories}
                style={{
                  marginTop: "20px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="heading3"
                  color="colorBlack"
                  additionalClass={styles.infoHeadingNoMargin}
                >
                  {t("PlayVSMessage.playagainst")}
                </Typography>
                <Typography
                  variant="heading3"
                  color="colorBlack"
                  additionalClass={styles.infoHeadingNoMargin}
                >
                  {t("PlayVSMessage.playagainst2")}
                </Typography>
                <Panel1v1 />
              </div>
            )}
            {selectedMode === "Battle Royale" && (
              <>
                <ComingSoon>
                  <div className={styles.categorySelectionContainer}>
                    <CategorySelectionPanel
                      selected={selectedCategories.includes("GeneralKnowledge")}
                      title="General Knowledge"
                      icon="GeneralKnowledge"
                      color="orange"
                      onClick={() => handleCategorySelect("GeneralKnowledge")}
                    />
                    <CategorySelectionPanel
                      selected={selectedCategories.includes("TVMusic")}
                      title="TV & Music"
                      icon="TVMusic"
                      color="green"
                      onClick={() => handleCategorySelect("TVMusic")}
                    />
                    <CategorySelectionPanel
                      selected={selectedCategories.includes("Science")}
                      title="Science"
                      icon="Science"
                      color="blue"
                      onClick={() => handleCategorySelect("Science")}
                    />
                    <CategorySelectionPanel
                      selected={selectedCategories.includes("Business")}
                      title="Business"
                      icon="Business"
                      color="darkOrange"
                      onClick={() => handleCategorySelect("Business")}
                    />
                    <CategorySelectionPanel
                      selected={selectedCategories.includes("Sport")}
                      title="Sport"
                      icon="Sport"
                      color="purple"
                      onClick={() => handleCategorySelect("Sport")}
                    />
                    <CategorySelectionPanel
                      selected={selectedCategories.includes("Food")}
                      title="Food"
                      icon="Food"
                      color="lightOrange"
                      onClick={() => handleCategorySelect("Food")}
                    />
                    {/* {!isMobile && <CategorySelectionPanel isTournament />} */}
                    <CategorySelectionPanel
                      selected={selectedCategories.includes("Celebrities")}
                      title="Celebrities"
                      icon="Celebrities"
                      color="red"
                      onClick={() => handleCategorySelect("Celebrities")}
                    />
                    <CategorySelectionPanel
                      selected={selectedCategories.includes("History")}
                      title="History"
                      icon="History"
                      color="lightGreen"
                      onClick={() => handleCategorySelect("History")}
                    />
                    <CategorySelectionPanel
                      selected={selectedCategories.includes("Geography")}
                      title="Geography"
                      icon="Geography"
                      color="magenta"
                      onClick={() => handleCategorySelect("Geography")}
                    />
                    {/* {!isMobile && <CategorySelectionPanel isTournament />} */}
                  </div>
                </ComingSoon>
              </>
            )}
            {selectedMode === "Cash Tournaments" && <CashTournamentsSection />}
          </>
          <div
            className={`${styles.buttonArea} ${
              loggedIn ? styles["logged-in"] : ""
            }`}
          >
            {/* {isMobile && (
              <PlayButton
                additionalClass={"play-prize-small"}
                primaryText={"Share Quiz"}
              />
            )} */}
            {(selectedMode === "Quick play" || selectedMode === "1 vs 1") && (
              <PlayButton
                additionalClass={isMobile ? "play-now-small" : "play-now"}
                extraClass={
                  selectedMode === "1 vs 1"
                    ? styles.playNowActivated
                    : selectedMode === "Quick play"
                    ? selectedCategories.length > 0
                      ? styles.playNowActivated
                      : styles.playNowDeactivated
                    : styles.playNowDeactivated
                }
                primaryText={isMobile ? "Play Now" : t("generic.Play Now")}
                onClick={
                  selectedMode === "Quick play"
                    ? handlePlayNowClick
                    : selectedMode === "1 vs 1"
                    ? handlePvPmodePlay
                    : () => {}
                }
              />
            )}
          </div>
          {isMobile && <CategorySelectionPanel tournamentBanner />}
        </div>
      )}
      {playNowClicked && ( // Render SinglePlayerMode if playNowClicked is true
        <SinglePlayerMode selectedCategories={selectedCategories} />
      )}
    </>
  );
};

export default QuizSelectionTemplate;
