// SocketContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import io from "socket.io-client";
import { socket as startingSocket } from "./services/sockets";

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(startingSocket);

  useEffect(() => {
    return () => {};
  }, []);
  const connectSocket = () => {
    //console.log("I connect");
    socket.on("connect", () => {});
  };
  const disconnectSocket = () => {
    //console.log("I disconnect");
    socket.disconnect();
  };

  const emitInit = async () => {
    const access_token = localStorage?.getItem("token");
    const response = await new Promise((resolve) => {
      socket.emit("init", { access_token: access_token }, (response) => {
        resolve(response);
        console.log(response);
      });
    });
    return await response;
  };

  const emitLogout = async () => {
    try {
      const acknowledgement = await new Promise((resolve, reject) => {
        //console.log("try to disconnect");
        // Assuming socket is defined somewhere
        socket.emit("logout", (response) => {
          if (response === "success") {
            resolve(response); // Resolve with "success" if server acknowledges
          } else {
            reject(new Error("Logout failed: " + response)); // Reject with error message if not successful
          }
        });
      });

      if (acknowledgement === "success") {
        console.log("Logout successful!");
        disconnectSocket();
      } // No need for "else" block here as any error would be caught in the try-catch block
    } catch (error) {
      // Handle any errors during the logout process
      console.error("Logout failed:", error);
    }
  };

  const emitInviteVersus = (opponentUsername, callback) => {
    socket.emit(
      "invite versus",
      { opponentUsername: opponentUsername },
      callback
    );
  };

  const emitAcceptVersus = (opponentUsername, callback) => {
    socket.emit(
      "accept versus",
      { opponentUsername: opponentUsername },
      callback
    );
  };

  return (
    <SocketContext.Provider
      value={{
        socket,
        connectSocket,
        disconnectSocket,
        emitInit,
        emitLogout,
        emitInviteVersus,
        emitAcceptVersus,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => {
  return useContext(SocketContext);
};
