import React from 'react';
import styles from './TournamentInformation.module.css';
import Typography from '../../atoms/Typography/Typography';

const TournamentInformation = ({ name = '', description = '' }) => {
  return (
    <div className={styles.tournamentInformationContainer}>
      <div className={styles.tournamentInformation}>
        <Typography variant="heading1" color="colorBlack">
          {name}
        </Typography>
        <Typography variant="heading3" color="colorBlack">
          {description}
        </Typography>
      </div>
    </div>
  );
};

export default TournamentInformation;
