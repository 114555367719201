import React from "react";
import styles from "./SpinWheelText.module.css";
import Typography from "../../atoms/Typography/Typography";
import { useTranslation } from "react-i18next";

const SpinWheelText = ({ remainingSpins }) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography
        variant="headingL"
        fontWeight="font900"
        color="colorBlack"
        additionalClass={styles["header-text"]}
      >
        {t("spinWheel.youHave")} {remainingSpins} {t("spinWheel.spins")}
      </Typography>
      <Typography
        variant="body2"
        fontWeight="font400"
        color="colorBlack"
        additionalClass={styles["caption-text"]}
      >
        {t("spinWheel.info")}
      </Typography>
    </>
  );
};

export default SpinWheelText;
