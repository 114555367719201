import React from 'react';
import styles from './CounterDownBar.module.css';

const CounterDownBar = ({ seconds = 10, questionIndex }) => {
  const animationDuration = `${seconds}s`;

  return (
    <div className={styles.timerContainer}>
      <div className={styles.timerTrack}>
        {/* key attribute is for rerendering the page when the question index changes */}
        <div key={questionIndex} className={styles.timerBar} style={{ animationDuration }}></div>
      </div>
    </div>
  );
};

export default CounterDownBar;
