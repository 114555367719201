import React, { useState, useEffect } from "react";
import Icon from "../../atoms/Icon/Icon";
import styles from "./SelectAvatarCarousel.module.css";
import Button from "../../atoms/Button/Button";

const SelectAvatarCarousel = ({ onAvatarSelect, initialAvatarId = 1 }) => {
  const avatars = [
    { name: 'avatar-1', id: '1' },
    { name: 'avatar-2', id: '2' },
    { name: 'avatar-3', id: '3' },
    { name: 'avatar-4', id: '4' },
    { name: 'avatar-5', id: '5' },
    { name: 'avatar-6', id: '6' },
    { name: 'avatar-7', id: '7' },
    { name: 'avatar-8', id: '8' },
  ];

  const initialAvatarPosition = initialAvatarId - 1
  const [selectedAvatar, setSelectedAvatar] = useState(avatars[initialAvatarPosition]);

  // calculation so the initialAvatar will be always in the middle of the visible avatars
  const calculateInitialVisible = () => {
    const totalAvatars = avatars.length;
    let prev = initialAvatarPosition - 1 < 0 ? totalAvatars - 1 : initialAvatarPosition - 1;
    let next = initialAvatarPosition + 1 >= totalAvatars ? 0 : initialAvatarPosition + 1;
    return [prev, initialAvatarPosition, next];
  };
  const [visibleAvatars, setVisibleAvatars] = useState(calculateInitialVisible());

 useEffect(() => {
    // Call onAvatarSelect with the currently selected avatar on mount and whenever it changes
    onAvatarSelect(selectedAvatar);
  }, [selectedAvatar]);

  const handleSelectAvatar = (avatar) => {
    setSelectedAvatar(avatar);
  };

  const updateVisibleAvatars = (newVisibleAvatars) => {
    setVisibleAvatars(newVisibleAvatars);
    const newSelectedAvatar = avatars[newVisibleAvatars[1]]; // Automatically update the selected avatar based on visibility
    setSelectedAvatar(newSelectedAvatar);
  };

  const handleNext = (e) => {
    e.preventDefault();
    const lastIndex = avatars.length - 1;
    const newVisibleAvatars = visibleAvatars.map(index => (index === lastIndex ? 0 : index + 1));
    updateVisibleAvatars(newVisibleAvatars);
  };

  const handlePrev = (e) => {
    e.preventDefault();
    const lastIndex = avatars.length - 1;
    const newVisibleAvatars = visibleAvatars.map(index => (index === 0 ? lastIndex : index - 1));
    updateVisibleAvatars(newVisibleAvatars);
  };

  return (
    <div className={styles.carouselContainer}>
      <div className={styles.avatarsSection}>
        {visibleAvatars.map((index) => (
          <Icon
            key={avatars[index].id}
            name={avatars[index].name}
            folder="avatars"
            size={90}
            additionalClass={selectedAvatar.name === avatars[index].name ? styles.active : styles.icon}
            onClick={() => handleSelectAvatar(avatars[index])}
          />
        ))}
        <div className={styles.directionButtons}>
          <Button
            additionalClass={styles.avatarsSectionButton}
            onClick={handlePrev}
          >
            <Icon size={40} folder={"other_icons"} name={"arrow-left"} />
          </Button>
          <Button
            additionalClass={styles.avatarsSectionButton}
            onClick={handleNext}
          >
            <Icon size={40} folder={"other_icons"} name={"arrow-right"} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SelectAvatarCarousel;
