import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./MenuPlayerInfo.module.css";
import Avatar from "../../molecules/Avatar/Avatar";
import Typography from "../../atoms/Typography/Typography";
import ProgressBar from "../../atoms/ProgressBar/ProgressBar";
import { useAuth } from "../../../AthContext";

const MenuPlayerInfo = () => {
  const { playerInfo, isMobile, setPlayingModeStatus } = useAuth();
  const navigate = useNavigate();

  const handleMenuClick = () => {
    // Navigate to the '/profile' route when the MenuPlayerInfo is clicked
    navigate("/profile");
    setPlayingModeStatus(false); // This will set isUserAboutToPlay to false
  };

  return (
    <div className={styles.playerInfo} onClick={handleMenuClick}>
      {playerInfo && (
        <>
          <Avatar
            imageUrl={playerInfo?.avatarId}
            badgeNumber={playerInfo?.playerLevel} // Assuming this comes from playerInfo
            icon={"avatar-" + playerInfo?.avatarId} // Assuming this comes from playerInfo
            progress={playerInfo.progress} // Assuming this comes from playerInfo
            imageSize={isMobile ? "xsmall" : "small"}
          />
          <div className={styles.avatarInfo}>
            <Typography
              textShadow
              variant={isMobile ? "body1" : "heading2"}
              fontWeight={isMobile ? "font400" : "font700"}
              color={"colorBlack"}
            >
              {playerInfo?.username}
            </Typography>
            <ProgressBar
              percentage={
                (playerInfo?.totalExp !== 0 &&
                  Math.round(
                    (playerInfo?.totalExp / playerInfo?.totalExpForNextLevel) *
                      100 *
                      100
                  ) / 100) ||
                1
              }
            />
          </div>
        </>
      )}
    </div>
  );
};

export default MenuPlayerInfo;
