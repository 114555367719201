import React from "react";
import styles from "./OtherUserProfileButtonSection.module.css";
import Typography from "../../atoms/Typography/Typography";
import Icon from "../../atoms/Icon/Icon";
import Button from "../../atoms/Button/Button";
import { blockUser } from "../../../apiHelpers";
import { useTranslation } from 'react-i18next';

const OtherUserProfileButtonSection = ({playerData, setRefreshSocialTriggered, setSelectedUser, setFilteredUserList}) => {
  const { t } = useTranslation();

  const handleBlockButtonClick = async (userId) => {
    try {
      const result = await blockUser(userId);
      console.log(result);
      setRefreshSocialTriggered(prev => !prev); // Toggle the refresh trigger
      setSelectedUser(null)
      setFilteredUserList([])
    } catch (error) {
      console.error('Failed to decline friend request', error);
      // Handle error (e.g., show an error message)
    }
  };
  
  
  return (
    <div className={styles.profileButtonSection}>
      <Button additionalClass={styles.profileButton} onClick={() => handleBlockButtonClick(playerData.internalId)}>
        <Icon folder={"other_icons"} name={"blockUser"} />
        <Typography variant={"body1"}>{t('opponentProfileScreen.block')}</Typography>
      </Button>
      <Button additionalClass={styles.profileButton}>
        <Icon folder={"other_icons"} name={"report"} />
        <Typography variant={"body1"}>{t('opponentProfileScreen.reportUser')}</Typography>
      </Button>
    </div>
  );
};

export default OtherUserProfileButtonSection;
