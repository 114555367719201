import React from "react";
import styles from "./CongratulationsBoxPvPmode.module.css";
import Typography from "../../atoms/Typography/Typography";
import PlayButton from "../../molecules/PlayButton/PlayButton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CongratulationsStatistics from "../../molecules/CongratulationsStatistics/CongratulationsStatistics";
import NotificationsButtonLoggedV2 from "../../molecules/NotificationsButtonV2/NotificationsButtonV2";

const CongratulationsBoxPvPmode = ({
  quizEvaluationResults,
  reason = null,
  userCorrectAnswers,
  opponentCorrectAnswer,
  userPoints,
  completionBonus,
  questionPoints,
  winnerBonus,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/", { replace: true }); // Navigate to home page and replace the current entry in the history stack
  };

  const getRandomReasonNoneCaption = (type) => {
    const randomIndex = Math.floor(Math.random() * 6) + 1;
    return t(`congratulationsPvPMessage.${type}.reasonNone.${randomIndex}`);
  };

  const quizEvaluationResultsTexts = {
    perfect: {
      header: t("congratulationsPvPMessage.perfect.header"),
      caption:
        reason === "faster"
          ? t("congratulationsPvPMessage.perfect.reasonWin")
          : getRandomReasonNoneCaption("perfect"),
    },
    fail: {
      header: t("congratulationsPvPMessage.fail.header"),
      caption:
        reason === "slow"
          ? t("congratulationsPvPMessage.fail.reasonLoss")
          : getRandomReasonNoneCaption("fail"),
    },
  };

  const { header, caption } = quizEvaluationResultsTexts[quizEvaluationResults];

  return (
    <div className={`${styles["box-container"]}`}>
      <div className={`${styles["text-container"]}`}>
        <Typography
          variant="heading2"
          fontWeight="font700"
          additionalClass={`${styles["header-text"]}`}
        >
          {header}
        </Typography>
        <Typography
          variant="body2"
          fontWeight="font700"
          color="colorBlack"
          additionalClass={`${styles["caption-text"]}`}
        >
          {caption}
        </Typography>
      </div>
      <div className={styles.scoresContainer}>
        <div className={styles.statisticsContainer}>
          <CongratulationsStatistics
            number={`${userCorrectAnswers}/7`}
            text={t("battleRoyalResultScreen.correctAnswers")}
          />
          {/* <CongratulationsStatistics
            number={`${opponentCorrectAnswer}/7`}
            text={"Opponent's Answers"}
          /> */}
          <CongratulationsStatistics
            number={`${questionPoints}`}
            text={t("generic.points")}
          />
          <CongratulationsStatistics
            number={`${completionBonus}`}
            text={t("generic.completionbonus")}
          />
          {winnerBonus !== 0 && (
            <CongratulationsStatistics
              number={winnerBonus}
              text={t("generic.winnerbonus")}
            />
          )}
        </div>
        <div className={styles.resultNotification}>
          <NotificationsButtonLoggedV2
            text={`${t("generic.totalscore")}: ${userPoints}`}
          />
        </div>
      </div>
      <PlayButton
        primaryClass="skewed-play-button"
        additionalClass="play-now"
        primaryText="OK"
        onClick={handleButtonClick}
      />
    </div>
  );
};

export default CongratulationsBoxPvPmode;
