import { useEffect } from "react";
import confetti from "canvas-confetti";
import gsap from "gsap";

export const useLaunchConfetti = () => {
  useEffect(() => {
    const launchConfetti = () => {
      confetti({
        particleCount: 1500, // Number of confetti particles
        spread: 1170, // Spread of the confetti
        origin: { y: 0.4 }, // Start position (y-axis)
        gravity: 0.6, // Lower gravity for slower fall (default is 1)
        ticks: 500, // Number of ticks (how long the confetti falls)
        scalar: 1, // Scale of particles
        shapes: ["square", "circle"], // You can specify shapes if you want
      });
    };

    launchConfetti();
  }, []);
};

export const useThumbsUpEffect = () => {
  useEffect(() => {
    const thumbs = [];
    const numThumbs = 7; // Number of thumbs-up to animate

    // Create multiple thumbs-up elements
    for (let i = 0; i < numThumbs; i++) {
      const thumb = document.createElement("div");
      thumb.innerHTML = "👍"; // Thumbs-up emoji (or replace with an image if desired)
      thumb.style.position = "fixed";
      thumb.style.bottom = "-50px"; // Start below the screen
      thumb.style.left = `${Math.random() * 100}vw`; // Random horizontal position
      thumb.style.fontSize = "50px"; // Adjust size of thumbs-up
      thumb.style.opacity = 0; // Initially hidden
      document.body.appendChild(thumb);
      thumbs.push(thumb);
    }

    // Animate each thumbs-up with GSAP
    thumbs.forEach((thumb, i) => {
      gsap.to(thumb, {
        opacity: 1, // Fade in
        y: "-100vh", // Move upwards out of the viewport
        duration: 4 + i * 0.2, // Slightly staggered for each thumb
        ease: "power2.out", // Smooth easing
        onComplete: () => {
          thumb.remove(); // Remove the thumb element after animation
        },
      });

      // Add a soft glowing effect during the animation
      gsap.to(thumb, {
        scale: 1.2, // Slight zoom
        duration: 2,
        repeat: -1, // Repeat for the duration of the floating effect
        yoyo: true, // Ping-pong the animation back and forth
      });
    });
  }, []);
};
