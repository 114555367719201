import React from 'react';
import styles from './QuestionAndAnswersRealTime.module.css';
import Typography from '../../atoms/Typography/Typography';
import AnswerButton from '../../molecules/AnswerButton/AnswerButton';

const QuestionAndAnswersRealTime = ({
  currentQuestion,
  handleUserAnswer,
  userAnswer,
  correctAnswer,
}) => {
  const answers = [
    currentQuestion.answer1,
    currentQuestion.answer2,
    currentQuestion.answer3,
    currentQuestion.answer4,
  ];

  return (
    <>
      <Typography
        variant="heading1"
        fontWeight="font700"
        color="colorBlack"
        additionalClass={styles.question}
      >
        {currentQuestion.question ?? ''}
      </Typography>
      <div className={styles.buttonsContainer}>
        {answers.map((answer, index) => (
          <AnswerButton
            key={index}
            buttonText={answer}
            onClick={() => handleUserAnswer(index + 1)}
            additionalClass={
              // Check if correctAnswer is provided
              correctAnswer
                ? // If correctAnswer is provided, apply logic
                  // If the current index corresponds to the correctAnswer, apply 'correctAnswerClass'
                  index + 1 === correctAnswer
                  ? 'correctAnswerClass'
                  : // If the current index corresponds to the userAnswer and it's incorrect, apply 'wrongAnswerClass'
                  index + 1 === userAnswer && userAnswer !== 5
                  ? 'wrongAnswerClass'
                  : // Otherwise, leave it with the default class
                    ''
                : // If correctAnswer is not provided, apply the logic considering userAnswer
                // If userAnswer is provided and matches the current index, apply 'selectedAnswerClass'
                userAnswer === index + 1 && userAnswer !== 5
                ? 'selectedAnswerClass'
                : // Otherwise, leave it with the default class
                  ''
            }
          />
        ))}
      </div>
    </>
  );
};

export default QuestionAndAnswersRealTime;
