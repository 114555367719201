// LogoutSlider.js
import React, { useState, useEffect } from "react";
import styles from "./LoginSlider.module.css";
import Button from "../../atoms/Button/Button";
import Typography from "../../atoms/Typography/Typography";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isMobile as deviceIsMobile } from "react-device-detect";
import { useAuth } from "../../../AthContext";

const LoginSlider = ({ setActiveIndex }) => {
  const { isMobile } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation(); // Get current location

  const path = location.pathname;
  const activeIndex =
    deviceIsMobile || isMobile ? 0 : path === "/forms" ? 0 : 1;

  useEffect(() => {
    setActiveIndex(activeIndex);
    const handleResize = () => {};
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [activeIndex, setActiveIndex]);

  const handleOptionClick = (index) => {
    // Set the active index (for styling or conditional rendering)
    setActiveIndex(index);

    // Navigate based on the option clicked
    if (index === 0) {
      navigate("/forms");
    } else if (index === 1) {
      navigate("/");
    }
  };

  const options =
    deviceIsMobile || isMobile
      ? [{ label: t("Login") }]
      : [
          { label: t("generic.playAsUser"), isActive: false },
          { label: t("generic.playAsGuest"), isActive: true },
        ];

  return (
    <div className={styles["login-slider"]}>
      {options.map((option, index) => (
        <Button
          key={index}
          additionalClass={`${styles["button"]} ${
            activeIndex === index ? styles["active"] : ""
          }`}
          onClick={() => handleOptionClick(index)}
        >
          <Typography
            variant="body2"
            fontWeight="font700"
            color={activeIndex === index ? "colorWhite" : "colorBlack"}
          >
            {option.label}
          </Typography>
        </Button>
      ))}
    </div>
  );
};

export default LoginSlider;
