import React, { useEffect } from "react";
import styles from "./WalletTemplate.module.css";
import WalletSideBanner from "../../molecules/WalletSideBanner/WalletSideBanner";
import WalletContainer from "../../organisms/WalletContainer/WalletContainer";
import ComingSoon from "../../molecules/ComingSoon/ComingSoon";
import Typography from "../../atoms/Typography/Typography";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../AthContext";
import { getUserData, getUserInfo } from "../../../apiHelpers";

const WalletTemplate = ({ walletData }) => {
  const { t } = useTranslation();
  const { updatePlayerInfo, playerInfo } = useAuth();
  const id = JSON.parse(localStorage?.getItem("id"));

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getUserData();
        if (id) {
          const userInfo = await getUserInfo(id);
          // Update player info
          updatePlayerInfo(userInfo);
        }
      } catch (error) {
        // Handle errors
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  
  return (
    <div className={styles.walletTemplateWrapper}>
      <div className={styles.walletTemplateHeader}>
        <Typography
          variant={"headingL"}
          fontWeight="font900"
          color="colorBlack"
        >
          {t("mainNav.walletTab.title")}
        </Typography>
      </div>
      <div className={styles.walletTemplateContainer}>
        {/* <WalletSideBanner /> */}
        <WalletContainer walletData={walletData} coins={playerInfo.coins} />
        {/* <WalletSideBanner /> */}
      </div>
    </div>
  );
};

export default WalletTemplate;
