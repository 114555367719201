import React from 'react';

const CircleShape = ({ diameter, color, className, style, children }) => {
  return (
    <div
      className={className}
      style={{
        ...style,
        width: diameter,
        height: diameter,
        backgroundColor: color,
        borderRadius: '50%',
      }}
    >
      {children}
    </div>
  );
};

export default CircleShape;


// is used for creation the spin wheel that includes the inner circle with the slices,the outer circle for the base,the leds of the base and the center of the wheel