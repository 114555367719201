import React from "react";
import styles from "./CongratulationsBoxTournament.module.css";
import CongratulationsImage from "../../molecules/CongratulationsImage/CongratulationsImage";
import TournamentParticipants from "../TournamentParticipants/TournamentParticipants";
import Typography from "../../atoms/Typography/Typography";
import { useTranslation } from "react-i18next";
import JackpotScreen from "../JackpotScreen/JackpotScreen";
const CongratulationsBoxTournament = ({
  players,
  winners,
  amountPerWinner,
  numberOfWinners,
}) => {
  const { t } = useTranslation();
  const playersInfo = winners.map((winner) => {
    const player = players.find((player) => player.username === winner.user);
    return {
      username: winner.user,
      avatarId: player?.avatarId,
      amountWon: `${amountPerWinner} €`,
    };
  });

  if (numberOfWinners > 0) {
    return (
      <div className={styles.congratulationsBoxTemplate}>
        <div className={styles.congratulationsBox}>
          <CongratulationsImage quizEvaluationResults={"tournament"} />
          <div className={styles.congratulationsBoxPlayers}>
            <div className={styles.congratulationsTextbox}>
              <Typography
                variant="heading2"
                fontWeight={"font700"}
                additionalClass={styles.blueColor}
              >
                {t("tourWinners.congratulations")}
              </Typography>
              <Typography
                variant="body2"
                fontWeight={"font700"}
                color="colorBlack"
              >
                {t("tourWinners.theWinnersAre")}
              </Typography>
            </div>
            <TournamentParticipants players={playersInfo} completed={true} />
          </div>
        </div>
      </div>
    );
  } else {
    return <JackpotScreen />;
  }
};

export default CongratulationsBoxTournament;
