import React from "react";
import styles from "./BoosterButton.module.css";
import Icon from "../../atoms/Icon/Icon";
import Typography from "../../atoms/Typography/Typography";
import Button from "../../atoms/Button/Button";
import { useAuth } from "../../../AthContext";

const BoosterButton = ({
  boosterIcon,
  iconSize,
  boosterText,
  onClick,
  available,
}) => {
  const { isMobile } = useAuth();

  return (
    <Button additionalClass={styles.boosterButton} onClick={onClick}>
      {boosterText && (
        <>
          <div className={styles.boosterButtonContainer}>
            <Icon
              name={boosterIcon}
              folder="booster_icons"
              size={isMobile ? 74 : iconSize}
            />
          </div>
          <div>
            <Typography
              variant={isMobile ? "caption" : "heading3"}
              fontWeight="font500"
              color="colorBlack"
            >
              {boosterText}
            </Typography>
            {available !== undefined && ( // Check if available is defined
              <div className={styles.boosterBadge}>{available}</div>
            )}
          </div>
        </>
      )}
    </Button>
  );
};

export default BoosterButton;
