import React, { useEffect, useState, useRef, useMemo } from "react";
import styles from "./TournamentTemplate.module.css";
import {
  listenTournamentStart,
  stopListenTournamentStart,
  listenTournamentFinish,
  stopListenTournamentFinish,
  listenTournamentWinners,
  stopListenTournamentWinners,
  listenTournamentQuestion,
  stopListenTournamentQuestion,
  emitUserAnswerTournament,
  listenCorrectAnswerTournament,
  stopListenCorrectAnswerTournament,
} from "../../../services/sockets";
import { getTournamentInfo } from "../../../apiHelpers";
import WaitingLobbyTournaments from "../../organisms/WaitingLobbyTournaments/WaitingLobbyTournaments";
import CongratulationBoxTournament from "../../organisms/CongratulationsBoxTournament/CongratulationsBoxTournament";
import NumberOfQuestions from "../../molecules/NumberOfQuestions/NumberOfQuestions";
import CounterDownBarNumber from "../../organisms/CounterDownBarNumber/CounterDownBarNumber";
import QuestionAndAnswersRealTime from "../../organisms/QuestionAndAnswersRealTime/QuestionAndAnswersRealTime";
import { useLoginExpired } from "../../../customHooks";
import TournamentIngameInfo from "../../organisms/TournamentIngameInfo/TournamentIngameInfo";
import Typography from "../../atoms/Typography/Typography";
import { useAuth } from "../../../AthContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const TournamentTemplate = () => {
  // States
  const [tournamentId, setTournamentId] = useState(
    localStorage.getItem("tournamentId")
  );
  const { language } = useAuth();
  const [gameActive, setGameActive] = useState(false);
  const [resultsReady, setResultsReady] = useState(false);
  const [disqualified, setDisqualified] = useState(false);
  const [startingAt, setStartingAt] = useState(null);
  const [question, setQuestion] = useState("");
  const [questionNo, setQuestionNo] = useState(0);
  const [totalQuestions, setTotalQuestions] = useState(10);
  const [userAnswer, setUserAnswer] = useState(null);
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [timeToAnswer, setTimeToAnswer] = useState(7000);
  const loginExpired = useLoginExpired();
  const [tourInfo, setTourInfo] = useState(null);
  const latestUserAnswer = useRef(userAnswer);
  const questionStartTime = useRef(0);
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Use effect that gets tour info
  useEffect(() => {
    // If tournamentId null , navigate to home
    if (!tournamentId) {
      navigate("/");
    }
    const tournamentInfoHandler = async (tournamentId) => {
      try {
        const tournamentInfo = await getTournamentInfo(tournamentId);
        return tournamentInfo;
      } catch (error) {
        console.error("Error in getting tournament info:", error);
        if (error.response?.status === 401) {
          loginExpired();
        }
      }
    };

    const tournamentInfo = tournamentInfoHandler(tournamentId);
    tournamentInfo.then((response) => {
      setTourInfo(response);
      setStartingAt(response?.tournament?.startingAt);
    });
    return () => {
      localStorage.removeItem("tournamentId");
    };
  }, []);

  // Use effect that setups listener for game start , game finish and results and closes the listeners on unmount
  useEffect(() => {
    listenTournamentStart((response) => {
      // console.log('tour start listener', response);
      // IF for whatever reason someone joins after the game has started
      // their game won't be active to prevent cheating
      setGameActive(true);
      setTotalQuestions(response.totalQuestions);
      setTimeToAnswer(response.tournamentTimeToAnswer);
    });

    listenTournamentFinish((response) => {
      //console.log('tour finish listener', response);
      setGameActive(false);
    });

    listenTournamentWinners((response) => {
      // To handle Results
      //console.log("tour winners listener", response);
      setResultsReady(response);
    });

    listenTournamentQuestion((response) => {
      // console.log("tour question listener", response);
      // Deconstruct the response object to the expected question object
      setQuestion({
        question: response[`question_${language}`],
        answer1: response[`answer1_${language}`],
        answer2: response[`answer2_${language}`],
        answer3: response[`answer3_${language}`],
        answer4: response[`answer4_${language}`],
      });
      setQuestionNo((prev) => prev + 1);
      questionStartTime.current = Date.now();
    });

    listenCorrectAnswerTournament((response) => {
      //console.log("tour correct answer listener", response);
      setCorrectAnswer(parseInt(response.correctAnswer));
      if (
        parseInt(response.correctAnswer) !== latestUserAnswer.current &&
        !disqualified
      ) {
        setDisqualified(true);
        //console.log("User is disqualified");
      }
    });

    return () => {
      stopListenTournamentStart();
      stopListenTournamentFinish();
      stopListenTournamentWinners();
      stopListenTournamentQuestion();
      stopListenCorrectAnswerTournament();
    };
  }, []);

  // Use effect for the game loop
  useEffect(() => {
    let answerTimeout;

    if (gameActive) {
      setCorrectAnswer(null);
      setUserAnswer(null);
      latestUserAnswer.current = null;
      if (!disqualified) {
        // Set a timeout to automatically answer if the user hasn't responded
        answerTimeout = setTimeout(() => {
          if (!latestUserAnswer.current) {
            emitUserAnswerTournament({ answer: 5 }, (data) => {
              if (data.code !== 0) {
                //console.error("Error in submitting answer:", data);
                loginExpired();
              }
            });
          }
        }, timeToAnswer - (Date.now() - questionStartTime.current));
      }
    }
    return () => clearTimeout(answerTimeout);
  }, [gameActive, questionNo]);

  // User Answer Handler function
  const handleUserAnswer = (selectedAnswer) => {
    // Check if disqualified
    if (disqualified) return;
    // If the user has already answered OR the timer has expired, ignore the click
    if (
      latestUserAnswer.current ||
      Date.now() - questionStartTime.current >= timeToAnswer
    ) {
      return;
    }

    latestUserAnswer.current = selectedAnswer; // Update latestUserAnswer ref
    setUserAnswer(selectedAnswer);

    emitUserAnswerTournament({ answer: selectedAnswer }, (data) => {
      if (data.code !== 0) {
        //console.error("Error in submitting answer:", data);
        loginExpired();
      }
    });
  };

  return (
    <div className={styles.questionAnswersContainer}>
      {!gameActive && !resultsReady && (
        <WaitingLobbyTournaments
          tournament={tourInfo?.tournament}
          players={tourInfo?.tournamentSubscribers}
          startTime={startingAt}
        />
      )}
      {gameActive && !questionNo && (
        <Typography variant="heading2" color="colorBlack" fontWeight="font900">
          {t("tourInfo.quizStarting")}
        </Typography>
      )}
      {gameActive && questionNo > 0 && (
        <>
          <TournamentIngameInfo
            disqualified={disqualified}
            remainingPlayers={100}
            totalPlayers={100}
          />
          <NumberOfQuestions
            currentQuestionNumber={questionNo - 1}
            totalQuestions={totalQuestions}
          />
          <CounterDownBarNumber
            seconds={timeToAnswer / 1000}
            questionIndex={questionNo}
          />
          <QuestionAndAnswersRealTime
            currentQuestion={question}
            handleUserAnswer={handleUserAnswer}
            userAnswer={userAnswer}
            correctAnswer={correctAnswer}
          />
        </>
      )}
      {!gameActive && resultsReady && (
        <CongratulationBoxTournament
          players={tourInfo.tournamentSubscribers}
          winners={resultsReady.winners}
          amountPerWinner={resultsReady.amountPerWinner}
          numberOfWinners={resultsReady.numberOfWinners}
        />
      )}
    </div>
  );
};

export default TournamentTemplate;
